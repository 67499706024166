import provinces from "provinces"
import { EMPTY_CELL } from "../constants"

interface SubdivisionCellProps {
  countryAndSubdivision: string
}

interface GetProvince {
  countryCode: string
  subdivisionCode: string
}

export const getProvince = ({ countryCode, subdivisionCode }: GetProvince) =>
  provinces
    .filter(province => province.country === countryCode)
    .find(({ short }) => short === subdivisionCode)

export const SubdivisionCell = ({ countryAndSubdivision }: SubdivisionCellProps) => {
  if (!countryAndSubdivision) {
    return EMPTY_CELL
  }
  const [countryCode, subdivisionCode] = countryAndSubdivision.split("-")
  const province = getProvince({ countryCode, subdivisionCode })
  return province?.name ?? EMPTY_CELL
}
