import { useEffect, useState } from "react"
import { parseSections } from "../../utils/parseSections"
import {
  ContentOwnership,
  LegalAndPeopleServicesReviewStatus,
  SSOTSection,
} from "@/types/SSOTDocument"
import { DecisionTable } from "@/types/DecisionTable"
import { ISO8601DateTime, User } from "@/types/commons"
import { amplitudeLogEvent } from "@/utils/amplitudeHelpers"
import { useExtractInfoFromSsotUrl } from "@/bundles/administrate/views/hooks/useExtractInfoFromSsotUrl"
import { useSSOTDocumentContext } from "@/bundles/administrate/views/components"

interface UseSectionsProps {
  children: SSOTSection[]
}

export interface UpdateSectionContentParams {
  id: number
  newContent: string
  newStatus: LegalAndPeopleServicesReviewStatus
  newLockVersion: number
  newUpdatedAt: ISO8601DateTime
  newUpdatedBy: User
}

interface UpdateContentParams extends UpdateSectionContentParams {
  data: SSOTSection[]
}

export interface UpdateDecisionTableParams {
  id: number
  newDecisionTable: DecisionTable
}

export interface UpdateDecisionRuleOwnerParams {
  id: number
  newOwnership: ContentOwnership
}

export interface UpdateSectionContentOwnershipParams {
  id: number
  newContentOwnership: ContentOwnership
}

export const isEditing = () => window.location.pathname.split("/").pop() === "edit"

const useSections = ({ children }: UseSectionsProps) => {
  const allSectionsFlattened = parseSections(children, 0)
  const initialSection =
    allSectionsFlattened.find(
      ({ urls: { app } }) => (isEditing() ? app.edit : app.resource) === window.location.href,
    ) || allSectionsFlattened[0]

  const [sections, setSections] = useState(parseSections(children, 2))
  const [activeSection, setActiveSection] = useState(initialSection)
  const {
    state: {
      ssotDocument: { country },
    },
  } = useSSOTDocumentContext()
  const { oysterScore, section, category } = useExtractInfoFromSsotUrl()

  const updateContent = ({
    data,
    id,
    newContent,
    newStatus,
    newLockVersion,
    newUpdatedAt,
    newUpdatedBy,
  }: UpdateContentParams): SSOTSection[] => {
    return data.map(item => {
      if (item.id === id) {
        return {
          ...item,
          content: newContent,
          lpsReviewStatus: newStatus,
          lockVersion: newLockVersion,
          newUpdatedAt,
          updatedBy: newUpdatedBy,
        }
      }

      if (item.children.length > 0) {
        return {
          ...item,
          children: updateContent({
            data: item.children,
            id,
            newContent,
            newStatus,
            newLockVersion,
            newUpdatedAt,
            newUpdatedBy,
          }),
        }
      }

      return item
    })
  }

  const updateTable = ({
    data,
    id,
    newDecisionTable,
  }: UpdateDecisionTableParams & { data: SSOTSection[] }): SSOTSection[] => {
    return data.map(item => {
      if (item.id === id) {
        const updatedDecisionTables = [newDecisionTable]

        if (activeSection.id === id) {
          setActiveSection({ ...activeSection, decisionTables: updatedDecisionTables })
        }

        return {
          ...item,
          decisionTables: updatedDecisionTables,
        }
      }

      if (item.children.length > 0) {
        return {
          ...item,
          children: updateTable({
            data: item.children,
            id,
            newDecisionTable,
          }),
        }
      }

      return item
    })
  }

  const updateRuleOwner = ({
    data,
    id,
    newOwnership,
  }: UpdateDecisionRuleOwnerParams & { data: SSOTSection[] }): SSOTSection[] => {
    return data.map(item => {
      if (item.id === id) {
        const { user: owner, oysterScore } = newOwnership
        const decisionTable =
          item.decisionTables && item.decisionTables.length > 0 ? item.decisionTables?.[0] : null

        const getUpdatedDecisionTable = () => {
          const updatedRows = decisionTable.decisionRows.reduce((acc, decisionRow) => {
            if (decisionRow.rule.oysterScore === oysterScore) {
              return [...acc, { ...decisionRow, owner }]
            }
            return [...acc, decisionRow]
          }, [])

          const existingOwnership = !!decisionTable.contentOwnerships?.find(
            el =>
              // Don't check by ID because the ID is updated when an ownership is added
              el.countryCode === newOwnership.countryCode &&
              el.oysterScore === newOwnership.oysterScore,
          )

          const updatedContentOwnerships = existingOwnership
            ? decisionTable.contentOwnerships?.reduce((acc, contentOwnership) => {
                if (
                  contentOwnership.countryCode === newOwnership.countryCode &&
                  contentOwnership.oysterScore === newOwnership.oysterScore
                ) {
                  return [...acc, { ...newOwnership }]
                }
                return [...acc, contentOwnership]
              }, [])
            : [...decisionTable.contentOwnerships, { ...newOwnership }]

          return {
            ...decisionTable,
            decisionRows: updatedRows,
            contentOwnerships: updatedContentOwnerships,
          }
        }

        const updatedItem = {
          ...item,
          decisionTables: item.decisionTables
            ? ([getUpdatedDecisionTable()] as DecisionTable[])
            : undefined,
        }

        if (activeSection.id === id) {
          setActiveSection(updatedItem)
        }

        return updatedItem
      }

      if (item.children.length > 0) {
        return {
          ...item,
          children: updateRuleOwner({
            data: item.children,
            id,
            newOwnership,
          }),
        }
      }

      return item
    })
  }

  const updateDecisionRuleOwner = ({ id, newOwnership }: UpdateDecisionRuleOwnerParams) => {
    setSections(updateRuleOwner({ data: sections, id, newOwnership }))
  }

  const updateSectionContent = ({
    id,
    newContent,
    newStatus,
    newLockVersion,
    newUpdatedAt,
    newUpdatedBy,
  }: UpdateSectionContentParams) => {
    setSections(
      updateContent({
        data: sections,
        id,
        newContent,
        newStatus,
        newLockVersion,
        newUpdatedAt,
        newUpdatedBy,
      }),
    )
  }

  const updateDecisionTable = ({ id, newDecisionTable }: UpdateDecisionTableParams) =>
    setSections(updateTable({ id, newDecisionTable, data: sections }))

  const updateSectionContentOwnership = ({
    data,
    id,
    newContentOwnership,
  }: UpdateSectionContentOwnershipParams & { data: SSOTSection[] }): SSOTSection[] => {
    return data.map(item => {
      if (item.id === id) {
        return {
          ...item,
          contentOwnership: newContentOwnership,
        }
      }

      if (item.children.length > 0) {
        return {
          ...item,
          children: updateSectionContentOwnership({
            data: item.children,
            id,
            newContentOwnership,
          }),
        }
      }

      return item
    })
  }

  const updateSSOTSectionContentOwnership = ({
    id,
    newContentOwnership,
  }: UpdateSectionContentOwnershipParams) =>
    setSections(updateSectionContentOwnership({ data: sections, id, newContentOwnership }))

  useEffect(() => {
    const { resource, edit } = activeSection.urls.app
    window.history.pushState({}, "", isEditing() ? edit : resource)

    amplitudeLogEvent("[SE]-open-ssot-section", {
      countryCode: country.code,
      category,
      section,
      oysterScore,
    })
  }, [activeSection, category, country, oysterScore, section])

  return {
    sections,
    activeSection,
    setActiveSection,
    updateSectionContent,
    updateDecisionRuleOwner,
    updateDecisionTable,
    updateSSOTSectionContentOwnership,
  }
}

export default useSections
