import { ListItemIcon, MenuItem } from "@mui/material"
import React from "react"
import PushPinIcon from "@mui/icons-material/PushPin"

interface PinProps {
  text: string
  iconRotation?: number
  onClick: () => void
}

export const Pin = ({ text, iconRotation, onClick }: PinProps) => {
  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon>
        <PushPinIcon style={{ rotate: `${iconRotation ?? 0}deg` }} />
      </ListItemIcon>
      {text}
    </MenuItem>
  )
}
