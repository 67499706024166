import React from "react"
import ReactOnRails from "react-on-rails"
import { IntlProvider } from "react-intl"

import SSOTDocumentShow from "../bundles/administrate/views/SSOTDocumentShow"
import SSOTDocumentEdit from "../bundles/administrate/views/SSOTDocumentEdit"
import DecisionTableShow from "../bundles/administrate/views/DecisionTableShow"

import PreviewEditor from "../bundles/sage/editor/PreviewEditor"
import { RailsContextContext, ToastProvider } from "../components"
import translations from "../bundles/sage/editor/i18n/translations"
import * as amplitude from "@amplitude/analytics-browser"

// eslint-disable-next-line import/no-unresolved
import "@oysterhr/theme/variables.css"

// Wrap to inject Rails context into component (when called from Rails).
const wrap = Component => (props, railsContext) => () => {
  amplitude.init(window.gon?.amplitudeAPIKey)

  const locale = "en"
  const messages = translations[locale]

  return (
    <IntlProvider key={locale} locale={locale} messages={messages}>
      <ToastProvider>
        <RailsContextContext.Provider value={railsContext}>
          <Component {...props} />
        </RailsContextContext.Provider>
      </ToastProvider>
    </IntlProvider>
  )
}

// This is how react_on_rails can see the components in the browser.
ReactOnRails.register({
  PreviewEditor: wrap(PreviewEditor),
  SSOTDocumentShow: wrap(SSOTDocumentShow),
  SSOTDocumentEdit: wrap(SSOTDocumentEdit),
  DecisionTableShow: wrap(DecisionTableShow),
})
