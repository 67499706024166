import classNames from "classnames"
import { ReactNode } from "react"

/**
 * Returns the correct grid classNames for depending on the given startAdornment and endAdornment args
 * The grid container should have up to three grid item:, [startAdornment] [mainContent] [endAdornment]
 * The start and end adornments, should only take up as much space as they need to (width: auto)
 * The mainContent is assumed to always be present, and should always take up the remaining available space (1fr)
 */
export function getGridClassNames(startAdornment: ReactNode, endAdornment: ReactNode): string {
  return classNames({
    "grid gap-2 grid-flow-col": startAdornment || endAdornment,
    "grid-cols-[auto,1fr,auto]": startAdornment && endAdornment,
    "grid-cols-[1fr,auto]": !startAdornment && endAdornment,
    "grid-cols-[auto,1fr]": startAdornment && !endAdornment,
  })
}
