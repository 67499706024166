import React, { useEffect, useState } from "react"
import classNames from "classnames"

type ToastProgressBarProps = {
  autohideDuration: number
  closeToast: () => void
  variation: "error" | "info" | "primary" | "success" | "warning"
}

export function ToastProgressBar({
  autohideDuration,
  closeToast,
  variation,
}: ToastProgressBarProps) {
  const [progress, setProgress] = useState(0)
  useEffect(() => {
    if (autohideDuration === null) {
      return
    }

    const interval = 50
    const increment = (interval / autohideDuration) * 100 // total percentage
    const progressTimer = setTimeout(() => {
      setProgress(lastProgress => lastProgress + increment)
    }, interval)
    let closeTimer

    if (progress === 100) {
      closeTimer = setTimeout(() => closeToast(), 300) // Let Toast stay on the page a little bit longer so that the animation's end is clear to use
      clearTimeout(progressTimer)
    }

    return () => {
      clearTimeout(progressTimer)
      clearTimeout(closeTimer)
    }
  }, [progress, autohideDuration, closeToast])

  return (
    <div
      aria-valuenow={progress ?? undefined}
      className={classNames(
        // subtract 2px to align with the padding box of the alert
        "absolute bottom-0 left-0 right-0 mx-auto h-[6px] w-[calc(100%-2px)] overflow-hidden rounded-bl-md rounded-br-md",
        {
          "bg-error-lighten-100": variation === "error",
          "bg-info-lighten-100": variation === "info",
          "bg-success-lighten-100": variation === "success",
          "bg-warning-lighten-100": variation === "warning",
          "bg-primary-lighten-100": variation === "primary",
        },
      )}
      role="progressbar"
    >
      <div
        className={classNames("h-full transition-transform", {
          "bg-error-base": variation === "error",
          "bg-info-base": variation === "info",
          "bg-success-base": variation === "success",
          "bg-warning-base": variation === "warning",
          "bg-primary-base": variation === "primary",
        })}
        style={{ transform: `translateX(-${100 - progress}%)` }}
      ></div>
    </div>
  )
}
