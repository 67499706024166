import React from "react"
import { Pin } from "./Pin"
import { ColumnMenuItemProps } from "../types"
import { useDecisionTableContext } from "@/components"

export const PinToLeft = ({ column, closeMenu }: ColumnMenuItemProps) => {
  const { state, dispatch } = useDecisionTableContext()

  return (
    <Pin
      iconRotation={90}
      onClick={() => {
        dispatch({
          pinnedColumns: {
            left: [...state.pinnedColumns.left, column.id],
            right: state.pinnedColumns.right.filter(id => id !== column.id),
          },
        })
        closeMenu()
      }}
      text="Pin to left"
    />
  )
}
