import { TimeUnits } from "../../../types"
import { textUnitToIsoUnit } from "../../../utils"
import { Action, RangeFieldState } from "./types"

export const rangeFieldActionTypes = {
  UPDATE_AMOUNT: "UPDATE_AMOUNT",
  UPDATE_UNIT: "UPDATE_UNIT",
}

export const rangeFieldStateReducer = (state: RangeFieldState, action: Action) => {
  switch (action.type) {
    case rangeFieldActionTypes.UPDATE_AMOUNT:
      return {
        ...state,
        [action.value.field]: {
          ...state[action.value.field],
          amount: action.value.update,
          isoDuration: `P${action.value.update}${textUnitToIsoUnit(
            state[action.value.field].unit,
          )}`,
        },
      }
    case rangeFieldActionTypes.UPDATE_UNIT:
      return {
        ...state,
        [action.value.field]: {
          ...state[action.value.field],
          unit: action.value.update,
          isoDuration: `P${state[action.value.field].amount}${textUnitToIsoUnit(
            action.value.update as TimeUnits,
          )}`,
        },
      }
    default:
      return state
  }
}
