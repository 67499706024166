import React, { ComponentPropsWithRef, forwardRef, ReactNode } from "react"
import classNames from "classnames"
import { WRAPPER_CLASSES, WRAPPER_MD_CLASSES } from "../utils/formComponentWrapperClasses"
import { FormLabel, FormLabelProps } from "../FormLabel"
import { Radio } from "../Radio"

export interface RadioOption {
  value: string | number
  label: string
  assistiveText?: string
  defaultChecked?: boolean
  disabled?: boolean
  dataAnalyticsName?: string
}

/**
 * This component extends all the props from the FormLabel component
 */
type RadioGroupProps = ComponentPropsWithRef<"input"> &
  FormLabelProps<"label"> & {
    /**
     * Renders the radio buttons vertically.
     * @default true
     */
    vertical?: boolean
    /**
     * Error message that should be shown below the radio buttons
     */
    error?: string
    /**
     * Adornment to add after the label
     */
    labelEndAdornment?: ReactNode
    /**
     * Options to be rendered inside the radio group
     */
    options: Array<RadioOption>

    onFocus?: () => void
  }

export const RadioGroup = forwardRef<HTMLInputElement, RadioGroupProps>(function RadioGroup(
  {
    label,
    assistiveText,
    id,
    name,
    options,
    error,
    isOptional,
    labelEndAdornment,
    vertical = true,
    ...rest
  },
  ref,
) {
  const getRadioTestId = (value: RadioOption["value"]) => `radio-option-${value}`
  const getRadioId = (value: RadioOption["value"]) => `${name}-radio-option-${value}`

  return (
    <div id={id} className={classNames(WRAPPER_CLASSES, WRAPPER_MD_CLASSES)}>
      <FormLabel
        label={label}
        htmlFor={name}
        assistiveText={assistiveText}
        isOptional={isOptional}
        endAdornment={labelEndAdornment}
      />
      <div
        data-testid={`radio-button-group-${name}`}
        /* By default, the options are place inside a row */
        className={classNames("flex", {
          "flex-row space-x-8": !vertical,
          "flex-col space-y-2": vertical,
        })}
      >
        {options.map(option => {
          return (
            <div key={option.value}>
              {/* We need this `div` here because if the radio is disabled (most likely when you want to show the toolip), it removes all pointer events.
                  This doesn't break anything in terms of UI or UX, it just adds an extra div where the tooltip can attach
                */}
              <Radio
                /* AB: I don't we should be spreading the rest of the props here, since they belong ot the RadioGroup, not to the Radio  */
                {...rest}
                data-testid={getRadioTestId(option.value)}
                id={getRadioId(option.value)}
                value={option.value}
                name={name}
                defaultChecked={option.defaultChecked}
                disabled={option.disabled}
                data-analytics-name={option?.dataAnalyticsName}
                label={
                  <FormLabel
                    htmlFor={getRadioId(option.value)}
                    label={option.label}
                    assistiveText={option.assistiveText}
                  />
                }
                ref={ref}
                error={error}
              />
            </div>
          )
        })}
      </div>
      {error && <div className="mt-2 text-sm text-error-base">{error}</div>}
    </div>
  )
})
