import React from "react"
import { Button, Modal } from "@/components"
import { UpdateSection } from "./types"

interface UnsavedChangesModalProps {
  modalOpen: boolean
  setModalOpen: (modalOpen: boolean) => void
  onModalClose: () => void
  updateSection: UpdateSection
  updatingSection: boolean
  setContentJustUpdated: () => void
}

export const UnsavedChangesModal: React.FC<UnsavedChangesModalProps> = ({
  modalOpen,
  setModalOpen,
  onModalClose,
  updateSection,
  updatingSection,
  setContentJustUpdated,
}) => {
  return (
    <Modal isOpen={modalOpen} setIsOpen={setModalOpen} title="Leaving without saving changes?">
      <p className="mb-6">
        Are you sure you want to leave without saving? Your progress will be lost if you proceed.
      </p>
      <div className="flex justify-end">
        <div className="w-fit mr-3">
          <Button variant="secondary" onClick={onModalClose}>
            Discard changes
          </Button>
        </div>
        <div className="w-fit">
          <Button
            variant="primary"
            onClick={() => {
              updateSection(() => {
                setContentJustUpdated()
                onModalClose()
              })
            }}
            loading={updatingSection}
          >
            Save changes
          </Button>
        </div>
      </div>
    </Modal>
  )
}
