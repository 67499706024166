import React, { useCallback, useEffect, useMemo, useReducer } from "react"
import { FormLabel, useDecisionTableContext } from "@/components"
import { parse } from "tinyduration"
import {
  GenerateFieldParams,
  RangeFieldProps,
  RangeFieldState,
  RangeFieldStateReducer,
} from "./types"
import { rangeFieldActionTypes, rangeFieldStateReducer } from "./rangeFieldStateReducer"
import { DurationAmountInput } from "../DurationField/DurationAmountInput"
import { DurationUnitSelectInput } from "../DurationField"
import { TimeUnits } from "../../../types"

export const RangeField = ({
  cell,
  label,
  column,
  row,
  accessorKey,
  startKey,
  endKey,
}: RangeFieldProps) => {
  const {
    state: { validationErrors },
    dispatch,
  } = useDecisionTableContext()

  const getInitialAmountAndUnit = useCallback(
    (keyName: string) => {
      const defaultAmountAndUnit = { amount: "", unit: TimeUnits.Days }
      try {
        const duration = row._valuesCache[column.id]?.[keyName]
        if (duration) {
          const parsedDuration = parse(duration)
          const setUnit = Object.entries(parsedDuration).find(entry => {
            const [_unit, amount] = entry
            return amount > 0
          })
          return setUnit ? { amount: setUnit[1], unit: setUnit[0] } : defaultAmountAndUnit
        }
        return defaultAmountAndUnit
      } catch (error) {
        return defaultAmountAndUnit
      }
    },
    [row, column],
  )

  const startInitialAmountAndUnit = useMemo(
    () => getInitialAmountAndUnit(startKey),
    [getInitialAmountAndUnit, startKey],
  )
  const endInitialAmountAndUnit = useMemo(
    () => getInitialAmountAndUnit(endKey),
    [getInitialAmountAndUnit, endKey],
  )

  const initialState: RangeFieldState = {
    start: {
      isoDuration: row._valuesCache[column.id]?.[startKey] ?? "",
      amount: startInitialAmountAndUnit.amount,
      unit: startInitialAmountAndUnit.unit as TimeUnits,
    },
    end: {
      isoDuration: row._valuesCache[column.id]?.[endKey] ?? "",
      amount: endInitialAmountAndUnit.amount,
      unit: endInitialAmountAndUnit.unit as TimeUnits,
    },
  }

  const [state, updateState] = useReducer<RangeFieldStateReducer>(
    rangeFieldStateReducer,
    initialState,
  )

  useEffect(() => {
    if (!row._valuesCache[column.id]) {
      row._valuesCache = {
        ...row._valuesCache,
        [column.id]: {
          [startKey]: state.start.isoDuration,
          [endKey]: state.end.isoDuration,
        },
      }
    } else {
      row._valuesCache[column.id][startKey] = state.start.isoDuration
      row._valuesCache[column.id][endKey] = state.end.isoDuration
    }

    if (!row._valuesCache[column.id].excludeEnd) {
      row._valuesCache[column.id].excludeEnd = false
    }
  }, [column.id, endKey, row, startKey, state])

  const generateAmountInput = ({ fieldName, reducerKey }: GenerateFieldParams) => (
    <DurationAmountInput
      onChange={e => {
        const update = parseInt(e.target.value).toString()
        if (!row._valuesCache[column.id]) {
          row._valuesCache = {
            ...row._valuesCache,
            [column.id]: {
              [fieldName]: update,
            },
          }
        } else {
          row._valuesCache[column.id][fieldName] = update
        }
        updateState({
          type: rangeFieldActionTypes.UPDATE_AMOUNT,
          value: {
            field: reducerKey,
            update: update,
          },
        })
      }}
      id={`${cell.id}-${reducerKey}`}
      value={state[reducerKey].amount}
      error={validationErrors[accessorKey]?.[fieldName]}
      onFocus={() =>
        dispatch({
          validationErrors: {
            ...validationErrors,
            [accessorKey]: undefined,
          },
        })
      }
    />
  )

  const generateUnitSelect = ({ fieldName, reducerKey }: GenerateFieldParams) => (
    <DurationUnitSelectInput
      id={`${cell.id}-${reducerKey}`}
      value={state[reducerKey].unit}
      onChange={(val: string) => {
        if (!row._valuesCache[column.id]) {
          row._valuesCache = {
            ...row._valuesCache,
            [column.id]: {
              [fieldName]: val,
            },
          }
        } else {
          row._valuesCache[column.id][fieldName] = val
        }
        updateState({
          type: rangeFieldActionTypes.UPDATE_UNIT,
          value: {
            field: reducerKey,
            update: val,
          },
        })
      }}
    />
  )

  return (
    <fieldset>
      <div className="mb-4">
        <FormLabel
          htmlFor={`${cell.id}-start`}
          id={`label-${cell.id}-start`}
          className="mb-2"
          label={`${label} lower limit`}
        />
        <div className="flex">
          {generateAmountInput({ fieldName: startKey, reducerKey: "start" })}
          {generateUnitSelect({ fieldName: startKey, reducerKey: "start" })}
        </div>
      </div>
      <div className="mb-4">
        <FormLabel
          htmlFor={`${cell.id}-upper`}
          id={`label-${cell.id}-upper`}
          className="mb-2"
          label={`${label} upper limit`}
        />
        <div className="flex">
          {generateAmountInput({ fieldName: endKey, reducerKey: "end" })}
          {generateUnitSelect({ fieldName: endKey, reducerKey: "end" })}
        </div>
      </div>
    </fieldset>
  )
}
