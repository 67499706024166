export enum FetchMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  HEAD = "HEAD",
  OPTIONS = "OPTIONS",
  PATCH = "PATCH",
}

export type Done = (responseField: Record<string, any>) => void

interface DoFetchParams {
  endpoint: string
  method: FetchMethod
  onResponse?: (response: Response) => void
  body?: Record<string, any>
  errorMessage?: string
  parseResponseField?: string | null
  done?: Done
  fail?: (e: Error) => void
}

const urlify = endpoint => (endpoint.startsWith("http") ? endpoint : `/api/v1/${endpoint}`)

export const doFetch = async ({
  endpoint,
  method,
  onResponse,
  body,
  errorMessage,
  parseResponseField,
  done,
  fail,
}: DoFetchParams) => {
  try {
    const response = await fetch(urlify(endpoint), {
      method,
      body: body ? JSON.stringify(body) : undefined,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })

    if (onResponse) {
      return onResponse(response)
    }

    if (!response.ok) {
      // TODO: Don't throw JSON as error message
      const json = await response.json()
      const stringified = json ? JSON.stringify(json) : null
      throw new Error(errorMessage ?? stringified ?? "Something went wrong")
    }

    const jsonResponse = await response.json()

    if (done) {
      done(
        parseResponseField
          ? { [parseResponseField]: jsonResponse[parseResponseField] }
          : jsonResponse,
      )
    }

    return jsonResponse
  } catch (e) {
    if (fail) {
      fail(e)
    }
  }
}
