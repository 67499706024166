import { camelCase } from "change-case"
import React from "react"
import {
  DecisionColumnTypeName,
  DecisionColumn,
  DecisionColumnEnum,
  DecisionColumnInnerTyped,
  DecisionColumnEnumValue,
  DecisionRule,
  OysterScores,
} from "@/types/DecisionTable"
import {
  ArrayCell,
  ArrayField,
  BooleanField,
  CountryCell,
  CountryField,
  DurationField,
  DurationRangeCell,
  MoneyCell,
  MoneyField,
  RangeField,
  StringCell,
  StringField,
  SubdivisionCell,
  SubdivisionField,
} from "../components"
import { filterColumn, sortDurationRanges, sortDurations, sortOwners } from "../utils"
import { booleanToTextValue } from "@administrate/utils/booleanTextConverter"
import { DURATION_RANGE_KEYS, OWNER_COLUMN, countriesWithProvinceList } from "../constants"
import { EMPTY_CELL } from "../components/cells/constants"
import { type MRT_ColumnDef } from "material-react-table"
import { NO_OWNER, Owner } from "@administrate/views/components"
import { InsideSSOT, useDecisionTableContext } from "../DecisionTableContext"

export const useGenerateColumns = () => {
  const {
    state: {
      schema: { columns },
      insideSSOT,
    },
  } = useDecisionTableContext()

  const textField = (accessorKey: string, header: string, isNumber?: boolean) => ({
    accessorKey,
    header,
    Edit: ({ cell, column, row }) => (
      <div className="mb-4">
        <StringField
          cell={cell}
          row={row}
          column={column}
          accessorKey={accessorKey}
          label={header}
          isNumber={isNumber}
        />
      </div>
    ),
    filterFn: filterColumn(columns),
    Cell: ({ cell }) => {
      const value = cell.getValue()
      if (!value) return EMPTY_CELL

      if (Array.isArray(value)) {
        return <ArrayCell items={value} />
      }

      return <StringCell value={value} />
    },
  })

  const selectField = (
    accessorKey: string,
    header: string,
    editSelectOptions: DecisionColumnEnumValue[],
    type: "simple" | "multi" = "simple",
  ) => ({
    accessorKey,
    header,
    Edit: ({ cell, column, row }) => (
      <div className="mb-4">
        <ArrayField
          cell={cell}
          row={row}
          column={column}
          accessorKey={accessorKey}
          label={header}
          options={editSelectOptions}
          type={type}
          disabled={
            columns.find(({ name }) => name === "oysterScore") &&
            accessorKey === "oysterScore" &&
            (Object.values(OysterScores) as string[]).includes(insideSSOT?.activeSection.kind)
          }
          value={
            columns.find(({ name }) => name === "oysterScore") &&
            accessorKey === "oysterScore" &&
            (Object.values(OysterScores) as string[]).includes(insideSSOT?.activeSection.kind) &&
            insideSSOT?.activeSection.kind
          }
        />
      </div>
    ),
    filterFn: filterColumn(columns),
    Cell: ({ cell }) => {
      const value = cell.getValue()
      if (!value) return EMPTY_CELL

      if (Array.isArray(value)) {
        return <ArrayCell items={value} />
      }

      return <StringCell value={value} />
    },
  })

  const booleanField = (accessorKey: string, header: string) => ({
    accessorKey,
    header,
    Edit: ({ cell, column, row }) => (
      <div className="mb-4">
        <BooleanField
          cell={cell}
          row={row}
          column={column}
          accessorKey={accessorKey}
          label={header}
        />
      </div>
    ),
    size: 120,
    enableSorting: false,
    filterFn: filterColumn(columns),
    Cell: ({ cell }) => {
      const value = cell.getValue()

      if (typeof value !== "boolean") {
        return EMPTY_CELL
      }

      return <StringCell value={booleanToTextValue(value)} />
    },
  })

  const rangeField = (accessorKey: string, header: string) => ({
    accessorKey,
    header,
    sortingFn: sortDurationRanges,
    Cell: ({ cell }) => {
      const value = cell.getValue()

      if (!value) return EMPTY_CELL

      return <DurationRangeCell duration={value} />
    },
    enableEditing: true,
    filterFn: filterColumn(columns),
    Edit: ({ cell, column, row }) => (
      <RangeField
        cell={cell}
        row={row}
        column={column}
        accessorKey={accessorKey}
        label={header}
        startKey={DURATION_RANGE_KEYS.BEGIN}
        endKey={DURATION_RANGE_KEYS.END}
      />
    ),
  })

  const durationField = (accessorKey: string, header: string) => ({
    accessorKey,
    header,
    sortingFn: sortDurations,
    Cell: ({ cell }) => {
      const value = cell.getValue()

      if (!value) return EMPTY_CELL

      return <StringCell value={value} />
    },
    enableEditing: true,
    filterFn: filterColumn(columns),
    Edit: ({ cell, column, row }) => (
      <div className="mb-4">
        <DurationField
          cell={cell}
          row={row}
          column={column}
          accessorKey={accessorKey}
          label={header}
        />
      </div>
    ),
  })

  const countryField = (
    accessorKey: string,
    header: string,
    options: DecisionColumnEnumValue[],
    insideSSOT?: InsideSSOT,
  ) => ({
    accessorKey,
    header,
    Edit: ({ cell, column, row }) =>
      insideSSOT ? null : (
        <div className="mb-4">
          <CountryField
            cell={cell}
            row={row}
            column={column}
            accessorKey={accessorKey}
            label={header}
            options={options}
          />
        </div>
      ),
    Cell: ({ cell }) => <CountryCell countryCode={cell.getValue()} />,
    filterFn: filterColumn(columns),
  })

  const subdivisionField = (
    accessorKey: string,
    header: string,
    options: DecisionColumnEnumValue[],
    insideSSOT?: InsideSSOT,
  ) => ({
    accessorKey,
    header,
    Edit: ({ cell, row, column }) => {
      const renderSubdivisionField = (countryCode: string) => (
        <div className="mb-4">
          <SubdivisionField
            cell={cell}
            row={row}
            column={column}
            accessorKey={accessorKey}
            countryCode={countryCode}
            label={header}
            options={options}
          />
        </div>
      )

      if (insideSSOT) {
        if (countriesWithProvinceList.includes(insideSSOT.countryCode)) {
          return renderSubdivisionField(insideSSOT.countryCode)
        }
        return null
      }

      if (!countriesWithProvinceList.includes(row._valuesCache.countryCode)) {
        return null
      }

      return renderSubdivisionField(row._valuesCache.countryCode)
    },
    filterFn: filterColumn(columns),
    Cell: ({ cell }) => <SubdivisionCell countryAndSubdivision={cell.getValue()} />,
  })

  const moneyField = (accessorKey: string, header: string) => ({
    accessorKey,
    header,
    Edit: ({ cell, column, row }) => (
      <div className="mb-4">
        <MoneyField
          cell={cell}
          row={row}
          column={column}
          accessorKey={accessorKey}
          label={header}
        />
      </div>
    ),
    filterFn: filterColumn(columns),
    Cell: ({ cell }) => {
      const value = cell.getValue()
      if (!value) return EMPTY_CELL

      return <MoneyCell value={value} />
    },
  })

  const ownerField = () =>
    ({
      accessorKey: OWNER_COLUMN.name,
      header: OWNER_COLUMN.title,
      Edit: () => null,
      sortingFn: sortOwners,
      Cell: ({ cell }) => {
        const owner = cell.getValue()
        return <span data-testid="row-owner">{owner?.name ?? NO_OWNER.name}</span>
      },
      filterFn: filterColumn(columns),
    }) as MRT_ColumnDef<DecisionRule, Owner>

  const columnsToRender = [...columns, OWNER_COLUMN].filter(e => e)

  const cols = columnsToRender.map(({ name, title, type }: DecisionColumn) => {
    switch (type.name) {
      case DecisionColumnTypeName.ISO31661Alpha2CountryCode:
        return countryField(camelCase(name), title, (type as DecisionColumnEnum).values, insideSSOT)
      case DecisionColumnTypeName.ISO31662SubdivisionCode:
        return subdivisionField(
          camelCase(name),
          title,
          (type as DecisionColumnEnum).values,
          insideSSOT,
        )
      case DecisionColumnTypeName.ISO8601Duration:
        return durationField(camelCase(name), title)
      case DecisionColumnTypeName.Boolean:
        return booleanField(camelCase(name), title)
      case DecisionColumnTypeName.Range:
        return rangeField(camelCase(name), title)
      case DecisionColumnTypeName.Integer:
        return textField(camelCase(name), title, true)
      case DecisionColumnTypeName.Money:
        return moneyField(camelCase(name), title)
      case DecisionColumnTypeName.Owner:
        return ownerField()
      default: {
        if ((type as DecisionColumnEnum).values) {
          return selectField(camelCase(name), title, (type as DecisionColumnEnum).values, "simple")
        }
        if ((type as DecisionColumnInnerTyped).innerType) {
          return selectField(
            camelCase(name),
            title,
            ((type as DecisionColumnInnerTyped).innerType as DecisionColumnEnum).values,
            "multi",
          )
        }

        return textField(camelCase(name), title)
      }
    }
  })

  return cols.filter(c => c)
}
