import { useInteractions, type UseFloatingReturn } from "@floating-ui/react"
import { noop } from "lodash-es"
import { createContext } from "react"
import { MultiSelectContextValue } from "./types"

export const MultiSelectContext = createContext<MultiSelectContextValue>({
  activeIndex: null,
  checkboxPosition: "start",
  floating: {} as UseFloatingReturn<HTMLElement>,
  getSearchInputProps: () => ({
    onFocus: noop,
    onKeyDown: noop,
    ref: { current: null },
    tabIndex: 0,
  }),
  id: "",
  interactions: {} as ReturnType<typeof useInteractions>,
  isSelectAllChecked: false,
  listNodeRef: {
    current: [],
  },
  onChange: noop,
  onSelectAll: noop,
  onSelectValue: noop,
  open: false,
  placeholder: undefined,
  removeValue: noop as (value: string) => string[],
  defaultSearchInputValue: "",
  searchInputRef: { current: null },
  selectAllButtonRef: { current: null },
  setActiveIndex: noop,
  setOpen: noop,
  setDefaultSearchInputValue: noop,
  value: [],
  disabled: false,
  error: undefined,
})
