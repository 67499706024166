import React from "react"
import type { SelectGroupProps } from "./types"

export function SelectGroup({ children, label, role = "group" }: SelectGroupProps) {
  const id = label && `select-group-${label.toLowerCase().split(" ").join("-")}`

  return (
    <ul
      role={role}
      className="border-b border-gray-200 last:border-transparent"
      aria-labelledby={id}
    >
      <li role="presentation" className="sr-only" id={id}>
        {label}
      </li>
      {children}
    </ul>
  )
}

SelectGroup.displayName = "Select.Group"
