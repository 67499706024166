import React, { cloneElement, forwardRef, isValidElement } from "react"
import classNames from "classnames"
import { twMerge } from "tailwind-merge"
import {
  WRAPPER_CLASSES,
  WRAPPER_MD_CLASSES,
  WRAPPER_SM_CLASSES,
} from "../utils/formComponentWrapperClasses"
import {
  BASE_CLASSES,
  BASE_MD_CLASSES,
  BASE_SM_CLASSES,
  BASE_DISABLED_CLASSES,
  BASE_ERROR_CLASSES,
  BASE_FOCUS_CLASSES,
  INPUT_CLASSES,
} from "./constants"
import type { TextInputProps } from "./types"
import { FOCUS_STATE_BASE_CLASSES, FORM_COMPONENT_FOCUS_CLASSES } from "../utils/focusStateClasses"
import { useInputGroupContext } from "../InputGroup/InputGroupContext"

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      label,
      disabled,
      className,
      error,
      showErrorMessage = true,
      name,
      startAdornment,
      endAdornment,
      wrapperClassName,
      size = "md",
      ...rest
    },
    ref,
  ) => {
    const inputGroupContext = useInputGroupContext()

    const isSM = size === "sm"
    const isMD = size === "md"

    const _showErrorMessage = !!error && showErrorMessage
    const _label =
      label && isValidElement(label)
        ? cloneElement(label, { htmlFor: rest.id || name, size, ...label.props })
        : label

    const _className = twMerge(
      classNames(
        BASE_CLASSES,
        FOCUS_STATE_BASE_CLASSES,
        FORM_COMPONENT_FOCUS_CLASSES,
        {
          [BASE_SM_CLASSES]: isSM,
          [BASE_MD_CLASSES]: isMD,
          [BASE_DISABLED_CLASSES]: disabled,
          [BASE_FOCUS_CLASSES]: !inputGroupContext.error && !error,
          [BASE_ERROR_CLASSES]: inputGroupContext.error || error,
        },
        wrapperClassName,
      ),
    )

    const _classNameWrapper = classNames(WRAPPER_CLASSES, {
      [WRAPPER_SM_CLASSES]: isSM,
      [WRAPPER_MD_CLASSES]: isMD,
    })

    return (
      <div className={_classNameWrapper}>
        {_label}
        <div className={_className}>
          {startAdornment}
          <input
            id={name}
            name={name}
            className={classNames(INPUT_CLASSES, className)}
            disabled={disabled}
            ref={ref}
            {...rest}
          />
          {endAdornment}
        </div>
        {/* TODO: Move this error message to its own component so it can be re-use in other components */}
        {_showErrorMessage && <div className="text-sm text-error-base">{error}</div>}
      </div>
    )
  },
)

TextInput.displayName = "TextInput"
