import { type MRT_Row, type MRT_TableInstance } from "material-react-table"
import React, { useEffect, useMemo, useState } from "react"
import { DecisionRule, DecisionTableName } from "@/types/DecisionTable"
import { Button, InfoBanner, useDecisionTableContext } from "@/components"
import { REQUEST_ERROR_KEY } from "../../constants"
import { validateAndParseValues } from "../../utils"

interface EditRuleModalContentProps {
  table: MRT_TableInstance<DecisionRule>
  row: MRT_Row<DecisionRule>
  internalEditComponents: React.ReactNode[]
  isNewRule?: boolean
  handleSave: ({
    row,
    table,
  }: {
    row: MRT_Row<DecisionRule>
    table: MRT_TableInstance<DecisionRule>
  }) => void
}

export const EditRuleModalContent = ({
  table,
  row,
  internalEditComponents,
  isNewRule,
  handleSave,
}: EditRuleModalContentProps) => {
  const {
    state: { insideSSOT, name, loading, schema, validationErrors },
    dispatch,
  } = useDecisionTableContext()
  const [showExtendableFields, setShowExtendableFields] = useState(row._valuesCache["extendable"])
  const [showConfirmationContent, setShowConfirmationContent] = useState(false)

  useEffect(() => {
    dispatch({ validationErrors: {} })
  }, [dispatch])

  /**
  THIS IS A HACKY SOLUTION. THIS GOES AGAINST EVERYTHING THAT'S GOOD IN REACT.
  I don't agree with this solution, but the refactoring would require a lot more work, that we don't have.
  This is a temporary solution, and should be refactored as soon as possible.
  */
  useEffect(() => {
    function handleRadioChange({ target }: Event) {
      const { value } = target as HTMLInputElement
      if (value === "true") {
        setShowExtendableFields(true)
      }
      if (value === "false") {
        setShowExtendableFields(false)
      }
    }

    const radioYesOption = document.querySelector("[data-testid='radio-option-true']")
    const radioNoOption = document.querySelector("[data-testid='radio-option-false']")
    if (!radioYesOption || !radioNoOption) return

    radioYesOption.addEventListener("change", handleRadioChange)
    radioNoOption.addEventListener("change", handleRadioChange)

    return () => {
      radioYesOption.removeEventListener("change", handleRadioChange)
      radioNoOption.removeEventListener("change", handleRadioChange)
    }
  }, [])

  const fields = useMemo(() => {
    if (name === DecisionTableName.ProbationPeriod) {
      if (!showExtendableFields) {
        return internalEditComponents.filter(
          field => !(field as any).key.toLowerCase().includes("extension"),
        )
      }
      return internalEditComponents
    }
    return internalEditComponents
  }, [name, internalEditComponents, showExtendableFields])

  const defaultTitle = `${isNewRule ? "Add new" : "Edit"} rule`
  const closeModal = () => {
    dispatch({ validationErrors: {} })
    isNewRule ? table.setCreatingRow(null) : table.setEditingRow(null)
  }

  return (
    <div className="px-8 pb-5 pt-8">
      <h3 className="px-1 mb-6 text-2xl font-semibold">
        {showConfirmationContent ? "Confirm changes" : defaultTitle}
      </h3>
      <div className="flex flex-col p-0 px-1 overflow-auto mb-6" style={{ maxHeight: "70vh" }}>
        {insideSSOT && showConfirmationContent ? (
          <p> Please read this before confirming any changes.</p>
        ) : (
          fields
        )}
        {showConfirmationContent && (
          <InfoBanner className="my-5">
            By adding these values you are responsible for making sure that any long-form text
            content for {insideSSOT?.activeSection.name} is also up-to-date.
          </InfoBanner>
        )}
      </div>

      {validationErrors?.[REQUEST_ERROR_KEY] && (
        <p className="mb-4 text-error-base">{validationErrors[REQUEST_ERROR_KEY]}</p>
      )}

      <div className="flex justify-end">
        <div className="mr-4">
          <Button
            variant="secondary"
            onClick={() => {
              if (showConfirmationContent) {
                setShowConfirmationContent(false)
              } else {
                row._valuesCache = row.original
                closeModal()
              }
            }}
          >
            Cancel
          </Button>
        </div>
        <div>
          <Button
            variant="primary"
            onClick={() => {
              if (insideSSOT && !showConfirmationContent) {
                const { localValidationErrors } = validateAndParseValues({
                  values: row._valuesCache,
                  insideSSOT,
                  columns: schema.columns,
                  tableName: name,
                })
                if (Object.values(localValidationErrors).length > 0) {
                  return dispatch({ validationErrors: localValidationErrors })
                }
                return setShowConfirmationContent(true)
              }
              handleSave({ table, row })
            }}
            loading={loading}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  )
}
