import React, { isValidElement, ReactElement, ReactNode, useRef } from "react"
import classNames from "classnames"
import { SelectOptionProps, useSelectControlProps } from "./types"
import { createDisplayValueFromOptionElement } from "./util"
import { SelectedOption } from "./SelectedOption"
import { validateDisplayNameChild } from "../utils/validateDisplayNameChild"
import { deepFind } from "../utils/deepFind"

export function useSelectControl({
  optionNodes,
  placeholder,
  select,
  UNSAFE_selectedValueDisplay,
}: useSelectControlProps) {
  const {
    interactions: { getReferenceProps } = { getReferenceProps: undefined },
    floating,
    id,
    value,
    disabled,
  } = select

  const lastSelectedOptionNode = useRef<ReactElement<SelectOptionProps>>()

  const getSelectDisplay = (): ReactNode => {
    if (!value) {
      return (
        <div
          className={classNames("overflow-hidden text-ellipsis whitespace-nowrap text-gray-300")}
        >
          {placeholder}
        </div>
      )
    }

    if (UNSAFE_selectedValueDisplay) {
      return UNSAFE_selectedValueDisplay(value)
    }

    // Automatically find the value's display within the Select options
    const child = deepFind(optionNodes, child => {
      return (
        isValidElement(child) &&
        validateDisplayNameChild(child, "Select.Option") &&
        child.props.value === value
      )
    })

    if (child && isValidElement(child)) {
      /**
       * Cached the selected Select option. This is useful for cases where the Select is used in
       * an async manner and Select options are populated from the data of an API response.
       */
      lastSelectedOptionNode.current = child as ReactElement<SelectOptionProps>
      return createDisplayValueFromOptionElement(child as ReactElement<SelectOptionProps, string>)
    }

    // Use the cached Select option here
    if (lastSelectedOptionNode.current && isValidElement(lastSelectedOptionNode.current)) {
      return createDisplayValueFromOptionElement(lastSelectedOptionNode.current)
    }

    // If above fails, fall back to the value as children
    return <SelectedOption value={value}>{value}</SelectedOption>
  }

  return {
    getSelectDisplay,
    getSelectControlProps: () => ({
      ...getReferenceProps?.({
        id,
        "aria-labelledby": `label-${id}`,
        "aria-controls": `select-list-${id}`,
        tabIndex: disabled ? undefined : 0,
        ref: floating?.refs.setReference,
      }),
    }),
    id,
  }
}
