import React, { useCallback, KeyboardEvent } from "react"
import { useListItem } from "@floating-ui/react"
import classNames from "classnames"
import { twMerge } from "tailwind-merge"
import { useSelectContext } from "./SelectContext"
import type { SelectOptionProps } from "./types"
import { BaseSelectOption } from "./BaseSelectOption"

export const SelectOption = ({
  className,
  value: valueProp,
  role = "option",
  disabled,
  ...rest
}: SelectOptionProps) => {
  const {
    activeIndex,
    interactions: { getItemProps } = { getItemProps: undefined },
    value,
    onSelectValue,
  } = useSelectContext()
  const isSelected = value === valueProp
  const { ref, index } = useListItem()
  const isActive = index === activeIndex

  const _className = twMerge(
    classNames(
      {
        "bg-gray-100": isActive, // uses virtual focus
      },
      className,
    ),
  )

  const handleKeyDown = useCallback(
    (evt: KeyboardEvent<HTMLUListElement>) => {
      if (["Enter", " "].includes(evt.key)) {
        evt.preventDefault()
        onSelectValue?.()
      }
    },
    [onSelectValue],
  )

  return (
    <BaseSelectOption
      aria-selected={isSelected}
      aria-disabled={disabled}
      className={_className}
      role={role}
      tabIndex={disabled ? undefined : 0} // Ensure that Floating UI sees each option as tabbable
      data-value={valueProp}
      ref={ref}
      {...(disabled
        ? {}
        : {
            ...getItemProps?.({
              onClick: onSelectValue,
              onKeyDown: handleKeyDown,
            }),
          })}
      {...rest}
    />
  )
}

SelectOption.displayName = "Select.Option"
