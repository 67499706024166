import { useRailsContext } from "@/components"
import { LPSReviewStatus, KMHandoverStatus } from "@/types/SSOTDocument"

type HexColorCode = string
type DocumentStatusType = "legal_and_people_services_review" | "knowledge_management_handover"

export interface DocumentStatusDefinition {
  value: LPSReviewStatus | KMHandoverStatus
  type: DocumentStatusType
  text: string
  color: HexColorCode
}

type LPSReviewStatusDefinition = {
  value: LPSReviewStatus
  type: "legal_and_people_services_review"
} & DocumentStatusDefinition

type KMHandoverStatusDefinition = {
  value: KMHandoverStatus
  type: "knowledge_management_handover"
} & DocumentStatusDefinition

interface DocumentStatusDefinitions {
  lpsReviewStatuses: Array<LPSReviewStatusDefinition>
  kmHandoverStatuses: Array<KMHandoverStatusDefinition>
}

export const useDocumentStatuses: () => DocumentStatusDefinitions = () => {
  const { documentStatuses } = useRailsContext()

  const lpsReviewStatuses = <Array<LPSReviewStatusDefinition>>(
    documentStatuses.filter(({ type }) => type === "legal_and_people_services_review")
  )
  const kmHandoverStatuses = <Array<KMHandoverStatusDefinition>>(
    documentStatuses.filter(({ type }) => type === "knowledge_management_handover")
  )

  return { lpsReviewStatuses, kmHandoverStatuses }
}
