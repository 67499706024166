import React, { ElementType } from "react"
import classNames from "classnames"
import type { FormLabelProps } from "./types"

export const FormLabel = <E extends ElementType = "label">({
  as,
  assistiveText,
  className,
  endAdornment = null,
  isOptional = false,
  label,
  optionalText = "(optional)",
  htmlFor,
  ...rest
}: FormLabelProps<E>) => {
  if (!label) {
    return null
  }

  const Component = as || "label"

  /**
   * The optional element that is rendered if the field is optional
   */
  const optionalElement = isOptional ? (
    <>
      {" "}
      <span className={classNames("text-gray-300")}>{optionalText}</span>
    </>
  ) : null

  return (
    <Component
      className={classNames("flex flex-col text-base font-medium leading-6 text-black", className)}
      htmlFor={htmlFor}
      {...rest}
    >
      <div className="flex flex-row items-center">
        <span className="text-base leading-6">
          {label}
          {optionalElement}
        </span>
        {endAdornment ? <div className="ml-2">{endAdornment}</div> : null}
      </div>
      {!!assistiveText && typeof assistiveText === "string" ? (
        <span className="text-sm font-normal leading-4 text-gray-400">{assistiveText}</span>
      ) : (
        assistiveText
      )}
    </Component>
  )
}

export const FORM_LABEL_DISPLAY_NAME = "FormLabel"

FormLabel.displayName = FORM_LABEL_DISPLAY_NAME
