import classNames from "classnames"
import React, { PropsWithChildren } from "react"

const infoIcon = require("./assets/info.svg")

interface InfoBannerProps {
  className?: string
}

export const InfoBanner = ({ children, className }: PropsWithChildren<InfoBannerProps>) => {
  return (
    <div
      className={classNames(
        "flex items-start p-4 bg-info-lighten-100 text-info-darken-100 rounded-md",
        className,
      )}
    >
      <img src={infoIcon} alt="info" className="block mr-5" />
      {children}
    </div>
  )
}
