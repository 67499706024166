import React, { useCallback, KeyboardEvent } from "react"
import classNames from "classnames"
import { twMerge } from "tailwind-merge"
import { useMultiSelectContext } from "./MultiSelectContext"
import type { MultiSelectOptionProps, MultiSelectOptionWithNodeProps } from "./types"
import { MultiSelectOptionCheckbox } from "./MultiSelectOptionCheckbox"
import { BaseSelectOption } from "../Select/BaseSelectOption"

export const MultiSelectOption = ({
  className: classNameProp,
  endAdornment,
  index,
  role = "option",
  startAdornment,
  value: valueProp,
  ...restProps
}: MultiSelectOptionProps) => {
  const {
    activeIndex,
    interactions: { getItemProps } = { getItemProps: undefined },
    listNodeRef,
    value,
    onSelectValue,
    checkboxPosition,
  } = useMultiSelectContext()

  const isSelected = value?.includes(valueProp as string)

  const _className = twMerge(
    classNames(
      "focus:bg-gray-100", // MultiSelect does not use virtual focus
      classNameProp,
    ),
  )

  const _startAdornment =
    typeof startAdornment === "undefined" && checkboxPosition === "start" ? (
      <MultiSelectOptionCheckbox isChecked={isSelected} />
    ) : (
      startAdornment
    )

  const _endAdornment =
    typeof endAdornment === "undefined" && checkboxPosition === "end" ? (
      <MultiSelectOptionCheckbox isChecked={isSelected} />
    ) : (
      endAdornment
    )

  const handleKeyDown = useCallback(
    (evt: KeyboardEvent<HTMLUListElement>) => {
      if (["Enter", " "].includes(evt.key)) {
        evt.preventDefault()
        onSelectValue?.(valueProp)
      }
    },
    [onSelectValue, valueProp],
  )

  const _tabIndex = activeIndex === null && index === 0 ? 0 : activeIndex === index ? 0 : -1
  const { selectedTagContent, ...rest } = restProps as MultiSelectOptionWithNodeProps

  return (
    <BaseSelectOption
      aria-selected={isSelected}
      className={_className}
      data-value={valueProp}
      endAdornment={_endAdornment}
      role={role}
      startAdornment={_startAdornment}
      tabIndex={_tabIndex}
      ref={node => {
        if (listNodeRef?.current && node) {
          listNodeRef.current[Number(index)] = node
        }
      }}
      {...getItemProps?.({
        onClick: () => onSelectValue?.(valueProp),
        onKeyDown: handleKeyDown,
      })}
      {...rest}
    />
  )
}

MultiSelectOption.displayName = "MultiSelect.Option"
