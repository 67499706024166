import React, { ComponentPropsWithRef, forwardRef, PropsWithChildren } from "react"
import { LoadingSpinner } from "../LoadingSpinner"

type ButtonVariants = "primary" | "secondary" | "danger" | "borderless"

type ButtonProps = {
  variant: ButtonVariants
  className?: string
  loading?: boolean
  disabled?: boolean
} & PropsWithChildren<
  ComponentPropsWithRef<"button"> | PropsWithChildren<ComponentPropsWithRef<"a">>
>

const baseClasses =
  "flex items-center justify-between w-full px-4 py-2 rounded-lg font-medium whitespace-normal outline-0 text-left border-2"

export const Button = forwardRef<HTMLAnchorElement | HTMLButtonElement, ButtonProps>(
  ({ children, className = "", variant, loading, disabled, ...props }, ref) => {
    const variantClasses: Record<ButtonVariants, string> = {
      primary:
        "bg-primary-base border-primary-base hover:bg-primary-darken-100 hover:border-primary-darken-100 text-white",
      secondary:
        "border-primary-base hover:bg-primary-lighten-100 border-2 bg-white text-primary-base",
      danger:
        "bg-error-base border-error-base hover:bg-error-darken-100 hover:border-error-darken-100 text-white",
      borderless: "bg-white text-primary-base border-white",
    }

    const loaderVariantByButtonVariant: Record<ButtonVariants, "primary" | "secondary" | "error"> =
      {
        primary: "secondary",
        secondary: "primary",
        borderless: "primary",
        danger: "error",
      }

    if ("href" in props && !loading && !disabled) {
      const { target, rel } = props

      return (
        <a
          className={`${baseClasses} ${variantClasses[variant]} ${className}`}
          {...props}
          rel={target === "_blank" ? "noopener" : rel}
          {...ref}
        >
          {children}
        </a>
      )
    }

    return (
      <button
        className={`${baseClasses} ${variantClasses[variant]} ${className}`}
        disabled={loading || disabled}
        {...(props as ComponentPropsWithRef<"button">)}
        {...ref}
      >
        {loading ? (
          <LoadingSpinner size="md" variant={loaderVariantByButtonVariant[variant]} />
        ) : (
          children
        )}
      </button>
    )
  },
)
