import React, { ReactNode } from "react"
import classNames from "classnames"
import { useCloseToast } from "./ToastContexts"
import { ToastProgressBar } from "./ToastProgressBar"
import { ToastProps, ToastVariation } from "./types"
import { WarningCircleFill } from "@/components/Toast/assets/warning-circle-fill"
import { CheckCircle } from "@/components/Toast/assets/check-circle"
import { colorPalette } from "@/constants/colorPalette"
const closeIcon = require("./assets/close.svg")

export const ICON_MAP: {
  [key in ToastVariation]: string | ReactNode
} = {
  error: <WarningCircleFill fillColor={colorPalette.error.main} />,
  info: <WarningCircleFill fillColor={colorPalette.info.main} />,
  primary: <CheckCircle />,
  success: <CheckCircle />,
  warning: <WarningCircleFill fillColor={colorPalette.warning.main} />,
}

function ToastTitle({ children }: { children: string }) {
  return <span className="col-start-2 w-full text-lg font-semibold">{children}</span>
}

function ToastContent({ children }: { children: ReactNode }) {
  return <div className="col-start-2 text-black">{children}</div>
}

export function Toast({
  autohideDuration = 5000,
  children,
  closeButtonLabel = "Close alert",
  id,
  variation = "primary",
  isCloseable: showCloseButton = true,
}: ToastProps) {
  const closeToast = useCloseToast()
  const handleCloseToast = () => closeToast(id as string)

  return (
    <div role="alert" className="relative overflow-hidden rounded-md shadow-2xl">
      <div
        className={classNames(
          "grid gap-x-3 border-gray-100 bg-white px-4 py-3",
          showCloseButton
            ? "grid-cols-[24px_auto_16px] grid-rows-[auto_auto]"
            : "grid-cols-[24px_auto] grid-rows-[auto_auto]",
          autohideDuration ? "border-l border-r border-t" : "border",
          {
            "text-error-base": variation === "error",
            "text-info-base": variation === "info",
            "text-success-base": variation === "success",
            "text-warning-base": variation === "warning",
            "text-primary-base": variation === "primary",
          },
        )}
        style={{ gridTemplateAreas: '"icon title close" "icon content content"' }}
      >
        <div style={{ gridArea: "icon" }}>{ICON_MAP[variation]}</div>
        <div style={{ gridArea: "title" }}>{children}</div>
        {showCloseButton && (
          <button
            aria-label={closeButtonLabel}
            onClick={handleCloseToast}
            style={{ gridArea: "close" }}
            className="self-start mt-1"
          >
            <img alt="Close" src={closeIcon} />
          </button>
        )}
      </div>
      {autohideDuration && (
        <ToastProgressBar
          autohideDuration={autohideDuration}
          closeToast={handleCloseToast}
          variation={variation}
        />
      )}
    </div>
  )
}

Toast.Title = ToastTitle
Toast.Content = ToastContent
