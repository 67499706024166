/**
 * Converts a boolean value into his own corresponding string
 *
 * true -> "true"
 *
 * false -> "false"
 */
export const booleanToTextValue = (v: string | undefined | boolean | number | null) =>
  v ? "true" : v === null || v === undefined ? "null" : "false"

/**
 * Converts "true" or "false" into the corresponding boolean value
 * If the string is not one of the two values, it returns null
 */
export const textValueToBoolean = (t?: string) =>
  t === "true" ? true : t === "false" ? false : null
