import { createTheme } from "@mui/material"
import { colorPalette } from "@/constants/colorPalette"

export const decisionTableTheme = createTheme({
  palette: colorPalette,
  typography: {
    fontFamily: "Work Sans",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "0.5rem",
          fontWeight: 500,
          padding: "0.75rem 1.5rem",
          textTransform: "none",
          lineHeight: 1.3,
          fontSize: "1rem",
          boxShadow: "none",
        },
        outlined: {
          border: `2px solid ${colorPalette.grey[300]}`,
          color: "#171717",
          "&:hover": {
            border: `2px solid ${colorPalette.grey[300]}`,
            backgroundColor: colorPalette.primary.light,
          },
        },
      },
    },
  },
})
