import React, { useState } from "react"
import { FieldProps } from "../types"
import { MoneyValue } from "@/types/DecisionTable"
import { allCurrencies } from "@administrate/utils/currencies"
import { MoneyInput, useDecisionTableContext } from "@/components"

export const MoneyField = ({ label, row, column, accessorKey }: FieldProps) => {
  const {
    state: { validationErrors },
    dispatch,
  } = useDecisionTableContext()
  const [value, setValue] = useState<MoneyValue>(row._valuesCache[column.id])

  return (
    <MoneyInput
      currencies={allCurrencies}
      label={label}
      onChange={value => {
        if (value.amount === "-") {
          const update = { amount: "", currencyCode: value.currency }
          row._valuesCache[column.id] = update
          return setValue(update)
        }
        const update = { amount: value.amount, currencyCode: value.currency }
        const parsedAmount = parseFloat(update.amount)
        if (
          update.amount !== "" &&
          update.amount !== undefined &&
          (parsedAmount < 0 || !parsedAmount)
        ) {
          return
        }
        row._valuesCache[column.id] = update
        return setValue(update)
      }}
      value={value ? { ...value, currency: value.currencyCode } : undefined}
      error={validationErrors[accessorKey]}
      onFocus={() =>
        dispatch({
          validationErrors: {
            ...validationErrors,
            [accessorKey]: undefined,
          },
        })
      }
    />
  )
}
