import React, { Children, cloneElement, FunctionComponent, isValidElement, useMemo } from "react"
import classNames from "classnames"
import { SelectContext } from "./SelectContext"
import { SelectControl } from "./SelectControl"
import { SelectedOption } from "./SelectedOption"
import { SelectEmptyState } from "./SelectEmptyState"
import { SelectGroup } from "./SelectGroup"
import { SelectInput } from "./SelectInput"
import { SelectList } from "./SelectList"
import { SelectOption } from "./SelectOption"
import type { SelectProps } from "./types"
import { useSelect } from "./useSelect"
import {
  WRAPPER_CLASSES,
  WRAPPER_MD_CLASSES,
  WRAPPER_SM_CLASSES,
} from "../utils/formComponentWrapperClasses"

export const Select = ({
  children,
  disabled,
  error,
  size = "md",
  id: idProp,
  isClearable,
  name: nameProp,
  placeholder,
  UNSAFE_selectedValueDisplay,
  label,
  value: valueProp,
  emptyState = <SelectEmptyState />,
  onChange,
  onFocus,
  onBlur,
}: SelectProps) => {
  const select = useSelect({
    value: valueProp,
    disabled,
    id: idProp,
    name: nameProp,
    onChange,
  })

  const isMD = size === "md"
  const isSM = size === "sm"

  const _className = classNames(WRAPPER_CLASSES, {
    [WRAPPER_MD_CLASSES]: isMD,
    [WRAPPER_SM_CLASSES]: isSM,
  })

  const _label = useMemo(() => {
    return label && isValidElement(label)
      ? cloneElement(label, {
          htmlFor: label.props.htmlFor || select.id,
          size,
          ...label.props,
          id: `label-${select.id}`,
        })
      : label
  }, [label, size, select.id])

  // remove search from optionNodes if it's there
  const optionNodes = Children.toArray(children).filter(
    child => (child as unknown as FunctionComponent)?.displayName !== "Select.Input",
  )

  const handleBlur = () => {
    if (select.open) return
    onBlur?.()
  }

  return (
    <SelectContext.Provider value={{ ...select, error, isSM, isMD, placeholder, onChange }}>
      <div className={_className}>
        {_label}
        <div>
          <SelectControl
            isClearable={isClearable}
            optionNodes={optionNodes}
            placeholder={placeholder}
            UNSAFE_selectedValueDisplay={UNSAFE_selectedValueDisplay}
            onFocus={onFocus}
            onBlur={handleBlur}
          />
          <SelectList emptyState={emptyState}>{children}</SelectList>
        </div>
        {!!error && <span className="text-sm block text-error-base">{error}</span>}
      </div>
    </SelectContext.Provider>
  )
}

Select.Option = SelectOption
Select.SelectedOption = SelectedOption
Select.Group = SelectGroup
Select.List = SelectList
Select.Input = SelectInput
Select.EmptyState = SelectEmptyState
