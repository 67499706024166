import React from "react"
import { Button, useDecisionTableContext } from "@/components"
import { type MRT_TableInstance } from "material-react-table"
import { DecisionRule, DecisionTable, DecisionTableName } from "@/types/DecisionTable"
import { amplitudeLogEvent } from "@/utils/amplitudeHelpers"

const arrowSquareOut = require("./assets/arrow-square-out.svg")

interface ToolbarCustomActionsProps {
  table: MRT_TableInstance<DecisionRule>
}

interface TableLinkProps {
  linkedTable?: DecisionTable
}

const TableLink = ({ linkedTable }: TableLinkProps) => {
  if (linkedTable == null) return null

  const getLinkText = () => {
    if (linkedTable.name?.startsWith(DecisionTableName.CBALevels)) {
      return "Job level to seniority"
    }
    return linkedTable.name
  }

  return (
    <div>
      <Button
        variant="secondary"
        className="flex items-center justify-between"
        onClick={() => {
          amplitudeLogEvent("[SE]CA-click_go_to_linked_decision_table")
          window.location.href = linkedTable.urls.app.resource
        }}
      >
        <span>{getLinkText()}</span>
        <span className="inline-block ml-2">
          <img src={arrowSquareOut} alt="link icon" />
        </span>
      </Button>
    </div>
  )
}

export const ToolbarCustomActions = ({ table }: ToolbarCustomActionsProps) => {
  const {
    state: { linkedTable, actions, insideSSOT },
  } = useDecisionTableContext()

  const showCreateButton = actions.update && (!insideSSOT || insideSSOT?.editMode)

  return (
    <div style={{ width: "calc(100% - 300px)" }} className="flex justify-end -mr-4">
      <TableLink linkedTable={linkedTable} />
      {showCreateButton && (
        <div className="ml-5">
          <Button
            variant="primary"
            onClick={() => {
              table.setCreatingRow(true)
            }}
          >
            Add new rule
          </Button>
        </div>
      )}
    </div>
  )
}
