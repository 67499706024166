import classNames from "classnames"
import React, { cloneElement, forwardRef, useEffect, useRef } from "react"
import type { CheckboxProps } from "./types"
import {
  BASE_CHECKED_CLASSES,
  BASE_CLASSES,
  BASE_DISABLED_CLASSES,
  BASE_HOVER_CLASSES,
  CONTAINER_BASE_CLASSES,
  ERROR_CLASSES,
} from "./constants"
import { FORM_COMPONENT_FOCUS_CLASSES, FOCUS_STATE_BASE_CLASSES } from "../utils/focusStateClasses"
import { twMerge } from "tailwind-merge"
import { isFormLabelComponent } from "../utils/isFormLabelComponent"
import { Check, Minus } from "./icons"

function mergeRefs<T>(...refs: React.Ref<T>[]): React.RefCallback<T> {
  return (element: T) => {
    for (let i = 0; i < refs.length; i++) {
      const ref = refs[i]
      if (typeof ref === "function") ref(element)
      else if (ref && typeof ref === "object") (ref as React.MutableRefObject<T>).current = element
    }
  }
}

/**
 * Checkbox extends HTMLInputElement
 */
export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    { className, name, disabled, label, value, id, hasError, isIndeterminate = false, ...rest },
    ref,
  ) => {
    const _internalRef = useRef<HTMLInputElement>()
    const _id = id ?? `checkbox-${value}`
    const _className = twMerge(
      classNames(
        BASE_CLASSES,
        BASE_HOVER_CLASSES,
        BASE_CHECKED_CLASSES,
        FOCUS_STATE_BASE_CLASSES,
        FORM_COMPONENT_FOCUS_CLASSES,
        {
          [BASE_DISABLED_CLASSES]: disabled,
          [ERROR_CLASSES]: hasError && !disabled,
        },
      ),
    )

    useEffect(() => {
      if (_internalRef.current) {
        _internalRef.current.indeterminate = isIndeterminate
      }
    }, [isIndeterminate])

    const _classNameContainer = classNames(CONTAINER_BASE_CLASSES, className)

    /**
     * As FormLabel is polymorphic component we can render it as a div tag.
     * We need to this because we need to wrap the checkbox with the label and it is not possible
     * to have 2 labels associated with the same input.
     */
    const formLabelAsDiv = isFormLabelComponent(label)
      ? cloneElement(label, {
          ...label.props,
          as: "div",
          className: classNames(label.props.className, "cursor-pointer"),
        })
      : label

    return (
      <label htmlFor={_id} className={_classNameContainer}>
        <input
          className="peer sr-only"
          disabled={disabled}
          id={_id}
          name={name}
          ref={mergeRefs(ref, _internalRef)}
          type="checkbox"
          value={value}
          {...rest}
        />

        <span data-testid="checkbox-control" className={_className}>
          {!isIndeterminate && <Check data-testid="checkbox-icon-check" />}
          {isIndeterminate && <Minus data-testid="checkbox-icon-minus" />}
        </span>

        {formLabelAsDiv}
      </label>
    )
  },
)
Checkbox.displayName = "Checkbox"
