import dayjs from "dayjs"
import duration from "dayjs/plugin/duration"
import { type MRT_Row } from "material-react-table"
import { DecisionRule } from "@/types/DecisionTable"
import { Duration } from "../types"
import { DURATION_RANGE_KEYS } from "../constants"

dayjs.extend(duration)

// The logic here is: Sort by begin first, then by end.
// Null begin (“since always”) is less than non-null begin.
// Null end (“forever on”) is greater than non-null end.

export const sortDurationRanges = (
  rowA: MRT_Row<DecisionRule>,
  rowB: MRT_Row<DecisionRule>,
  columnId: string,
) => {
  const rangeA = rowA._valuesCache[columnId] as Duration | undefined
  const rangeB = rowB._valuesCache[columnId] as Duration | undefined

  if (!rangeA && rangeB) {
    return -1
  }

  if (rangeA && !rangeB) {
    return 1
  }

  if (!rangeA && !rangeB) {
    return 0
  }

  if (!rangeA[DURATION_RANGE_KEYS.BEGIN] && rangeB[DURATION_RANGE_KEYS.BEGIN]) {
    return -1
  }

  if (rangeA[DURATION_RANGE_KEYS.BEGIN] && !rangeB[DURATION_RANGE_KEYS.BEGIN]) {
    return 1
  }

  if (rangeA[DURATION_RANGE_KEYS.BEGIN] && rangeB[DURATION_RANGE_KEYS.BEGIN]) {
    const durationBeginA = dayjs.duration(rangeA[DURATION_RANGE_KEYS.BEGIN]).as("hours")
    const durationBeginB = dayjs.duration(rangeB[DURATION_RANGE_KEYS.BEGIN]).as("hours")

    if (durationBeginA > durationBeginB) {
      return 1
    }

    if (durationBeginB > durationBeginA) {
      return -1
    }
  }

  if (rangeA[DURATION_RANGE_KEYS.END] && !rangeB[DURATION_RANGE_KEYS.END]) {
    return -1
  }

  if (!rangeA[DURATION_RANGE_KEYS.END] && rangeB[DURATION_RANGE_KEYS.END]) {
    return 1
  }

  if (rangeA[DURATION_RANGE_KEYS.END] && rangeB[DURATION_RANGE_KEYS.END]) {
    const durationEndA = dayjs.duration(rangeA[DURATION_RANGE_KEYS.END]).as("hours")
    const durationEndB = dayjs.duration(rangeB[DURATION_RANGE_KEYS.END]).as("hours")

    if (durationEndA > durationEndB) {
      return 1
    }

    if (durationEndB > durationEndA) {
      return -1
    }
  }

  return 0
}
