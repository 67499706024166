import { Box, IconButton, Tooltip } from "@mui/material"
import { type MRT_Row, type MRT_TableInstance } from "material-react-table"
import React, { useMemo } from "react"
import { DecisionRule } from "@/types/DecisionTable"
import { useDecisionTableContext } from "@/components"
import { isPublicOysterScore } from "@/components/utils/isPublicOysterScore"

const pencilIcon = require("./assets/pencil.svg")
const trashIcon = require("./assets/trash.svg")

interface ActionsCellProps {
  table: MRT_TableInstance<DecisionRule>
  row: MRT_Row<DecisionRule>
}

export const ActionsCell = ({ table, row }: ActionsCellProps) => {
  const { state, dispatch } = useDecisionTableContext()

  const shouldHideButtons = useMemo(() => {
    // If it's a subdivision document and the row belongs to the whole country
    if (state.insideSSOT?.subdivisionCode && !row.original.subdivisionCode) {
      return true
    }

    // If the row belongs to a different Oyster score than the current one
    const kind = state.insideSSOT?.activeSection?.kind
    if (
      state.insideSSOT &&
      // Only apply this to tables that include and Oyster score column
      state.schema.columns.find(({ name }) => name === "oysterScore") &&
      // TODO: Remove this check when transition to next level nesting is completed
      isPublicOysterScore(kind) &&
      kind !== row.original.oysterScore
    ) {
      return true
    }

    return false
  }, [
    row.original.oysterScore,
    row.original.subdivisionCode,
    state.insideSSOT,
    state.schema.columns,
  ])

  return !shouldHideButtons ? (
    <Box className="flex justify-end gap-x-4">
      <Tooltip title="Edit">
        <IconButton onClick={() => table.setEditingRow(row)}>
          <img src={pencilIcon} alt="edit" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete">
        <IconButton color="error" onClick={() => dispatch({ showDeletionModal: row.id })}>
          <img src={trashIcon} alt="delete" />
        </IconButton>
      </Tooltip>
    </Box>
  ) : null
}
