export const colorPalette = {
  primary: {
    main: "#1E4637",
    light: "#F0F8F5",
    dark: "#122A21",
  },
  error: {
    main: "#B00020",
    light: "#FDE1E1",
    dark: "#6E031E",
  },
  info: {
    main: "#0367A6",
    light: "#D9ECF7",
    dark: "#023E64",
  },
  warning: {
    main: "#E98525",
    light: "#FCF0DB",
    dark: "#814004",
  },
  grey: {
    100: "#F5F5F5",
    200: "#E3E3E3",
    300: "#A3A3A3",
    400: "#595959",
  },
  text: {
    primary: "#171717",
  },
}
