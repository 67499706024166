import { Editor } from "tinymce"

const customLinkOnAction = (editor: Editor) => {
  const selectedNode = editor.selection.getNode()
  let selectedText = editor.selection?.getContent({ format: "text" })
  let link = ""
  let title = ""

  if (selectedNode.nodeName === "A") {
    selectedText = selectedNode.innerText
    link = selectedNode.getAttribute("href")
    title = selectedNode.getAttribute("title")
  }

  editor.windowManager.open({
    title: "Insert/Edit Link",
    body: {
      type: "panel",
      items: [
        {
          type: "input",
          name: "link",
          label: "URL",
        },
        {
          type: "htmlpanel",
          html: '<div id="link-error" style="color: #b00020; font-size: 14px; margin-bottom: 4px; display: none;">Invalid link</div>',
        },
        {
          type: "input",
          name: "displayText",
          label: "Text to display",
        },
        {
          type: "input",
          name: "title",
          label: "Title",
        },
      ],
    },
    initialData: {
      displayText: selectedText ?? "",
      link: link ?? "",
      title: title ?? "",
    },
    buttons: [
      {
        type: "submit",
        text: "Insert",
        primary: true,
      },
    ],
    onSubmit: api => {
      const protocolRegex = /^https?:\/\//i
      const data = api.getData()
      const displayText = data.displayText.length > 0 ? data.displayText : data.link
      // Add protocol to those links that don't include it
      const link = protocolRegex.test(data.link) ? data.link : `https://${data.link}`
      const title = data.title
      const createLink = () =>
        editor.insertContent(`<a href="${link}" title="${title}">${displayText}</a>`)

      const isValidUrl = string => {
        try {
          new URL(string)
          return true
        } catch {
          return false
        }
      }

      if (isValidUrl(link)) {
        if (selectedNode.nodeName === "A") {
          // Remove the current link
          selectedNode.parentNode.removeChild(selectedNode)

          createLink()
        } else {
          createLink()
        }
        api.close()
      } else {
        document.getElementById("link-error").style.display = "block"
        ;(document.querySelector("[data-mce-name='link']") as HTMLElement).style.border =
          "2px solid #b00020"
      }
    },
  })
}

export const customlink = (editor: Editor) => {
  // Custom button added to the toolbar
  editor.ui.registry.addButton("customlink", {
    icon: "link",
    tooltip: "Insert link",
    onAction: () => customLinkOnAction(editor),
    onSetup: () => {
      // Add NodeChange event listener to show the link button as active when the
      // cursor is over a link
      const nodeChangeHandler = function (e) {
        const selectedNode = e.element
        const isLink = selectedNode.nodeName === "A" || selectedNode.closest("a")
        if (isLink) {
          editor.container
            ?.querySelector("[data-mce-name='customlink']")
            ?.classList.add("tox-tbtn--enabled")
        } else {
          editor.container
            ?.querySelector("[data-mce-name='customlink']")
            ?.classList.remove("tox-tbtn--enabled")
        }
      }

      editor.on("NodeChange", nodeChangeHandler)

      // Remove event listener when the button is destroyed
      return function () {
        editor.off("NodeChange", nodeChangeHandler)
      }
    },
  })

  editor.ui.registry.addButton("currentlinktext", {
    type: "button",
    text: "",
    onAction: () => null,
  })

  // Custom button to show the link's text. When clicked it opens
  // the custom link modal
  editor.ui.registry.addButton("linktext", {
    text: "",
    tooltip: "Edit link",
    onSetup: function (buttonApi) {
      // Function to update the button's text based on the selected link
      const updateButtonText = function () {
        const selectedNode = editor.selection.getNode()
        if (selectedNode.nodeName === "A") {
          const linkText = selectedNode.getAttribute("href").substring(0, 25)
          buttonApi.setText(linkText) // Set button text to the link href
        }
      }

      // Update the button's text when the selection changes
      editor.on("NodeChange", updateButtonText)

      // Initial text setup when toolbar is shown
      updateButtonText()

      return function () {
        editor.off("NodeChange", updateButtonText)
      }
    },
    onAction: () => customLinkOnAction(editor),
  })

  // Custom toolbar that is shown when the cursor is on a link
  editor.ui.registry.addContextToolbar("link", {
    predicate: function (node) {
      return node.nodeName === "A"
    },
    items: "linktext unlink openlink",
    position: "selection",
    scope: "node",
  })
}
