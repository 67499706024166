import { ReactNode, Children, isValidElement } from "react"

export function deepFind(
  children: ReactNode,
  deepFindFn: (child: ReactNode, index?: number, children?: ReactNode[]) => boolean,
): ReactNode | undefined {
  let found: ReactNode

  Children.toArray(children).find((child: ReactNode, index: number, findChildren: ReactNode[]) => {
    if (deepFindFn(child, index, findChildren)) {
      found = child
      return true
    }

    if (isValidElement(child)) {
      found = deepFind(child.props.children, deepFindFn)
      return typeof found !== "undefined"
    }

    return false
  })

  return found
}
