import React, { createContext, useContext } from "react"
import { InputGroupContextInterface, InputGroupContextProviderProps } from "./types"

const InputGroupContext = createContext<InputGroupContextInterface>({ size: "md" })

export const InputGroupContextProvider = ({
  children,
  size,
  error,
}: InputGroupContextProviderProps) => {
  return <InputGroupContext.Provider value={{ size, error }}>{children}</InputGroupContext.Provider>
}

export const useInputGroupContext = () => useContext(InputGroupContext)
