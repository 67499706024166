import React from "react"
import { sentenceCase } from "change-case"
import { EMPTY_CELL } from "../constants"

interface ArrayCellProps {
  items: string[]
}

export const ArrayCell = ({ items }: ArrayCellProps) => {
  if (items.length === 0) return EMPTY_CELL

  return (
    <>
      {items.map((item, index) => (
        <span key={item}>
          {index !== 0 && ", "}
          {sentenceCase(item)}
        </span>
      ))}
    </>
  )
}
