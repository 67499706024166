import React, { PropsWithChildren } from "react"
import { PortalLayer, ReactPortal } from "../ReactPortal"

const closeIcon = require("./assets/close.svg")

interface ModalProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  title?: string
}

export const Modal = ({ children, isOpen, setIsOpen, title }: PropsWithChildren<ModalProps>) => {
  if (!isOpen) {
    return null
  }

  return (
    <ReactPortal
      layer={PortalLayer.Modal}
      className="w-screen h-screen fixed top-0 left-0 flex items-center justify-center z-30"
    >
      <div className="w-full h-full bg-black absolute top-0 left-0 flex items-center justify-center opacity-50"></div>
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white pt-10 pb-6 px-8 z-40 rounded-md min-w-96 max-w-screen-md">
        <button onClick={() => setIsOpen(false)} className="absolute top-4 right-4">
          <img alt="Close" src={closeIcon} />
        </button>
        {title && <h3 className="font-semibold text-black text-xl mb-4">{title}</h3>}
        {children}
      </div>
    </ReactPortal>
  )
}
