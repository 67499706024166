import { Children, isValidElement, ReactElement, ReactNode, useEffect, useRef } from "react"
import { devWarning } from "@/components/utils/devWarning"
import { validateDisplayNameChild } from "../utils/validateDisplayNameChild"
import { useMultiSelectContext } from "./MultiSelectContext"
import {
  MultiSelectOptionProps,
  MultiSelectOptionWithNodeProps,
  TagConfig,
  UseMultiSelectValueTagsConfigArgs,
} from "./types"

const isOptionWithNode = (
  element: ReactElement<MultiSelectOptionProps>,
): element is ReactElement<MultiSelectOptionWithNodeProps> => {
  return typeof element.props.children !== "string"
}

const getLabelFromOption = (optionEl: ReactElement<MultiSelectOptionProps>) => {
  if (isOptionWithNode(optionEl)) {
    devWarning(
      !optionEl.props.selectedTagContent,
      "MultiSelect.Option with a custom React Node must be used with selectedTagContent prop so that it can display the correct tag when selected",
    )

    return optionEl.props.selectedTagContent || ""
  }

  return optionEl.props.children as string
}

/**
 * Mutates the given tagsCache and returns it
 */
const updateTagsCache = (children: ReactNode, tagsCache: Map<string, TagConfig>) => {
  const optionEls = Children.toArray(children).filter(
    child => isValidElement(child) && validateDisplayNameChild(child, "MultiSelect.Option"),
  )
  optionEls.forEach(optionEl => {
    const props = (optionEl as ReactElement).props
    const optionValue = props.value

    if (tagsCache.has(optionValue)) {
      return
    }

    tagsCache.set(optionValue, {
      value: optionValue,
      label: getLabelFromOption(optionEl as ReactElement<MultiSelectOptionProps>),
    })
  })

  return tagsCache
}

/**
 * This hook watches the options and caches their values and labels as the options change.
 * Any selected option is maintained in the cache even when the option is no longer available in the list
 */
export const useMultiSelectValueTagsConfig = ({ optionEls }: UseMultiSelectValueTagsConfigArgs) => {
  const { value } = useMultiSelectContext()
  const tagsCache = useRef<Map<string, TagConfig>>(updateTagsCache(optionEls, new Map()))
  useEffect(() => {
    updateTagsCache(optionEls, tagsCache.current)
  }, [optionEls])

  const tagsConfig: TagConfig[] = []
  if (value.length < 1) {
    return undefined
  }
  value.forEach(valueString => {
    const tagConfig = tagsCache.current.get(valueString)
    if (tagConfig) {
      tagsConfig.push(tagConfig)
    }
  })

  return tagsConfig
}
