import { JSONSchema7 } from "json-schema"
import { ContentOwner, ISO8601DateTime, User } from "./commons"

export enum DecisionTableName {
  ProbationPeriod = "Probation Period",
  CBALevels = "CBA Levels",
}

export type DecisionTableContentOwnership = {
  countryCode: string
  id: number
  oysterScore: string
  urls: ContentOwnershipUrls
  user: ContentOwner
}

export enum DecisionColumnTypeName {
  Array = "Array",
  Boolean = "Boolean",
  Date = "Date",
  DateTime = "DateTime",
  Enum = "Enum",
  Float = "Float",
  Hash = "Hash",
  Integer = "Integer",
  ISO8601Duration = "ISO8601Duration",
  Nil = "Nil",
  Range = "Range",
  String = "String",
  Time = "Time",
  ISO31661Alpha2CountryCode = "ISO31661Alpha2CountryCode",
  ISO31662SubdivisionCode = "ISO31662SubdivisionCode",
  ContractType = "ContractType",
  TeamMemberSeniority = "TeamMemberSeniority",
  TeamMemberLevel = "TeamMemberLevel",
  OysterScore = "OysterScore",
  Money = "Money",
  LevelNameOverride = "LevelNameOverride",
  CBALevel = "CBALevel",
  CBAStatus = "CBAStatus",
  Owner = "Owner",
}

// Some decision column types have extra attributes, e.g. legal `values`
// for `Enum` types or `innerType` of `Array` and `Range`.
// @see SageEditor::DecisionTables::ColumnTypes
type DecisionColumnTypeKey = "values" | "innerType"

export interface DecisionColumnType {
  name: DecisionColumnTypeName
  base?: DecisionColumnType
  keys?: DecisionColumnTypeKey[]
}

export interface MoneyValue {
  amount: string
  currencyCode: string
}

export interface DecisionColumnEnumValue {
  value: string
  title: string
}

export type DecisionColumnEnum = {
  values: DecisionColumnEnumValue[]
} & DecisionColumnType

export type DecisionColumnInnerTyped = {
  innerType: DecisionColumnType
} & DecisionColumnType

export interface DecisionColumn {
  name: string
  title: string
  type: DecisionColumnType | DecisionColumnEnum | DecisionColumnInnerTyped
  required?: boolean
}

interface DecisionSchema {
  columns: DecisionColumn[]
}

export type DecisionRuleInput = Record<string, any>
export type DecisionRule = DecisionRuleInput & { id: number }

export interface DecisionRow {
  createdAt: ISO8601DateTime
  updatedAt: ISO8601DateTime
  id: number
  urls: {
    api: {
      index: string
      resource: string
    }
  }
  rule: DecisionRule
  owner?: ContentOwner
}

export interface DecisionTable {
  id: number
  name: string
  description?: string
  schema: DecisionSchema
  decisionRows: DecisionRow[]
  jsonSchema: JSONSchema7
  createdAt: ISO8601DateTime
  updatedAt: ISO8601DateTime
  publishedAt?: ISO8601DateTime
  updatedBy?: User
  publishedBy?: User
  linkedTable?: DecisionTable
  contentOwnerships?: DecisionTableContentOwnership[]
  urls: {
    app: {
      index: string // POST to create
      resource: string
      edit: string
    }
    api: {
      index: string // POST to create
      resource: string
      publish: string // POST to publish
      addOwner: string // POST
      decisionRows: {
        index: string // POST to create
      }
    }
  }
}

export interface ContentOwnershipUrls {
  api: {
    delete: string
    update: string
  }
}

export enum OysterScores {
  Statutory = "statutory",
  Customary = "customary",
  BestInClass = "best_in_class",
}
