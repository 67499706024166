import React, { MouseEventHandler } from "react"
import classNames from "classnames"
import { twMerge } from "tailwind-merge"
import {
  BASE_ERROR_CLASSES,
  BASE_FOCUS_CLASSES,
  BASE_MD_CLASSES,
  BASE_SM_CLASSES,
  DISABLED_CLASSES,
} from "./constants"
import { useSelectContext } from "./SelectContext"
import { useSelectControl } from "./useSelectControl"
import { SelectControlProps } from "./types"
import { FOCUS_STATE_BASE_CLASSES, FORM_COMPONENT_FOCUS_CLASSES } from "../utils/focusStateClasses"

const chevronUp = require("./assets/chevron-up.svg")

export const SelectControl = ({
  placeholder,
  onBlur,
  isClearable,
  onFocus,
  optionNodes,
  UNSAFE_selectedValueDisplay,
}: SelectControlProps) => {
  const select = useSelectContext()

  const selectControl = useSelectControl({
    select,
    placeholder,
    optionNodes,
    UNSAFE_selectedValueDisplay,
  })
  const handleClear: MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation()
    select.onChange?.("")
  }

  const { isSM, isMD, error, open, disabled, id } = select
  const _classNameIcon = classNames("transition-all shrink-0", { "rotate-180": !open })
  const _classNameControl = twMerge(
    classNames(
      "cursor-pointer flex w-full items-center justify-between gap-2 border-2 border-gray-300 text-left outline-none transition-all focus:border-primary-base bg-white",
      {
        "border-primary-base ring-4": open,
        [BASE_MD_CLASSES]: isMD,
        [BASE_SM_CLASSES]: isSM,
        [BASE_FOCUS_CLASSES]: error,
        [BASE_ERROR_CLASSES]: error,
        [FOCUS_STATE_BASE_CLASSES]: !disabled,
        [FORM_COMPONENT_FOCUS_CLASSES]: !disabled,
        [DISABLED_CLASSES]: disabled,
      },
    ),
  )

  return (
    <div
      className={_classNameControl}
      {...selectControl.getSelectControlProps?.()}
      onFocus={onFocus}
      onBlur={onBlur}
      data-disabled={disabled}
    >
      <div id={`select-control-${id}`} className="w-full overflow-hidden">
        {selectControl.getSelectDisplay()}
      </div>
      {isClearable && select.value && (
        <button
          className="flex h-6 items-center"
          type="button"
          aria-label="Clear"
          onClick={handleClear}
        >
          <span>X</span>
        </button>
      )}
      <img className={_classNameIcon} src={chevronUp} alt={`${open ? "Close" : "Open"} dropdown`} />
    </div>
  )
}
