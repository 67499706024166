import React, { useEffect } from "react"
import { FloatingFocusManager, useDismiss, useFloating, useInteractions } from "@floating-ui/react"
import { FocusManagerProps } from "./types"

export const FocusManager = ({
  initialFocus = 0,
  isFocusTrapped = true,
  isDismissible = true,
  isOpen = true,
  render,
  returnFocusRef,
  onRequestOpenChange,
}: FocusManagerProps) => {
  const {
    context,
    refs: { setFloating },
  } = useFloating({
    open: isOpen,
    onOpenChange: onRequestOpenChange,
  })
  const dismiss = useDismiss(context, { enabled: isDismissible })
  const { getFloatingProps } = useInteractions([dismiss])
  const _initialFocus = initialFocus === null ? -1 : initialFocus

  useEffect(() => {
    const returnRef = returnFocusRef?.current
    return () => (returnRef as HTMLElement)?.focus()
  }, [returnFocusRef])

  return (
    <FloatingFocusManager context={context} modal={isFocusTrapped} initialFocus={_initialFocus}>
      {render({ getRenderProps: () => ({ ref: setFloating, ...getFloatingProps }) })}
    </FloatingFocusManager>
  )
}
