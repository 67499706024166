import React from "react"
import classNames from "classnames"
import { SelectedOptionProps } from "./types"
import { getGridClassNames } from "../utils/getGridClassNames"

/**
 * This component is displayed in the SelectControl when a value is selected
 */
export function SelectedOption({
  children,
  className,
  endAdornment,
  startAdornment,
  value,
}: SelectedOptionProps) {
  const _className = classNames(getGridClassNames(startAdornment, endAdornment), className)

  return (
    <div className={_className} data-value={value}>
      {startAdornment && <div aria-hidden>{startAdornment}</div>}
      <div className="overflow-hidden text-ellipsis whitespace-nowrap">{children}</div>
      {endAdornment && <div aria-hidden>{endAdornment}</div>}
    </div>
  )
}
