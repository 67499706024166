import dayjs from "dayjs"
import duration from "dayjs/plugin/duration"
import { type MRT_Row } from "material-react-table"
import { DecisionRule } from "@/types/DecisionTable"

dayjs.extend(duration)

export const sortDurations = (
  rowA: MRT_Row<DecisionRule>,
  rowB: MRT_Row<DecisionRule>,
  columnId: string,
) => {
  const durationA = dayjs.duration(rowA._valuesCache[columnId]).as("hours")
  const durationB = dayjs.duration(rowB._valuesCache[columnId]).as("hours")

  if (durationA > durationB) {
    return 1
  }

  if (durationB > durationA) {
    return -1
  }

  return 0
}
