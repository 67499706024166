import { ComponentProps, isValidElement, ReactElement, ReactNode } from "react"
import { FormLabel, FORM_LABEL_DISPLAY_NAME } from "../FormLabel/FormLabel"

export const isFormLabelComponent = (
  label: ReactNode,
): label is ReactElement<ComponentProps<typeof FormLabel>> => {
  return !!(
    label &&
    isValidElement(label) &&
    (label["type"] as unknown as Record<string, string>)?.displayName === FORM_LABEL_DISPLAY_NAME
  )
}
