import React from "react"
import { Pin } from "./Pin"
import { ColumnMenuItemProps } from "../types"
import { useDecisionTableContext } from "@/components"

export const Unpin = ({ column, closeMenu }: ColumnMenuItemProps) => {
  const { state, dispatch } = useDecisionTableContext()

  return (
    <Pin
      onClick={() => {
        dispatch({
          pinnedColumns: {
            left: state.pinnedColumns.left.filter(id => id !== column.id),
            right: state.pinnedColumns.right.filter(id => id !== column.id),
          },
        })
        closeMenu()
      }}
      text="Unpin"
    />
  )
}
