import React from "react"
import classNames from "classnames"
import { MultiSelectOptionCheckboxProps } from "./types"
const check = require("./assets/check.svg")

export function MultiSelectOptionCheckbox({ isChecked }: MultiSelectOptionCheckboxProps) {
  const _className = classNames(
    isChecked ? "border-primary-base bg-primary-base block" : "border-gray-300",
    "rounded border-2 text-white w-5 h-5 grid items-center flex-none",
  )

  return (
    <div aria-hidden className={_className}>
      {isChecked && <img src={check} alt="selected" />}
    </div>
  )
}
