/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactNode, createContext, useContext } from "react"
import { SSOTDocument } from "@/types/SSOTDocument"
import { AdministrateActions } from "@/types/commons"
import { useStateReducer } from "@/hooks/useStateReducer"

export interface SSOTDocumentState {
  ssotDocument: SSOTDocument
  actions: AdministrateActions
  skinUrl: string
  editorContent: string
  editMode: boolean
}

interface SSOTDocumentContextType {
  state: SSOTDocumentState
  dispatch: (update: Partial<SSOTDocumentState>) => void
}

const SSOTDocumentContext = createContext<SSOTDocumentContextType | null>(null)

export const useSSOTDocumentContext = () => {
  const context = useContext(SSOTDocumentContext)

  if (!context) throw new Error("SSOTDocumentContext must be called within SSOTDocumentProvider")

  return context
}

export const SSOTDocumentProvider = ({
  children,
  initialState,
}: {
  children: ReactNode
  initialState: Omit<SSOTDocumentState, "editorContent">
}) => {
  const { state, dispatch } = useStateReducer<SSOTDocumentState>({
    ...initialState,
    editorContent: "",
  })

  return (
    <SSOTDocumentContext.Provider value={{ state, dispatch }}>
      {children}
    </SSOTDocumentContext.Provider>
  )
}
