import { type BadgeVariant } from "@/components"
import { type LPSReviewStatus } from "@/types/SSOTDocument"

export const statusToBadgeVariant = (status: LPSReviewStatus): BadgeVariant => {
  const variantByStatus: Record<LPSReviewStatus, BadgeVariant> = {
    not_started: "grey",
    work_in_progress: "warning",
    for_review: "error",
    people_services_review_completed: "info",
    legal_drafting_review_pending: "grey",
    legal_review_completed: "info",
    approved: "success",
  }

  return variantByStatus[status]
}
