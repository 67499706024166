import { ListItemIcon, MenuItem } from "@mui/material"
import React from "react"
import FilterListIcon from "@mui/icons-material/FilterList"
import { ColumnMenuItemProps } from "../types"
import { useDecisionTableContext } from "@/components"

export const ToggleShowColumnFilters = ({ column, closeMenu }: ColumnMenuItemProps) => {
  const {
    state: { showColumnFilters },
    dispatch,
  } = useDecisionTableContext()

  return (
    <MenuItem
      onClick={() => {
        dispatch({ showColumnFilters: !showColumnFilters })
        closeMenu()
      }}
    >
      <ListItemIcon>
        <FilterListIcon />
      </ListItemIcon>
      {showColumnFilters ? "Hide filters" : `Filter by ${column.columnDef.header}`}
    </MenuItem>
  )
}
