/**
 * Takes in input string and converts it to use dot separator and allow max of 2 decimal digits
 * Used in moneyinput instead of type=number, so users can copy/paste amounts from other places, using commas and spaces etc
 * @param value input string
 * @returns formatted value
 */

export function convertToMaxDecimals(value: string, maxDecimalDigits: number) {
  let result = value
  // Replace commas with dots
  result = result.replace(",", ".")
  // Exception two dots ..
  result = result.replace(/[.]+/, ".")
  // Remove all forein chars, leave only digits, dots and minus
  result = result.replace(/[^-\d.]*/g, "")
  // Remove multiple minuses
  result = result.replace(/^-+/, "-")
  // Remove minuses that are not at the beginning
  result = result.replace(/(.+)-/, "$1")
  // Remove trailing zeroes
  result = result.replace(/^(-)?0+/, "$10")
  // Remove trailing zeroes before a number like 03
  result = result.replace(/^(-)?0(\d)/, "$1$2")
  // Edge case -.
  result = result.replace("-.", "-0.")
  // Add 0 prefix if input is .x
  result = result.replace(/^-?\.([\d]+)$/g, "0.$1")
  // Remove all digits after N decimals
  const maxDecimalRegexp = new RegExp(`^-?\\d+\\.?\\d{0,${maxDecimalDigits}}$`)
  if (result?.length > 3 && !maxDecimalRegexp.test(result)) {
    result = parseFloat(result).toFixed(maxDecimalDigits)
  }
  return result
}
