import React from "react"
import { MoneyValue } from "@/types/DecisionTable"

interface MoneyCellProps {
  value: MoneyValue
}

export const MoneyCell = ({ value }: MoneyCellProps) => {
  return value?.currencyCode ? (
    <span>
      {parseFloat(value.amount).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        style: "currency",
        currency: value.currencyCode,
      })}
    </span>
  ) : null
}
