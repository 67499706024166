import classNames from "classnames"
import React, { cloneElement, forwardRef, isValidElement } from "react"
import { twMerge } from "tailwind-merge"
import { useRadioListContext } from "../RadioList/utils"
import { FORM_COMPONENT_FOCUS_CLASSES, FOCUS_STATE_BASE_CLASSES } from "../utils/focusStateClasses"
import {
  AFTER_ELEMENT,
  AFTER_ELEMENT_CHECKED,
  AFTER_ELEMENT_DISABLED_CHECKED,
  AFTER_ELEMENT_ERROR,
  BASE_CHECKED_CLASSES,
  BASE_CLASSES,
  BASE_DISABLED_CHECKED_CLASSES,
  BASE_DISABLED_CLASSES,
  BASE_ERROR_CLASSES,
  BASE_HOVER_CLASSES,
} from "./constants"
import type { RadioProps } from "./types"
import { devWarning } from "@/components/utils/devWarning"

export const Radio = forwardRef<HTMLInputElement, RadioProps>(
  ({ className, label, disabled, error, ...rest }, ref) => {
    const ctx = useRadioListContext()
    const _classNameInput = twMerge(
      classNames(
        BASE_CLASSES,
        FOCUS_STATE_BASE_CLASSES,
        FORM_COMPONENT_FOCUS_CLASSES,
        AFTER_ELEMENT,
        {
          [BASE_HOVER_CLASSES]: !disabled,
          [BASE_CHECKED_CLASSES]: !disabled,
          [AFTER_ELEMENT_CHECKED]: !disabled,
          [BASE_DISABLED_CLASSES]: disabled,
          [BASE_DISABLED_CHECKED_CLASSES]: disabled,
          [AFTER_ELEMENT_DISABLED_CHECKED]: disabled,
          [BASE_ERROR_CLASSES]: error && !disabled,
          [AFTER_ELEMENT_ERROR]: error && !disabled,
        },
      ),
    )

    const _label =
      label && isValidElement(label)
        ? cloneElement(label, {
            htmlFor: rest.id || rest.name,
            className: classNames(label.props.className, "cursor-pointer w-fit"),
            ...label.props,
          })
        : label

    const _classNameContainer = classNames(
      "flex items-start gap-2 text-black font-medium font-work-sans w-fit",
    )

    const props = Object.assign({}, rest, {
      type: "radio",
      className: classNames(_classNameInput, className),
      disabled,
      ref,
      name: ctx.name ? ctx.name : rest.name,
      onChange: ctx.onChange ? ctx.onChange : rest.onChange,
    })

    devWarning(
      Boolean(ctx.name && rest.name),
      "It seems like you're setting a `name` prop on the `Radio` component, but you're also using the `Radio` component inside a `RadioList` component. Please remove the `name` prop from the `Radio` component.",
    )

    devWarning(
      Boolean(ctx.onChange && rest.onChange),
      "It seems like you're setting a `onChange` prop on the `Radio` component, but you're also using the `Radio` component inside a `RadioList` component. Please remove the `onChange` prop from the `Radio` component.",
    )

    return (
      <div className={_classNameContainer}>
        <input {...props} />
        {_label}
      </div>
    )
  },
)
