import classNames from "classnames"
import React, { MouseEvent, useCallback } from "react"
import { twMerge } from "tailwind-merge"
import { FORM_COMPONENT_FOCUS_CLASSES, FOCUS_STATE_BASE_CLASSES } from "../utils/focusStateClasses"
import {
  BASE_CONTROL_CLASSES,
  BASE_ERROR_CLASSES,
  BASE_FOCUS_CLASSES,
  DISABLED_CLASSES,
} from "./constants"
import { useMultiSelectContext } from "./MultiSelectContext"
import { MultiSelectControlProps } from "./types"
import { MultiSelectTag } from "./MultiSelectTag"
const chevronUp = require("./assets/chevron-up.svg")
const times = require("./assets/times.svg")

export const MultiSelectControl = ({
  valueTagsConfig,
  onBlur,
  onFocus,
  placeholder,
}: MultiSelectControlProps) => {
  const {
    disabled,
    error,
    floating,
    id,
    interactions: { getReferenceProps } = { getReferenceProps: undefined },
    onChange,
    open,
    removeValue,
  } = useMultiSelectContext()

  const _classNameIcon = classNames("transition-all", { "rotate-180": !open })
  const _classNameControl = twMerge(
    classNames(BASE_CONTROL_CLASSES, {
      "border-primary-base": open,
      [BASE_FOCUS_CLASSES]: error,
      [BASE_ERROR_CLASSES]: error,
      [FOCUS_STATE_BASE_CLASSES]: !disabled,
      [FORM_COMPONENT_FOCUS_CLASSES]: !disabled,
      [DISABLED_CLASSES]: disabled,
    }),
  )

  const handleClear = (evt: MouseEvent<HTMLButtonElement>) => {
    evt.stopPropagation()
    onChange?.([])
  }

  const handleRemoveTag = useCallback(
    valueToRemove => (evt: MouseEvent<HTMLButtonElement>) => {
      evt.stopPropagation()
      evt.preventDefault()
      onChange?.(removeValue(valueToRemove))
    },
    [onChange, removeValue],
  )

  const tagEls =
    valueTagsConfig.length > 0
      ? valueTagsConfig.map(({ value, label }) => (
          <MultiSelectTag key={value} onRemove={handleRemoveTag(value)}>
            {label}
          </MultiSelectTag>
        ))
      : undefined

  const hasValuesSelected = Number(valueTagsConfig?.length) > 0
  const display = tagEls || <span className="text-gray-300">{placeholder}</span>

  return (
    <div
      className={_classNameControl}
      {...getReferenceProps?.({
        "aria-controls": `select-list-${id}`,
        "aria-disabled": disabled,
        "aria-labelledby": `label-${id}`,
        id,
        onBlur,
        onFocus,
        ref: floating.refs.setReference,
        tabIndex: disabled ? undefined : 0,
      })}
    >
      <div
        id={`select-control-${id}`}
        role="presentation"
        className="flex-column flex max-h-[120px] w-full flex-wrap gap-1.5 overflow-y-auto"
      >
        {display}
      </div>
      <div className="flex items-center gap-2">
        {hasValuesSelected && (
          <button
            className="flex h-6 items-center"
            type="button"
            aria-label="Clear"
            onClick={handleClear}
          >
            <img src={times} alt="Clear" />
          </button>
        )}
        <img
          className={_classNameIcon}
          src={chevronUp}
          alt={`${open ? "Close" : "Open"} dropdown`}
        />
      </div>
    </div>
  )
}
