// We want to pass the ul element keybaord interactions to mimic a select
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import classNames from "classnames"
import { FloatingFocusManager, useId } from "@floating-ui/react"
import React, { KeyboardEventHandler, useLayoutEffect, useState } from "react"
import { useMultiSelectContext } from "./MultiSelectContext"
import { MultiSelectListProps } from "./types"
import { MultiSelectOptionCheckbox } from "./MultiSelectOptionCheckbox"

export const MultiSelectList = ({
  children,
  searchInput,
  selectAllLabel = "Select all",
  showSelectAllToggle,
}: MultiSelectListProps) => {
  const buttonLabelId = useId()
  /**
   * When isPointerActive is true, it avoids the current active list item to scroll into view when hovering over it.
   * Check useLayoutEffect below for more details.
   */
  const [isPointerActive, setPointerActive] = useState(false)
  const {
    activeIndex,
    checkboxPosition,
    floating: {
      refs: { setFloating },
      context,
      y,
      x,
      strategy,
    },
    id,
    interactions: { getFloatingProps },
    isSelectAllChecked,
    listNodeRef,
    onSelectAll,
    open,
    searchInputRef,
    selectAllButtonRef,
    setActiveIndex,
    setDefaultSearchInputValue: setSearch,
  } = useMultiSelectContext()

  const { onKeyDown, role, ...floatingProps } = getFloatingProps({
    ref: setFloating,
    style: {
      display: "grid",
      gridTemplateRows: "auto 1fr",
      position: strategy,
      top: 0,
      left: 0,
      transform: `translate3d(${Math.round(Number(x))}px, ${Math.round(Number(y))}px, 0)`,
    },
    onPointerMove() {
      setPointerActive(true)
    },
    onKeyDown() {
      setPointerActive(false)
    },
  })

  useLayoutEffect(() => {
    if (open && activeIndex != null && !isPointerActive) {
      requestAnimationFrame(() => {
        listNodeRef?.current[activeIndex]?.scrollIntoView?.({
          block: "nearest",
        })
      })
    }
  }, [open, activeIndex, listNodeRef, isPointerActive, setSearch, setActiveIndex])

  if (!open) return null
  if (!open && isPointerActive) {
    setPointerActive(false)
  }

  const selectAllToggleClassName = classNames(
    "flex w-full cursor-pointer items-center gap-2 px-4 py-2 outline-none hover:bg-gray-100 focus:bg-gray-100",
    {
      "justify-start": checkboxPosition === "start",
      "flex-row-reverse justify-between": checkboxPosition === "end",
    },
  )

  return (
    <FloatingFocusManager
      initialFocus={searchInputRef}
      context={context}
      closeOnFocusOut
      modal={false}
    >
      <div
        className="relative z-10 w-full rounded-lg border border-gray-200 bg-white shadow-md focus-visible:outline-none"
        {...floatingProps}
      >
        <div className="border-b border-gray-200">
          {searchInput}
          {showSelectAllToggle && (
            <button
              aria-labelledby={buttonLabelId}
              className={selectAllToggleClassName}
              onFocus={() => setActiveIndex?.(null)}
              onClick={onSelectAll}
              type="button"
              tabIndex={-1}
              ref={selectAllButtonRef}
              onKeyDown={event => {
                if (event.key === "ArrowDown" || event.key === "ArrowUp") {
                  event.preventDefault()
                  searchInputRef.current?.focus()
                }
                if (event.key === "Tab") {
                  event.preventDefault()
                  setActiveIndex?.(0)
                }
              }}
            >
              <MultiSelectOptionCheckbox isChecked={isSelectAllChecked} />
              <span id={buttonLabelId}>{selectAllLabel}</span>
            </button>
          )}
        </div>
        <ul
          aria-multiselectable
          id={`select-list-${id}`}
          role={role as "listbox"} // 'listbox'
          onKeyDown={onKeyDown as KeyboardEventHandler<HTMLUListElement>}
          className="overflow-auto pb-1 pt-0.5"
        >
          {children}
        </ul>
      </div>
    </FloatingFocusManager>
  )
}

MultiSelectList.displayName = "MultiSelectList"
