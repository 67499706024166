import React, { useState } from "react"
import { useIntl } from "react-intl"

import { DecisionTable } from "@/types/DecisionTable"
import { DecisionTableEditor, Button, useToast, Toast } from "@/components"
import { ConfirmationModal } from "./ConfirmationModal"
import { AdministrateActions } from "@/types/commons"
import { amplitudeLogEvent } from "@/utils/amplitudeHelpers"

interface DecisionTableShowProps {
  actions: AdministrateActions
  decisionTable: DecisionTable
}

export const DecisionTableShow: React.FC<DecisionTableShowProps> = ({ actions, decisionTable }) => {
  const { edit, publish } = actions
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [updatedDecisionTable, setUpdatedDecisionTable] = useState<DecisionTable>(decisionTable)
  const { name, urls } = updatedDecisionTable
  const { $t } = useIntl()
  const toast = useToast()

  const EditButton = () => (
    <Button variant="secondary" href={`${urls.app.edit}`}>
      {$t({ id: "administrate.actions.edit" })}
    </Button>
  )

  const handlePublication = (update: DecisionTable) => {
    setUpdatedDecisionTable(update)
    toast({
      render: (
        <Toast variation="success">
          <Toast.Content>
            {$t({ id: "alerts.decision_table.published_successfully" })}
          </Toast.Content>
        </Toast>
      ),
    })
  }

  return (
    <>
      <div className="w-full ml-3">
        <div className="flex justify-between items-center mr-5 mb-6">
          <div className="flex items-center">
            <h2 className="text-xl font-semibold">{name}</h2>
          </div>

          <div className="flex flex-wrap">
            {edit && (
              <div>
                <EditButton />
              </div>
            )}
            {publish && (
              <div className="ml-5">
                <Button
                  variant="primary"
                  onClick={() => {
                    amplitudeLogEvent("[SE]CA-click_publish_decision_table")
                    setShowConfirmationModal(true)
                  }}
                >
                  Publish
                </Button>
              </div>
            )}
          </div>
        </div>

        <section>
          <div
            className="w-full mb-8"
            style={{
              maxWidth: "calc(100vw - 325px)",
            }}
          >
            <DecisionTableEditor decisionTable={updatedDecisionTable} actions={actions} />
          </div>
        </section>
      </div>
      <ConfirmationModal
        setModalOpen={setShowConfirmationModal}
        modalOpen={showConfirmationModal}
        table={updatedDecisionTable}
        handlePublication={handlePublication}
      />
    </>
  )
}
