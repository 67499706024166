import { useId } from "@floating-ui/react"
import classNames from "classnames"
import React, { cloneElement, createContext, isValidElement, useContext } from "react"
import { twMerge } from "tailwind-merge"
import { BASE_DISABLED_CLASSES, BASE_MD_CLASSES, BASE_SM_CLASSES } from "./constants"
import { InputWithSelectSelect } from "./InputWithSelectSelectControl"
import { InputWithSelectTextInput } from "./InputWithSelectTextInput"
import type { InputWithSelectProps } from "./types"
import {
  WRAPPER_SM_CLASSES,
  WRAPPER_CLASSES,
  WRAPPER_MD_CLASSES,
} from "../utils/formComponentWrapperClasses"
import { FOCUS_STATE_BASE_CLASSES } from "../utils/focusStateClasses"

const InputWithSelectContext = createContext<{ disabled?: boolean }>({ disabled: undefined })
export const useInputWithSelectContext = () => useContext(InputWithSelectContext)

export const InputWithSelect = ({
  children,
  disabled,
  error,
  label,
  size = "md",
}: InputWithSelectProps) => {
  const isSM = size === "sm"
  const isMD = size === "md"
  const id = useId()

  const _label = isValidElement(label)
    ? cloneElement(label, { ...label.props, as: "div", id, role: "group" })
    : label

  const _className = twMerge(
    classNames(
      "border-2 border-gray-300 bg-white text-black transition-all flex flex-row items-center gap-2",
      FOCUS_STATE_BASE_CLASSES,
      "focus-within:ring-4",
      {
        [BASE_SM_CLASSES]: isSM,
        [BASE_MD_CLASSES]: isMD,
        [BASE_DISABLED_CLASSES]: disabled,
        ["focus-within:border-primary-base"]: !error,
        ["border-error-base"]: error,
      },
    ),
  )

  const _classNameWrapper = classNames(WRAPPER_CLASSES, {
    [WRAPPER_SM_CLASSES]: isSM,
    [WRAPPER_MD_CLASSES]: isMD,
  })

  return (
    <InputWithSelectContext.Provider value={{ disabled }}>
      <div className={_classNameWrapper} role="group" aria-labelledby={id}>
        {_label}
        <div className={_className}>{children}</div>
        {/* TODO: Move this error message to its own component so it can be re-use in other components */}
        {!!error && <div className="text-sm text-error-base">{error}</div>}
      </div>
    </InputWithSelectContext.Provider>
  )
}

InputWithSelect.Select = InputWithSelectSelect
InputWithSelect.TextInput = InputWithSelectTextInput
