import React, { useEffect, useRef } from "react"
import { useIntl } from "react-intl"

import { Toast, useToast } from "@/components"

const SSOT_CONTENT_JUST_UPDATED_KEY = "SSOT_CONTENT_JUST_UPDATED"
const AUTO_HIDE_DURATION = 5000

export const useShowSSOTContentUpdatedToast = () => {
  const { $t } = useIntl()
  const toast = useToast()
  const hasShownToast = useRef(false)

  useEffect(() => {
    const contentJustUpdated = window.sessionStorage.getItem(SSOT_CONTENT_JUST_UPDATED_KEY)
    if (contentJustUpdated === "true" && !hasShownToast.current) {
      // This ensures the toast is never rendered more than once because it's not reset to false afterwards
      hasShownToast.current = true
      toast({
        render: (
          <Toast variation="success" autohideDuration={AUTO_HIDE_DURATION}>
            <Toast.Content>
              {$t({ id: "alerts.ssot_documents.ssot_content_updated" })}
            </Toast.Content>
          </Toast>
        ),
      })
      setTimeout(() => {
        window.sessionStorage.removeItem(SSOT_CONTENT_JUST_UPDATED_KEY)
      }, AUTO_HIDE_DURATION)
    }
  }, [$t, toast])

  const setContentJustUpdated = () =>
    window.sessionStorage.setItem(SSOT_CONTENT_JUST_UPDATED_KEY, "true")

  return {
    setContentJustUpdated,
  }
}
