import { noop } from "lodash-es"
import { ReactNode, createContext, useContext } from "react"
import { ToastContextValue, CloseToastContextValue, TriggerToastContextValue } from "./types"

export const ToastContext = createContext<ToastContextValue>({ toasts: {}, setToasts: noop })
export const useToastContext = () => {
  const toastContext = useContext(ToastContext)

  if (!toastContext) {
    throw Error("Unable to use toastContext when Toast is not a child of ToastProvider")
  }

  return toastContext
}

export const TriggerToastContext = createContext<TriggerToastContextValue>(noop)
export const useTriggerToast = () => {
  const triggerToast = useContext(TriggerToastContext)

  return (toast: ReactNode, id: string) => {
    if (!triggerToast) {
      throw Error("Unable to call triggerToast when Toast is not a child of ToastProvider")
    }

    triggerToast(toast, id)
  }
}

export const CloseToastContext = createContext<CloseToastContextValue>(noop)
export const useCloseToast = () => {
  const closeToastContext = useContext(CloseToastContext)

  if (!closeToastContext) {
    throw Error("Unable to use closeToastContext when Toast is not a child of ToastProvider")
  }

  return closeToastContext
}
