import React, { useState } from "react"
import { UpdateSection } from "./types"
import { Button, Checkbox, FormLabel, Modal } from "@/components"

interface ConfirmUpdateModalProps {
  modalOpen: boolean
  setModalOpen: (modalOpen: boolean) => void
  updateSection: UpdateSection
  updatingSection: boolean
  redirectionUrl: string
  setContentJustUpdated: () => void
}

export const ConfirmUpdateModal = ({
  modalOpen,
  setModalOpen,
  updateSection,
  updatingSection,
  redirectionUrl,
  setContentJustUpdated,
}: ConfirmUpdateModalProps) => {
  const [confirmSave, setConfirmSave] = useState(false)

  return (
    <Modal title="Confirm changes" isOpen={modalOpen} setIsOpen={setModalOpen}>
      <div className="max-w-lg">
        <p className="mb-5">
          Before saving, please ensure that you have reviewed the corresponding country rules to
          prevent any unintended modifications. Are you sure you want to save your changes?
        </p>
        <Checkbox
          label={
            <FormLabel label="I confirm that I have reviewed the decision table and the SSOT content and both are consistent." />
          }
          checked={confirmSave}
          onChange={() => setConfirmSave(!confirmSave)}
        />
        <div className="flex justify-end mt-8">
          <div>
            <Button
              variant="primary"
              onClick={() =>
                updateSection(() => {
                  setContentJustUpdated()
                  window.location.href = redirectionUrl
                })
              }
              loading={updatingSection}
              disabled={!confirmSave}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
