import { Button, Modal } from "@/components"
import React from "react"

const alertIcon = require("./assets/alert.svg")

interface OutdatedContentModalProps {
  modalOpen: boolean
  onClose: () => void
  comment: string
}

export const OUTDATE_CONTENT_MODAL_TYPE = {
  DELETE: "delete",
  EDIT: "edit",
}

const editPageCopy =
  "It seems this content has just been updated by another user. Refresh the page to view the latest changes. Please copy your content before refreshing to avoid losing any work."
const showPageCopy =
  "It seems this content has just been updated by another user. Refresh the page to view the latest changes. If you'll like to keep your comment, you can copy it to your clipboard before refreshing. Click the 'Copy and reload' button to proceed."

export const OutdatedContentModal: React.FC<OutdatedContentModalProps> = ({
  modalOpen,
  onClose,
  comment,
}) => {
  const handleCopyAndReload = async () => {
    if (comment === OUTDATE_CONTENT_MODAL_TYPE.DELETE) {
      return location.reload()
    }

    await navigator.permissions
      .query({
        // eslint-disable-next-line no-undef
        name: "clipboard-read" as PermissionName,
      })
      .then(async result => {
        if (result.state === "granted") {
          await navigator.clipboard.writeText(comment)
        } else if (result.state === "prompt") {
          result.onchange = async () => {
            if (result.state === "granted") {
              await navigator.clipboard.writeText(comment)
            }
          }
          await navigator.clipboard.writeText(comment)
        }
      })
      .catch(() => location.reload())
      .finally(() => location.reload())
  }

  return (
    <Modal isOpen={modalOpen} setIsOpen={onClose}>
      <div className="flex flex-col items-center mb-6">
        <img src={alertIcon} alt="Warning" className="block mb-3" />
        <h3 className="font-medium text-xl">Oops! Content version outdated</h3>
      </div>
      <p className="mb-6">
        {comment === OUTDATE_CONTENT_MODAL_TYPE.EDIT ? editPageCopy : showPageCopy}
      </p>
      {comment !== OUTDATE_CONTENT_MODAL_TYPE.EDIT && (
        <div className="flex items-center justify-end">
          <div className="w-fit mr-3">
            <Button variant="secondary" className="mr-4" onClick={onClose}>
              Cancel
            </Button>
          </div>
          <div className="w-fit">
            <Button variant="primary" onClick={handleCopyAndReload}>
              {comment === OUTDATE_CONTENT_MODAL_TYPE.DELETE ? "Reload" : "Copy and reload"}
            </Button>
          </div>
        </div>
      )}
    </Modal>
  )
}
