import React, { useEffect, useMemo, useState } from "react"
import { Button, FormLabel, Modal, Select } from "@/components"
import { useRuleOwnershipForm } from "./useRuleOwnershipForm"
import { Controller } from "react-hook-form"
import {
  DecisionTableContentOwnership,
  DecisionColumnEnum,
  DecisionColumn,
} from "@/types/DecisionTable"
import { NO_OWNER, Owner, OwnerSelect, useSSOTDocumentContext } from "../../components"
import { UpdateDecisionRuleOwnerParams } from "../../hooks/useSections"
import { SSOTSection } from "@/types/SSOTDocument"

export interface EditRuleOwnershipModalProps {
  isOpen: boolean
  closeModal: () => void
  eligibleOwners: Owner[]
  updateDecisionRuleOwner: (params: UpdateDecisionRuleOwnerParams) => void
  activeSection: SSOTSection
}

export const EditRuleOwnershipModal = ({
  isOpen,
  closeModal,
  eligibleOwners,
  updateDecisionRuleOwner,
  activeSection,
}: EditRuleOwnershipModalProps) => {
  const {
    state: {
      ssotDocument: {
        country: { code: countryCode },
      },
    },
  } = useSSOTDocumentContext()

  const [owner, setOwner] = useState<Owner>(NO_OWNER)
  const [previousOwnership, setPreviousOwnership] = useState<DecisionTableContentOwnership>()

  const decisionTable = useMemo(() => {
    if (activeSection.decisionTables.length > 0) return activeSection.decisionTables[0]

    return null
  }, [activeSection])

  const oysterScores: DecisionColumn | undefined = decisionTable.schema.columns.find(
    ({ name }) => name === "oysterScore",
  )

  const {
    handleSubmit,
    onSubmit,
    control,
    formState: { errors, isSubmitting },
    oysterScoreValue,
    setValue,
    setError,
  } = useRuleOwnershipForm({
    onSubmitSuccessful: closeModal,
    previousOwnership,
    countryCode,
    section: activeSection,
    updateDecisionRuleOwner,
    oysterScores,
  })

  useEffect(() => {
    if (oysterScoreValue) {
      const matchingOwnership = decisionTable.contentOwnerships?.find(
        element => element.countryCode === countryCode && element.oysterScore === oysterScoreValue,
      )

      setValue("userId", matchingOwnership?.user.id ?? NO_OWNER.id)
      setOwner(matchingOwnership?.user ?? NO_OWNER)
      setError("userId", undefined)
      return setPreviousOwnership(matchingOwnership ?? undefined)
    }
    return setOwner(NO_OWNER)
  }, [
    oysterScoreValue,
    countryCode,
    setValue,
    activeSection.decisionTables,
    setError,
    decisionTable.contentOwnerships,
  ])

  const availableOysterScores = oysterScores
    ? activeSection.decisionTables?.[0].decisionRows?.reduce((acc, decisionRow) => {
        if (!acc.find(({ value }) => value === decisionRow.rule.oysterScore)) {
          return [
            ...acc,
            (oysterScores.type as DecisionColumnEnum).values.find(
              ({ value }) => value === decisionRow.rule.oysterScore,
            ),
          ]
        }
        return acc
      }, [])
    : undefined

  return (
    <Modal isOpen={isOpen} setIsOpen={closeModal} title="Assign ownership">
      <form onSubmit={handleSubmit(onSubmit)} className="min-w-96">
        <fieldset disabled={isSubmitting}>
          {oysterScores && (
            <div className="mb-4">
              <Controller
                control={control}
                name="oysterScore"
                render={({ field }) => (
                  <Select
                    {...field}
                    placeholder="eg. Statutory"
                    label={<FormLabel label="Oyster score" />}
                    error={errors.oysterScore?.message}
                  >
                    {availableOysterScores.map(({ value, title }) => (
                      <Select.Option value={value} key={value}>
                        {title}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              />
            </div>
          )}

          <div className="mb-8">
            <Controller
              control={control}
              name="userId"
              render={({ field: { value, onChange, ...field } }) => (
                <OwnerSelect
                  {...field}
                  owner={owner}
                  eligibleOwners={eligibleOwners}
                  onChange={(id: string) => {
                    const parsedId = parseInt(id)
                    setOwner(eligibleOwners.find(eligibleOwner => eligibleOwner.id === parsedId))
                    onChange(parsedId)
                  }}
                  error={errors.userId?.message}
                  label="Owner"
                  previousOwner={previousOwnership?.user}
                />
              )}
            />
          </div>

          <div className="flex items-center justify-end">
            <div>
              <Button variant="secondary" onClick={closeModal} type="button">
                Cancel
              </Button>
            </div>

            <div className="ml-3">
              <Button variant="primary">Assign</Button>
            </div>
          </div>
        </fieldset>
      </form>
    </Modal>
  )
}
