import React, { PropsWithChildren, useState } from "react"
import { LegalAndPeopleServicesReviewStatus, SSOTSection } from "@/types/SSOTDocument"
import { Status } from "../Status"
import { useSSOTDocumentAPI } from "@/api/useSSOTDocumentAPI"
import { useSSOTDocumentContext } from "../SSOTDocumentContext"
import { useIntl } from "react-intl"
import { FormLabel, MultilevelAccordion, Toast, useToast } from "@/components"
const backArrow = require("./assets/back-arrow.svg")

export interface SSOTLayoutProps {
  documentStatusEditable?: boolean
  onBackClicked: () => void
  onActiveSectionChange: (section: SSOTSection) => void
}

export const SSOTLayout = ({
  children,
  documentStatusEditable,
  onBackClicked,
  onActiveSectionChange,
}: PropsWithChildren<SSOTLayoutProps>) => {
  const { state, dispatch } = useSSOTDocumentContext()
  const {
    country,
    subdivision,
    lpsReviewStatus,
    id: documentId,
    createdAt,
    createdBy,
  } = state.ssotDocument

  const { updateSSOTDocument } = useSSOTDocumentAPI()
  const { $t } = useIntl()
  const toast = useToast()

  const [loading, setLoading] = useState(false)

  const handleChange = (status: LegalAndPeopleServicesReviewStatus) => {
    setLoading(true)

    updateSSOTDocument({
      lpsReviewStatus: status,
      id: documentId,
      done: () => {
        dispatch({
          ssotDocument: {
            ...state.ssotDocument,
            lpsReviewStatus: status,
          },
        })
        toast({
          render: (
            <Toast variation="success">
              <Toast.Content>
                {$t({ id: "alerts.ssot_documents.document_status_updated" })}
              </Toast.Content>
            </Toast>
          ),
        })
        setLoading(false)
      },
      fail: () => setLoading(false),
    })
  }

  return (
    <div className="mt-8 mb-16">
      <div className="mb-5">
        <button className="flex items-center text-primary-base" onClick={onBackClicked}>
          <img src={backArrow} alt="Back" className="block mr-2" />
          <span className="underline">Back</span>
        </button>
      </div>

      <div className="mb-5">
        <h1 className="font-semibold text-3xl flex flex-col mb-4">
          {country.emojiFlag} {country.name}
          {subdivision && ` - ${subdivision.name}`}
        </h1>

        <div className="flex items-center">
          <div className="flex items-center">
            <FormLabel
              htmlFor="document-status"
              id="label-document-status"
              className="mr-3"
              label="SSOT status"
            />
            <Status
              loading={loading}
              editable={documentStatusEditable}
              value={lpsReviewStatus}
              onChange={handleChange}
              id="document-status"
            />
          </div>

          <div className="flex items-center flex-wrap ml-8">
            <div className="flex items-center mr-5">
              <h3 className="text-gray-400 mr-2">Created at</h3>
              <p className="text-black mr-2">{new Date(createdAt).toLocaleString() ?? "Not yet"}</p>
            </div>

            <div className="flex items-center">
              <h3 className="text-gray-400 mr-2">Created by</h3>
              <p className="text-black mr-2">{createdBy?.name ?? "Not yet specified"}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex">
        <div className="min-w-72 w-72 max-w-72 mr-14">
          <div className="flex flex-col items-start">
            <p className="font-semibold mb-5">Sections</p>
            <MultilevelAccordion onActiveSectionChange={onActiveSectionChange} />
          </div>
        </div>

        {children}
      </div>
    </div>
  )
}
