import React from "react"
import type { MultiSelectTagProps } from "./types"
const times = require("./assets/times.svg")

export const MultiSelectTag = ({ children, onRemove }: MultiSelectTagProps) => {
  return (
    <div className="flex min-h-6 items-center gap-1.5 overflow-hidden rounded bg-gray-200 pl-2 text-xs">
      {children}
      <button
        aria-label={`Remove ${children}`}
        type="button"
        className="flex h-full items-center px-1 transition-all hover:bg-gray-300"
        onClick={onRemove}
      >
        <img src={times} alt="remove" className="w-2" />
      </button>
    </div>
  )
}
