import classnames from "classnames"
import React, { PropsWithChildren } from "react"

export type BadgeVariant = "success" | "error" | "warning" | "info" | "grey"

interface BadgeProps {
  variant: BadgeVariant
  className?: string
}

export const Badge = ({ children, variant, className }: PropsWithChildren<BadgeProps>) => {
  const classNameByVariant: Record<BadgeVariant, string> = {
    success: "bg-primary-lighten-100 text-primary-darken-100",
    error: "bg-error-lighten-100 text-error-darken-100",
    warning: "bg-warning-lighten-100 text-warning-darken-100",
    info: "bg-info-lighten-100 text-info-darken-100",
    grey: "bg-gray-100 text-gray-400",
  }
  return (
    <div
      className={classnames(
        "text-xs px-2 py-1 rounded-full",
        className,
        classNameByVariant[variant],
      )}
    >
      {children}
    </div>
  )
}
