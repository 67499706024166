import {
  useId,
  useFloating,
  autoUpdate,
  offset,
  shift,
  useInteractions,
  useDismiss,
  useListNavigation,
  useClick,
  useRole,
  flip,
  size,
} from "@floating-ui/react"
import React, { KeyboardEventHandler, useCallback, useRef, useState } from "react"
import { MultiSelectContext } from "./MultiSelectContext"
import { MultiSelectContextProviderProps, MultiSelectContextValue } from "./types"

export const MultiSelectContextProvider = ({
  children,
  value,
  disabled,
  id: idProp,
  onChange,
  error,
  placeholder,
  checkboxPosition,
}: MultiSelectContextProviderProps) => {
  const id = useId()
  const listNodeRef = useRef<HTMLElement[]>([])
  const [open, setOpen] = useState(false)
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const [search, setSearch] = useState("")
  const isSelectAllChecked = value ? listNodeRef.current.length === value.length : false

  const selectAllButtonRef = useRef<HTMLButtonElement>(null)
  const searchInputRef = useRef<HTMLInputElement>(null)

  const floating = useFloating<HTMLElement>({
    whileElementsMounted: autoUpdate,
    placement: "bottom-start",
    strategy: "fixed",
    middleware: [
      offset(8),
      flip(),
      shift(),
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            maxHeight: "320px",
          })
        },
        padding: 16,
      }),
    ],
    open,
    onOpenChange: setOpen,
  })

  const interactions = useInteractions([
    useDismiss(floating.context, {
      enabled: !disabled,
    }),
    useListNavigation(floating.context, {
      enabled: !disabled,
      listRef: listNodeRef,
      activeIndex,
      loop: true,
      focusItemOnOpen: false,
      virtual: false,
      openOnArrowKeyDown: true,
      orientation: "vertical",
      selectedIndex: null,
      onNavigate: index => {
        // Invoking `setActiveIndex()` will trigger focus on the corresponding option
        // because we have an input inside the floating list, we want to make sure that
        // focus is not hijacked unexpectedly. Hence we manually set the initial active index
        // according to the focus/blur of MultiSelect.Input.
        typeof activeIndex === "number" ? setActiveIndex(index) : undefined
      },
    }),
    useClick(floating.context, {
      enabled: !disabled,
      toggle: true,
    }),
    useRole(floating.context, {
      role: "listbox",
    }),
  ])

  const getSearchInputProps = () => {
    return {
      tabIndex: 0 as const,
      ref: searchInputRef,
      onFocus: () => setActiveIndex?.(null),
      onKeyDown: (event => {
        if (event.key === "ArrowDown" || event.key === "ArrowUp") {
          event.preventDefault()
          selectAllButtonRef.current?.focus()
        }
        if (event.key === "Tab") {
          event.preventDefault()
          setActiveIndex?.(0)
        }
      }) as KeyboardEventHandler<HTMLInputElement>,
    }
  }

  const removeValue = useCallback(
    (valueToRemove: string) => {
      const cloneValueProp = [...(value || [])]
      cloneValueProp.splice(cloneValueProp.indexOf(valueToRemove), 1)
      return cloneValueProp
    },
    [value],
  )

  const onSelectAll = () => {
    if (isSelectAllChecked) {
      onChange?.([])
    } else {
      const allValues = listNodeRef?.current.map(node => node.dataset.value as string)
      if (allValues) {
        onChange?.(allValues)
      }
    }
  }

  const onSelectValue = useCallback(
    (selectedValue: string) => {
      const nextValues = value?.includes(selectedValue)
        ? removeValue?.(selectedValue)
        : [...(value || []), selectedValue]
      onChange?.(nextValues)
    },
    [onChange, value, removeValue],
  )

  const contextValue: MultiSelectContextValue = {
    activeIndex,
    checkboxPosition,
    disabled,
    error,
    floating,
    getSearchInputProps,
    id: idProp || id,
    interactions,
    isSelectAllChecked,
    listNodeRef,
    onChange,
    onSelectAll,
    onSelectValue,
    open,
    placeholder,
    removeValue,
    defaultSearchInputValue: search,
    searchInputRef,
    selectAllButtonRef,
    setActiveIndex,
    setOpen,
    setDefaultSearchInputValue: setSearch,
    value,
  }

  return <MultiSelectContext.Provider value={contextValue}>{children}</MultiSelectContext.Provider>
}
