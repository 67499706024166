import React, { cloneElement, CSSProperties, HTMLProps, ReactElement, ReactNode, useRef } from "react"
import {
  offset,
  shift,
  flip,
  autoUpdate,
  arrow,
  useInteractions,
  useFloating,
  useHover,
  useRole,
  useFocus,
  useClick,
  useDismiss,
  FloatingPortal,
} from "@floating-ui/react"
import { memoize } from "lodash-es"
import DOMPurify from "dompurify"
import { useControlled } from "../utils/useControlled"
import { TooltipInnerProps, TooltipProps } from "./types"
import { devWarning } from "@/components/utils/devWarning"

const sanitizeValue = memoize((value: string) => {
  return DOMPurify.sanitize(value, { USE_PROFILES: { html: true }, ADD_ATTR: ["target"] })
})

export const TooltipInner = ({
  children,
  defaultIsOpen,
  isOpen: isOpenProp,
  placement = "top",
  unsafe_isValueInnerHTML = false,
  value,
  ...rest
}: TooltipInnerProps) => {
  const [isOpen, setOpen] = useControlled({
    controlled: isOpenProp,
    default: defaultIsOpen,
    name: "Tooltip isOpen",
  })
  const arrowEl = useRef<HTMLDivElement>(null)
  const {
    context,
    refs: { setFloating, setReference },
    strategy,
    y,
    x,
    middlewareData,
    placement: finalPlacement,
  } = useFloating({
    placement,
    onOpenChange: setOpen,
    open: isOpen,
    // This is used so that the tooltip can be positioned correctly when there
    // is no space in the selected placement.
    middleware: [offset(5), flip(), shift({ padding: 8 }), arrow({ element: arrowEl })],
    whileElementsMounted: autoUpdate,
  })

  const { getFloatingProps, getReferenceProps } = useInteractions([
    // The hover interaction should only be used by mouse
    useHover(context, { mouseOnly: true }),
    // The focus interaction should only be used through the keyboard
    useFocus(context, { visibleOnly: true }),
    // The click interaction should be able to toggle the tooltip status
    useClick(context, { toggle: true }),
    // Handles dismissal with esc and click outside of reference
    useDismiss(context),
    useRole(context, { role: "tooltip" }),
  ])

  const getArrowPosition = (): CSSProperties => {
    const oppositeDirectionMap = {
      top: "bottom",
      right: "left",
      bottom: "top",
      left: "right",
    } as const

    const finalPlacementFirstPart = finalPlacement.split("-")[0] as
      | "top"
      | "right"
      | "bottom"
      | "left"

    const staticSide = oppositeDirectionMap[finalPlacementFirstPart]
    return {
      left: `${middlewareData.arrow?.x}px`,
      top: `${middlewareData.arrow?.y}px`,
      [staticSide]: "-4px",
    }
  }

  let _value = value

  const isPotentialHtmlString =
    typeof value === "string" && !unsafe_isValueInnerHTML && value.match("</?[a-z]*>")
  devWarning(
    !!isPotentialHtmlString,
    "It looks like you are trying to use html inside your tooltip. Use <Tooltip unsafe_isValueInnerHTML/> prop to render content as HTML. Only do this if you are certain that content is controlled by Oyster",
  )

  if (typeof value === "string" && unsafe_isValueInnerHTML) {
    /* sanitized */
    /* eslint-disable-next-line react/no-danger */
    _value = <div dangerouslySetInnerHTML={{ __html: sanitizeValue(value) }} />
  }

  return (
    <>
      {cloneElement(
        children,
        getReferenceProps({
          ref: setReference,
          ...children.props,
          tabIndex: 0,
        }),
      )}

      {isOpen && (
        <FloatingPortal>
          <div
            role="tooltip"
            className="max-w-[260px] whitespace-normal rounded-md bg-black p-2 text-xs font-normal leading-normal text-white"
            {...getFloatingProps({
              ...(rest as HTMLProps<HTMLElement>), // cast this so that it aligns with Floating UI's typing
              ref: setFloating,
              style: { position: strategy, top: y ?? 0, left: x ?? 0 },
            })}
          >
            {_value}
            <div
              className="absolute h-2 w-2 rotate-45 bg-black"
              style={getArrowPosition()}
              ref={arrowEl}
            />
          </div>
        </FloatingPortal>
      )}
    </>
  )
}

export const Tooltip = ({ value, children, ...rest }: TooltipProps) => {
  if (!value) {
    return children
  }

  return (
    <TooltipInner value={value as ReactElement} {...rest}>
      {children}
    </TooltipInner>
  )
}
