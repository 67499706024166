export const BASE_CLASSES =
  "border-2 border-gray-300 bg-white text-black transition-all flex flex-row items-center gap-2"

export const BASE_FOCUS_CLASSES = "focus-within:border-primary-base"
export const BASE_DISABLED_CLASSES = "bg-gray-100 text-gray-400"
export const BASE_MD_CLASSES = "h-11 rounded-lg px-4"
export const BASE_SM_CLASSES = "h-8 rounded-md px-3 text-sm"
export const BASE_ERROR_CLASSES = "border-error-base"

export const INPUT_CLASSES =
  "w-full !bg-transparent outline-none placeholder:text-gray-300 !border-0"
