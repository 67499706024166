export enum TimeUnits {
  Days = "days",
  Weeks = "weeks",
  Months = "months",
  Years = "years",
}

export type ValidationErrors = Record<string, string> | undefined

export interface Duration {
  begin?: string
  end?: string
  excludeEnd: boolean
}
