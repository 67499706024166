import React from "react"
import { useId } from "@floating-ui/react"
import { InputWithSelectTextInputProps } from "./types"
import { useInputWithSelectContext } from "./InputWithSelect"
import { TextInput } from "../TextInput"

export function InputWithSelectTextInput({ label, ...rest }: InputWithSelectTextInputProps) {
  const id = useId()
  const { disabled } = useInputWithSelectContext()
  return (
    // This wrapper is required since the className props of TextInput are messy due to legacy
    <div className="w-full first:pl-4 last:pr-4">
      <TextInput
        aria-labelledby={id}
        disabled={disabled}
        label={
          <label id={id} className="sr-only">
            {label}
          </label>
        }
        // classNames override TextInput focus and border classNames
        wrapperClassName="border-0 bg-inherit ring-0 focus-within:ring-0 px-0"
        {...rest}
      />
    </div>
  )
}
