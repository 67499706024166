import * as Yup from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { NO_OWNER } from "../../components"
import { FetchMethod, doFetch } from "@/api/doFetch"
import { DecisionColumn, DecisionTableContentOwnership } from "@/types/DecisionTable"
import { UpdateDecisionRuleOwnerParams } from "../../hooks/useSections"
import { ContentOwnership, SSOTSection } from "@/types/SSOTDocument"

type RuleOwnershipFormValues = {
  oysterScore?: string
  userId?: number
}

interface UseRuleOwnershipFormProps {
  section: SSOTSection
  onSubmitSuccessful: () => void
  previousOwnership?: DecisionTableContentOwnership
  countryCode: string
  updateDecisionRuleOwner: (params: UpdateDecisionRuleOwnerParams) => void
  oysterScores?: DecisionColumn
}

const NO_OWNER_ERROR = "An owner is required"

export const useRuleOwnershipForm = ({
  onSubmitSuccessful,
  previousOwnership,
  countryCode,
  section,
  updateDecisionRuleOwner,
  oysterScores,
}: UseRuleOwnershipFormProps) => {
  const initialValues: RuleOwnershipFormValues = {
    oysterScore: "",
    userId: -1,
  }

  const validationSchema = Yup.object({
    oysterScore: oysterScores
      ? Yup.string().trim().required("Oyster score is required")
      : undefined,
    userId: previousOwnership
      ? Yup.number().min(NO_OWNER.id).required(NO_OWNER_ERROR)
      : Yup.number().min(0, NO_OWNER_ERROR).required(NO_OWNER_ERROR),
  })

  const form = useForm<RuleOwnershipFormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  })

  const { watch, setValue } = form
  const oysterScoreValue = watch("oysterScore")

  const changeOwnership = async ({ userId, oysterScore }: RuleOwnershipFormValues) => {
    // Necessary to check previousOwnership is not undefined, not just the user id
    if (previousOwnership && previousOwnership.user.id !== NO_OWNER.id) {
      if (userId === NO_OWNER.id) {
        await doFetch({
          endpoint: previousOwnership.urls.api.delete,
          method: FetchMethod.DELETE,
          done: (res: ContentOwnership) => {
            updateDecisionRuleOwner({
              id: section.id,
              newOwnership: { ...res, user: NO_OWNER },
            })
          },
        })
        return
      }

      await doFetch({
        endpoint: previousOwnership?.urls.api.update,
        method: FetchMethod.PATCH,
        body: {
          contentOwnership: {
            userId,
            countryCode,
            oysterScore,
          },
          SSOTSectionId: section.id,
        },
        done: (res: ContentOwnership) => {
          updateDecisionRuleOwner({
            id: section.id,
            newOwnership: res,
          })
        },
      })
      return
    }

    await doFetch({
      endpoint: section.decisionTables[0].urls.api.addOwner,
      method: FetchMethod.POST,
      body: {
        contentOwnership: {
          userId,
          countryCode,
          oysterScore,
        },
        DecisionTableId: section.decisionTables[0].id,
      },
      done: (res: ContentOwnership) => {
        updateDecisionRuleOwner({
          id: section.id,
          newOwnership: res,
        })
      },
    })
    return
  }

  const onSubmit = async (values: RuleOwnershipFormValues) => {
    try {
      await changeOwnership(values)
    } catch (error) {
      console.error(error)
    } finally {
      onSubmitSuccessful()
    }
  }

  return {
    onSubmit,
    ...form,
    oysterScoreValue,
    setValue,
  }
}
