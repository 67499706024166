import { createContext, useContext } from "react"
import { RailsContext } from "react-on-rails/node_package/lib/types"
import { DocumentStatusDefinition } from "@/hooks/useDocumentStatuses"

// Matches :rendering_extension in config/initializers/react_on_rails.rb
interface CustomContextProps {
  documentStatuses: Array<DocumentStatusDefinition>
  useEditorAPIKey: string
  featureFlags: Record<string, any>
}

type CustomRailsContext = RailsContext & CustomContextProps

export const RailsContextContext = createContext<CustomRailsContext>(null)

export const useRailsContext = () => {
  const context = useContext(RailsContextContext)

  if (!context) throw new Error("RailsContext must be called within RailsContextProvider")

  return context
}
