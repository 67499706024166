import React from "react"
import { useId } from "@floating-ui/react"
import classNames from "classnames"
import { SelectContext } from "../Select/SelectContext"
import { SelectList } from "../Select/SelectList"
import { useSelect } from "../Select/useSelect"
import { useSelectControl } from "../Select/useSelectControl"
import { useInputWithSelectContext } from "./InputWithSelect"
import { InputWithSelectSelectProps } from "./types"

export const InputWithSelectSelect = ({
  children,
  value: valueProp,
  label,
  name: nameProp,
  onFocus,
  onChange,
  onBlur,
  placeholder,
  selectedValueDisplay,
}: InputWithSelectSelectProps) => {
  const id = useId()
  const { disabled } = useInputWithSelectContext()
  const select = useSelect({
    onChange,
    value: valueProp,
    disabled,
    id,
    name: nameProp,
    listStyles: {
      width: "fit-content",
      minWidth: "240px",
    },
  })
  const selectControl = useSelectControl({
    select,
    placeholder,
    UNSAFE_selectedValueDisplay: selectedValueDisplay,
    optionNodes: children,
  })
  const _classNameIcon = classNames("transition-all", { "rotate-180": select.open })

  return (
    <SelectContext.Provider
      value={{
        ...select,
        disabled,
        onBlur,
        onChange,
        onFocus,
      }}
    >
      <label className="sr-only" id={`label-${id}`}>
        {label}
      </label>
      <div
        className={
          "flex items-center justify-between gap-2 px-4 text-left outline-none transition-all focus:border-primary-base"
        }
        {...selectControl.getSelectControlProps()}
      >
        <div id={`select-control-${id}`} role="presentation" className="w-full text-sm">
          {selectControl.getSelectDisplay()}
        </div>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={_classNameIcon}
        >
          <path
            d="M19.5 9L12 16.5L4.5 9"
            stroke="#171717"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <SelectList>{children /* Select.Option[] */}</SelectList>
    </SelectContext.Provider>
  )
}
