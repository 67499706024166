import { uniqueId } from "lodash-es"
import { cloneElement, isValidElement, ReactElement, ReactNode } from "react"
import { useToastContext, useTriggerToast, useCloseToast } from "./ToastContexts"

export type ToastFunctionParams = {
  id?: string
  render: ReactNode
}

type ToastFunction = (param: ToastFunctionParams) => string

type UseToastReturn = ToastFunction & {
  /**
   * Array of ID of all the visible toasts.
   */
  visibleToasts: string[]
  /**
   * Tells whether or not a specific toast is visible.
   */
  isVisible: (toastId: string) => boolean
  /**
   * Closes a specific toast.
   */
  close: (toastId: string) => void
  /**
   * Closes all toasts.
   */
  closeAll: () => void
}

export const useToast = (): UseToastReturn => {
  const { toasts, setToasts } = useToastContext()
  const closeToast = useCloseToast()
  const triggerToast = useTriggerToast()

  const getToastWithId = (toast: ReactNode, id: string): ReactNode =>
    isValidElement(toast)
      ? cloneElement(toast, { ...(toast as ReactElement).props, id, key: uniqueId() })
      : null

  function toastFn({ id: idParam, render }: ToastFunctionParams) {
    const uid = uniqueId()
    const id = idParam || uid

    triggerToast(getToastWithId(render, id), id)
    return id
  }

  function isToastVisible(toastId: string) {
    const toastEntries = Object.entries(toasts)

    if (toastEntries.length === 0) {
      return false
    }

    return !!toasts[toastId]
  }

  function dismissToast(toastId: string) {
    if (isToastVisible(toastId)) {
      return closeToast(toastId)
    }
  }

  function dismissAllToasts() {
    setToasts({})
  }

  toastFn.visibleToasts = Object.keys(toasts)
  toastFn.isVisible = isToastVisible
  toastFn.close = dismissToast
  toastFn.closeAll = dismissAllToasts

  return toastFn
}
