import { LPSReviewStatus } from "@/types/SSOTDocument"
import { FetchMethod, doFetch } from "./doFetch"

type Done = (data: Record<string, any>) => void

interface PublishParams {
  id: number
  done: Done
  fail: (e: Error) => void
}

interface UpdateSSOTSectionParams {
  id: number
  lockVersion: number
  content: string
  lpsReviewStatus?: LPSReviewStatus
  onResponse: (response: Response) => void
}

export const useSSOTSectionAPI = () => {
  const publishSSOTSection = ({ id, done, fail }: PublishParams) => {
    doFetch({
      endpoint: `ssot_sections/${id}/publish`,
      method: FetchMethod.POST,
      body: {},
      done,
      fail,
    })
  }

  const updateSSOTSection = ({
    id,
    content,
    lockVersion,
    lpsReviewStatus,
    onResponse,
  }: UpdateSSOTSectionParams) => {
    doFetch({
      endpoint: `ssot_sections/${id}`,
      method: FetchMethod.PATCH,
      body: { ssotSection: { content, lockVersion, lpsReviewStatus } },
      errorMessage: "Failed to update document section!",
      onResponse,
    })
  }

  const commentSSOTSection = ({
    id,
    content,
    lockVersion,
    onResponse,
  }: UpdateSSOTSectionParams) => {
    doFetch({
      endpoint: `ssot_sections/${id}/comment`,
      method: FetchMethod.PATCH,
      body: { ssotSection: { content, lockVersion } },
      errorMessage: "Failed to comment document section!",
      onResponse,
    })
  }

  return {
    publishSSOTSection,
    updateSSOTSection,
    commentSSOTSection,
  }
}
