import React from "react"
import { parseDurationToText } from "../../../utils"

interface DurationRangeCellProps {
  duration: {
    begin?: string
    end?: string
  }
}

export const DurationRangeCell = ({ duration }: DurationRangeCellProps) => {
  return (
    <span>{`${parseDurationToText(duration.begin) ?? "Up"} to ${
      parseDurationToText(duration.end) ?? "infinite"
    }`}</span>
  )
}
