import { SSOTSection } from "@/types/SSOTDocument"

function flattenChildren(section: SSOTSection): SSOTSection[] {
  let result: SSOTSection[] = []

  if (!section.children.length) {
    result.push(section)
  }

  for (const child of section.children) {
    result = result.concat(flattenChildren(child))
  }

  return result
}

export const parseSections = (sections: SSOTSection[], depth: number, previousCounter?: number) => {
  let counter = previousCounter || 0
  let parsedSections: SSOTSection[] = []

  for (const section of sections) {
    if (counter === depth) {
      parsedSections = [...parsedSections, flattenChildren(section)].flatMap(element => element)
    } else if (!section.children.length) {
      parsedSections.push(section)
    } else {
      parsedSections.push({
        ...section,
        children: parseSections(section.children, depth, counter + 1),
      })
    }
  }

  return parsedSections
}
