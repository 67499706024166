import React, { ReactElement, ReactNode } from "react"
import { SelectedOption } from "./SelectedOption"
import { SelectOptionProps } from "./types"

export function findOptionElement(container: HTMLElement, value: string) {
  return container?.querySelector(`[data-value="${value}"]`)
}

export function getOptionIndex(container: HTMLElement, value: string) {
  const element = findOptionElement(container, value)
  const index = container
    ? Array.from(container?.querySelectorAll("[role='option']")).indexOf(element as Element)
    : undefined

  return index
}

export function isList(container: HTMLElement) {
  return container.tagName === "UL"
}

/**
 * The children of the option can be different from the value
 * This function ensures that the children, start and end adornments on the selected option stay intact when they are used as the display in the SelectControl
 */
export function createDisplayValueFromOptionElement(
  optionElement: ReactElement<SelectOptionProps>,
) {
  return (
    <SelectedOption
      {...{
        ...optionElement.props,
        children: optionElement.props.children as ReactNode,
      }}
    />
  )
}
