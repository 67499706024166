import React, { useState } from "react"
import type { Currency, CurrencyGroup, MoneyInputProps } from "./types"
import { Select, SelectProps } from "../Select"
import { InputWithSelect } from "../InputWithSelect"
import { convertToMaxDecimals } from "../utils/convertToMaxDecimals"

function CurrencyOption({ currencyName, currencyCode }: Omit<Currency, "country" | "countryCode">) {
  return (
    <Select.Option
      startAdornment={
        <div className="flex items-center gap-2">
          <strong>{currencyCode}</strong>
          <strong>-</strong>
        </div>
      }
      value={currencyCode}
    >
      {currencyName}
    </Select.Option>
  )
}

function isACurrencyGroup(currencies: Currency[] | CurrencyGroup[]): currencies is CurrencyGroup[] {
  return currencies[0] && Object.prototype.hasOwnProperty.call(currencies[0], "options")
}

export function MoneyInput({
  currencies = [],
  label,
  selectLabel = "Select a currency",
  selectPlaceholder = "Currency",
  textInputLabel = "Amount",
  textInputPlaceholder = "00.00",
  searchLabel = "Search currency",
  searchPlaceholder = "Search...",
  value,
  onChange,
  onTextInputFocus,
  onSelectInputFocus,
  onFocus,
  onBlur,
  name,
  ...rest
}: MoneyInputProps) {
  const [search, setSearch] = useState("")
  function renderCurrency({ currencyName, currencyCode, currencyDecimalDigits }: Currency) {
    return (
      <CurrencyOption
        key={currencyCode}
        currencyName={currencyName}
        currencyCode={currencyCode}
        currencyDecimalDigits={currencyDecimalDigits}
      />
    )
  }

  function renderCurrencyGroup(group: CurrencyGroup) {
    const options = group.options.filter(filterCurrencies)
    if (options.length === 0) return null
    return (
      <Select.Group key={group.label} label={group.label}>
        {options.filter(filterCurrencies).map(renderCurrency)}
      </Select.Group>
    )
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const _currencies = isACurrencyGroup(currencies)
      ? currencies.flatMap(({ options }) => options)
      : currencies
    const selectedCurrency = _currencies?.find(
      currency => currency?.currencyCode === value?.currency,
    )

    onChange?.({
      ...value,
      amount: convertToMaxDecimals(
        // Remove anything except numbers and dots
        e.target.value.replace(/[^\-0-9.]/g, ""),
        selectedCurrency?.currencyDecimalDigits ?? 2,
      ),
    })
  }

  function handleSelectChange(e: SelectProps["value"]) {
    if (e === null) return
    onChange?.({ ...value, currency: e })
  }

  function handleSearch(e: React.ChangeEvent<HTMLInputElement>) {
    setSearch(e.target.value)
  }

  function filterCurrencies(currency: Currency) {
    return (
      currency.currencyName.toLowerCase().includes(search.toLowerCase()) ||
      currency.currencyCode.toLowerCase().includes(search.toLowerCase())
    )
  }

  return (
    <InputWithSelect label={label} {...rest}>
      <InputWithSelect.Select
        label={selectLabel}
        placeholder={selectPlaceholder}
        value={value?.currency}
        onChange={handleSelectChange}
        name={name ? `${name}.currency` : undefined}
      >
        <Select.Input
          id="search"
          label={searchLabel}
          placeholder={searchPlaceholder}
          value={search}
          onChange={handleSearch}
          onFocus={onSelectInputFocus ?? onFocus}
          onBlur={onBlur}
        />
        {isACurrencyGroup(currencies)
          ? currencies.map(renderCurrencyGroup)
          : currencies.filter(filterCurrencies).map(renderCurrency)}
      </InputWithSelect.Select>
      <InputWithSelect.TextInput
        label={textInputLabel}
        placeholder={textInputPlaceholder}
        value={value?.amount}
        onChange={handleInputChange}
        onFocus={onTextInputFocus ?? onFocus}
        onBlur={onBlur}
        name={name ? `${name}.amount` : undefined}
      />
    </InputWithSelect>
  )
}
