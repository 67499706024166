import { UseCommentsProps, useComments } from "./useComments"

export const useTinyCommentsCallbacks = (props: UseCommentsProps) => {
  const commentFunctions = useComments(props)

  const tinycomments_create = (req, done, fail) => {
    commentFunctions.createCommentsThread({ req, done, fail })
  }
  const tinycomments_reply = (req, done, fail) => {
    commentFunctions.replyComment({ req, done, fail })
  }
  const tinycomments_edit_comment = (req, done, fail) => {
    commentFunctions.editComment({ req, done, fail })
  }
  const tinycomments_delete = (req, done, fail) => {
    commentFunctions.deleteCommentsThread({ req, done, fail })
  }
  const tinycomments_delete_all = (req, done, fail) => {
    commentFunctions.deleteAllCommentThreads({ req, done, fail })
  }
  const tinycomments_delete_comment = (req, done, fail) => {
    commentFunctions.deleteComment({ req, done, fail })
  }
  const tinycomments_lookup = (req, done, fail) => {
    commentFunctions.lookupComments({ req, done, fail })
  }
  const tinycomments_resolve = (req, done, fail) => {
    commentFunctions.resolveComments({ req, done, fail })
  }

  return {
    tinycomments_create,
    tinycomments_reply,
    tinycomments_edit_comment,
    tinycomments_delete,
    tinycomments_delete_all,
    tinycomments_delete_comment,
    tinycomments_lookup,
    tinycomments_resolve,
  }
}
