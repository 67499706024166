import { Fail } from "@/api/useCommentsApi"

export enum ConfirmUpdateOnThreadCreationModalType {
  Create = "create",
  Delete = "delete",
}

export interface ConfirmUpdateOnThreadCreationModalOpen {
  updateAction: () => void
  cancelAction: Fail
  type: ConfirmUpdateOnThreadCreationModalType
}

export type UpdateSection = (done: () => void) => void

export enum UnsavedChangeModalTypes {
  Back = "back",
  Cancel = "cancel",
}
