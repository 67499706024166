import classNames from "classnames"
import React, { cloneElement, forwardRef } from "react"
import { twMerge } from "tailwind-merge"
import * as RadioClasses from "../Radio/constants"
import { useRadioListContext } from "../RadioList/utils"
import { validateDisplayNameChild } from "../utils/validateDisplayNameChild"
import * as RadioCardClasses from "./constants"
import type { RadioCardLabelProps, RadioCardProps } from "./types"
import { devWarning } from "@/components/utils/devWarning"

const RadioCardInput = forwardRef<HTMLInputElement, RadioCardProps>(({ error, ...rest }, ref) => {
  const _className = twMerge(
    classNames(
      "peer",
      RadioClasses.BASE_CLASSES,
      RadioClasses.AFTER_ELEMENT,
      RadioCardClasses.POSITION_CLASSES,
      {
        [RadioClasses.BASE_HOVER_CLASSES]: !error && !rest.disabled,
        [RadioClasses.BASE_CHECKED_CLASSES]: !rest.disabled,
        [RadioClasses.AFTER_ELEMENT_CHECKED]: !rest.disabled,
        [RadioClasses.BASE_DISABLED_CLASSES]: rest.disabled,
        [RadioClasses.BASE_DISABLED_CHECKED_CLASSES]: rest.disabled,
        [RadioClasses.AFTER_ELEMENT_DISABLED_CHECKED]: rest.disabled,
        [RadioClasses.BASE_ERROR_CLASSES]: error && !rest.disabled,
        [RadioClasses.AFTER_ELEMENT_ERROR]: error && !rest.disabled,
        border: rest.disabled,
      },
    ),
  )

  return <input {...rest} className={_className} ref={ref} />
})

const RadioCardLabel = ({ children, hint, id }: RadioCardLabelProps) => {
  const labelId = `radio-card-label:${id}`
  const descriptionId = `radio-card-description:${id}`
  return (
    <>
      <div id={labelId} className="leading-6">
        {children}
      </div>
      <span id={descriptionId} className="text-xs leading-4 text-gray-400">
        {hint}
      </span>
    </>
  )
}

RadioCardLabel.displayName = "RadioCard.Label"

const RadioCard = forwardRef<HTMLInputElement, RadioCardProps>(
  ({ label, id, checked, className, disabled, error, ...rest }, ref) => {
    const ctx = useRadioListContext()
    const hasError = Boolean(ctx.error) || Boolean(error)
    const _className = twMerge(
      classNames(RadioCardClasses.BASE_CLASSES, {
        [RadioCardClasses.FOCUS_CLASSES]: !disabled,
        [RadioCardClasses.HOVER_CLASSES]: !hasError && !disabled,
        [RadioCardClasses.DISABLED_CLASSES]: disabled,
        [RadioCardClasses.CHECKED_CLASSES]: !hasError && !disabled,
        [RadioCardClasses.ERROR_CLASSES]: hasError && !disabled,
      }),
    )

    const _classNameContainer = classNames("group relative w-full", className)

    const props = Object.assign({}, rest, {
      type: "radio",
      id,
      checked,
      ref,
      disabled,
      error: Boolean(ctx.error),
      name: ctx.name ? ctx.name : rest.name,
      onChange: ctx.onChange ? ctx.onChange : rest.onChange,
    })

    devWarning(
      Boolean(ctx.name && rest.name),
      "It seems like you're setting a `name` prop on the `RadioCard` component, but you're also using the `RadioCard` component inside a `RadioList` component. Please remove the `name` prop from the `RadioCard` component.",
    )

    devWarning(
      Boolean(ctx.onChange && rest.onChange),
      "It seems like you're setting a `onChange` prop on the `RadioCard` component, but you're also using the `RadioCard` component inside a `RadioList` component. Please remove the `onChange` prop from the `RadioCard` component.",
    )

    const _label =
      label && validateDisplayNameChild(label, "RadioCard.Label")
        ? cloneElement(label, { ...label.props, id: id })
        : label

    return (
      <div className={_classNameContainer}>
        <RadioCardInput {...props} />
        <label
          aria-labelledby={`radio-card-label:${id}`}
          aria-describedby={`radio-card-description:${id}`}
          htmlFor={id}
          className={_className}
        >
          {_label}
        </label>
      </div>
    )
  },
)

const RadioCardNamespace = Object.assign(RadioCard, { Label: RadioCardLabel })

export { RadioCardNamespace as RadioCard }
