import React, { useState } from "react"
import { FieldProps } from "../types"
import { FormLabel, TextInput, useDecisionTableContext } from "@/components"

interface StringFieldProps extends FieldProps {
  isNumber?: boolean
}

export const StringField = ({
  row,
  cell,
  column,
  accessorKey,
  label,
  isNumber,
}: StringFieldProps) => {
  const {
    state: { validationErrors },
    dispatch,
  } = useDecisionTableContext()

  const [value, setValue] = useState(row._valuesCache[column.id])

  return (
    <>
      <FormLabel htmlFor={cell.id} id={`label-${cell.id}`} className="mb-2" label={label} />
      <TextInput
        name={cell.id}
        onChange={e => {
          const update = isNumber ? parseInt(e.target.value) : e.target.value
          if (isNumber && (update as number) < 0) {
            return
          }
          row._valuesCache[column.id] = update
          setValue(update)
        }}
        value={value}
        error={validationErrors[accessorKey]}
        maxLength={250}
        onFocus={() => {
          if (isNumber && !value) {
            setValue("0")
          }
          dispatch({
            validationErrors: {
              ...validationErrors,
              [accessorKey]: undefined,
            },
          })
        }}
        type={isNumber ? "number" : "text"}
      />
    </>
  )
}
