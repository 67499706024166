import React from "react"
import { TextInput } from "@/components"
import { DurationInnerFieldBaseProps } from "./types"

interface DurationAmountInputProps extends DurationInnerFieldBaseProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  error?: string
  onFocus?: () => void
}

export const DurationAmountInput = ({
  onChange,
  id,
  value,
  error,
  onFocus,
}: DurationAmountInputProps) => {
  return (
    <div className="mr-3 min-w-32">
      <TextInput
        name={id}
        type="number"
        min={0}
        onChange={e => {
          e.target.value = e.target.value === "" ? "0" : e.target.value
          if (parseFloat(e.target.value) < 0) {
            return
          }
          onChange(e)
        }}
        value={value}
        error={error}
        onFocus={onFocus}
      />
    </div>
  )
}
