import React from "react"
import { Select } from "@/components"
import { TimeUnits } from "../../../types"
import { DurationInnerFieldBaseProps } from "./types"

interface DurationUnitSelectProps extends DurationInnerFieldBaseProps {
  onChange: (value: string) => void
}

export const DurationUnitSelectInput = ({ id, onChange, value }: DurationUnitSelectProps) => {
  return (
    <Select name={id} onChange={onChange} value={value}>
      {Object.values(TimeUnits).map(option => {
        return (
          <Select.Option key={option} value={option}>
            {option}
          </Select.Option>
        )
      })}
    </Select>
  )
}
