export const BASE_CLASSES =
  "transition-all appearance-none w-5 h-5 border-2 border-gray-300 rounded-full relative cursor-pointer flex-none"
export const BASE_ERROR_CLASSES =
  "border-error-base focus-within:border-error-base checked:border-error-base"
export const BASE_HOVER_CLASSES = "hover:border-gray-400 checked:hover:border-primary-base"
export const BASE_CHECKED_CLASSES = "checked:border-primary-base"
export const BASE_DISABLED_CHECKED_CLASSES = "checked:border-gray-300"
export const BASE_DISABLED_CLASSES = "bg-gray-200 cursor-not-allowed"

export const AFTER_ELEMENT =
  "after:content-[''] bg-white after:transition-all after:block after:w-2 after:h-2 after:bg-transparent after:rounded-full after:absolute after:top-1/2 after:left-1/2 after:transform after:-translate-x-1/2 after:-translate-y-1/2"
export const AFTER_ELEMENT_CHECKED = "checked:after:bg-primary-base"
export const AFTER_ELEMENT_DISABLED_CHECKED = "checked:after:bg-gray-300"
export const AFTER_ELEMENT_ERROR = "checked:after:bg-error-base"
