// Re my-0.5 The height of the control should be the same height as the checkbox label so that they are vertically aligned with each other.

export const BASE_CLASSES =
  "transition-all flex h-5 w-5 my-0.5 appearance-none rounded border-2 text-transparent border-gray-300 bg-white cursor-pointer items-center justify-center"
export const BASE_CHECKED_CLASSES =
  "peer-checked:border-primary-base peer-checked:bg-primary-base peer-checked:text-white peer-indeterminate:border-primary-base peer-indeterminate:bg-primary-base peer-indeterminate:text-white peer-checked:peer-hover:border-primary-base peer-indeterminate:peer-hover:border-primary-base peer-indeterminate:peer-focus:border-primary-base peer-checked:peer-focus:border-primary-base"
export const BASE_HOVER_CLASSES = "peer-hover:border-gray-400 peer-focus:border-gray-400"
export const BASE_DISABLED_CLASSES =
  "border peer-disabled:bg-gray-200 peer-disabled:border-gray-300 peer-disabled:hover:border-gray-300 peer-disabled:peer-checked:text-gray-300 peer-disabled:peer-indeterminate:text-gray-300 peer-disabled:cursor-not-allowed"
export const ERROR_CLASSES =
  "peer-hover:border-error-base border-error-base peer-checked:hover:border-error-base peer-checked:border-error-base peer-checked:bg-error-base peer-checked:text-white peer-indeterminate:border-error-base peer-indeterminate:bg-error-base peer-indeterminate:text-white"

// relative class ensures that the sr-only checkbox stays contained to avoid strange scrolling behavior on Chrome
export const CONTAINER_BASE_CLASSES =
  "flex items-start gap-2 text-black font-medium font-work-sans w-fit relative"
