import React from "react"

type LoadingSpinnerVariations = "primary" | "secondary" | "error"

type LoadingSpinnerSize = "sm" | "md" | "lg"

interface LoadingSpinnerProps {
  variant?: LoadingSpinnerVariations
  className?: string
  size?: LoadingSpinnerSize
}

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  variant = "primary",
  className = "",
  size = "md",
}) => {
  const variationClassNames: Record<LoadingSpinnerVariations, string> = {
    primary: "border-white border-b-primary-base",
    secondary: "border-primary-base border-b-white",
    error: "border-error-base border-b-white",
  }

  const sizeClassNames: Record<LoadingSpinnerSize, string> = {
    sm: "w-4 h-4 border-2",
    md: "w-6 h-6 border-2",
    lg: "w-10 h-10 border-4",
  }

  return (
    <div className={`w-full flex justify-center items-center ${className}`}>
      <div className={`loader ${variationClassNames[variant]} ${sizeClassNames[size]}`} />
    </div>
  )
}
