import React, { useState } from "react"
import { SSOTSection } from "@/types/SSOTDocument"
import { Button } from "../Button"
import classNames from "classnames"
import { useSSOTSectionsContext } from "@administrate/views/components"
import { Tooltip } from "@/components/Tooltip"
import { parseSections } from "@/bundles/administrate/utils/parseSections"

const chevron = require("./assets/chevron.svg")
const link = require("./assets/link.svg")

interface AccordionItemProps {
  section: SSOTSection
  activeSection: SSOTSection
  onActiveSectionChange: (section: SSOTSection) => void
  key: string
}

const AccordionItem = ({ section, activeSection, onActiveSectionChange }: AccordionItemProps) => {
  const [linkCopied, setLinkCopied] = useState(false)
  const selected = activeSection.id === section.id
  const hasChildren = Array.isArray(section.children) && section.children.length > 0
  const allSectionsContained = hasChildren ? parseSections(section.children, 0) : []
  const [open, setOpen] = useState(!!allSectionsContained.find(s => s.id === activeSection.id))

  const handleClick = () => {
    setOpen(!open)
    !hasChildren && onActiveSectionChange(section)
  }

  const handleCopyLink = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setLinkCopied(true)
    navigator.clipboard.writeText(section.urls.app.resource)
  }

  const generateId = () => {
    const relevantPartsOfResource = section.urls.app.resource.split("ssot_documents/")[1].split("/")
    // Remove country code
    relevantPartsOfResource.shift()
    return relevantPartsOfResource.join("-")
  }

  return (
    <div className="w-full">
      <Button
        onClick={handleClick}
        variant={selected ? "primary" : "borderless"}
        className={classNames("mb-2", { "font-semibold": hasChildren })}
        data-testid={generateId()}
      >
        {section.name}
        {hasChildren ? (
          <img className={`block ml-4 ${open ? "rotate-180" : ""}`} src={chevron} alt="Show more" />
        ) : (
          <Tooltip value={linkCopied ? "Link copied" : "Copy link"}>
            <button
              className="ml-3 bg-gray-100 p-1"
              onClick={handleCopyLink}
              onMouseLeave={() => {
                if (linkCopied) {
                  setLinkCopied(false)
                }
              }}
            >
              <img
                src={link}
                alt="Copy link"
                style={{ height: 16, minHeight: 16, width: 16, minWidth: 16 }}
              />
            </button>
          </Tooltip>
        )}
      </Button>
      {open && (
        <div className=" w-full ml-3">
          {hasChildren &&
            section.children.map(section => (
              <AccordionItem
                section={section}
                key={section.kind}
                activeSection={activeSection}
                onActiveSectionChange={onActiveSectionChange}
              />
            ))}
        </div>
      )}
    </div>
  )
}

interface MultilevelAccordionProps {
  onActiveSectionChange: (section: SSOTSection) => void
}

export const MultilevelAccordion = ({ onActiveSectionChange }: MultilevelAccordionProps) => {
  const { sections, activeSection } = useSSOTSectionsContext()

  return sections.map(section => (
    <AccordionItem
      section={section}
      key={section.kind}
      activeSection={activeSection}
      onActiveSectionChange={onActiveSectionChange}
    />
  ))
}
