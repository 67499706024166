import React, { forwardRef, ReactNode } from "react"
import { useId } from "@floating-ui/react"
import classNames from "classnames"
import { BaseSelectOptionProps } from "./types"
import { getGridClassNames } from "../utils/getGridClassNames"

export const BaseSelectOption = forwardRef<HTMLLIElement, BaseSelectOptionProps>(
  (
    { children, className: classNameProp, endAdornment, innerClassName, startAdornment, ...rest },
    ref,
  ) => {
    const labelId = useId()
    const className = classNames(
      "w-full cursor-pointer list-none px-4 py-2 outline-none hover:bg-gray-100",
      "aria-selected:bg-primary-lighten-100 aria-selected:text-primary-base",
      "aria-disabled:cursor-not-allowed aria-disabled:opacity-20",
      getGridClassNames(startAdornment, endAdornment),
      classNameProp,
    )

    const isChildrenRenderFunction = typeof children === "function"
    const _children: ReactNode = isChildrenRenderFunction ? children({ labelId }) : children
    const innerId = isChildrenRenderFunction ? undefined : labelId

    return (
      <li aria-labelledby={labelId} ref={ref} className={className} {...rest}>
        {/* start and end adornments are set as aria-hidden so that the list takes its accessible name from the children only */}
        {/* if users want to add a custom accessible name, they can use aria-labelledby on the `li` element and id on the specific part of the children */}
        {startAdornment && <div>{startAdornment}</div>}
        <div className={innerClassName} id={innerId}>
          {_children}
        </div>
        {endAdornment && <div>{endAdornment}</div>}
      </li>
    )
  },
)
