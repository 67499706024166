export const BASE_CLASSES =
  "leading-none border border-gray-200 bg-white rounded-md p-4 pl-12 block items-center ring-primary-lighten-50 outline-primary-base w-full"
export const FOCUS_CLASSES =
  "peer-focus-visible:border-primary-base peer-focus-visible:outline peer-focus-visible:outline-1 peer-focus-visible:ring-4"
export const HOVER_CLASSES = "group-hover:border-gray-300"
export const CHECKED_CLASSES =
  "peer-checked:outline-1 peer-checked:outline peer-checked:border-primary-base"
export const POSITION_CLASSES = "absolute top-1/2 left-4 transform -translate-y-1/2 outline-none"
export const DISABLED_CLASSES = "[&_*]:opacity-60 cursor-not-allowed"
export const ERROR_CLASSES =
  "border-error-base peer-focus-visible:border-error-base outline-error-base peer-checked:outline peer-checked:outline-1"
