export const useExtractInfoFromSsotUrl = () => {
  const splittedPathname = window.location.pathname.split("/")

  if (!splittedPathname.includes("ssot_documents")) {
    return {
      oysterScore: undefined,
      section: undefined,
      category: undefined,
    }
  }

  if (splittedPathname[splittedPathname.length - 1] === "edit") {
    splittedPathname.pop()
  }

  const oysterScore = splittedPathname[splittedPathname.length - 1]
  const getPositionInArray = (number: number) => splittedPathname.length - number

  const section = splittedPathname[getPositionInArray(2)]
  const category = splittedPathname[getPositionInArray(3)]

  return {
    oysterScore,
    section,
    category,
  }
}
