import React from "react"
import { User } from "@/types/commons"
import { useIntl } from "react-intl"

interface UpdateAndPublicationInfoProps {
  updatedAt?: string
  updatedBy?: User
  publishedAt?: string
  publishedBy?: User
}

export const UpdateAndPublicationInfo = ({
  updatedAt,
  updatedBy,
  publishedAt,
  publishedBy,
}: UpdateAndPublicationInfoProps) => {
  const { $t } = useIntl()

  return (
    <div className="flex flex-wrap">
      <div className="flex items-center mb-2">
        <h3 className="text-gray-400 mr-2">Last updated</h3>
        <p className="text-black mr-2">
          {updatedAt
            ? new Date(updatedAt).toLocaleString()
            : $t({ id: "update_and_publication_info.not_updated_yet" })}
        </p>
      </div>

      <div className="flex items-center mr-4 mb-2">
        <h3 className="text-gray-400 mr-2">by</h3>
        <p className="text-black mr-2" data-testid="last-update-author">
          {updatedBy?.name ?? $t({ id: "update_and_publication_info.not_yet_specified" })}
        </p>
      </div>

      <div className="flex items-center mb-2">
        <h3 className="text-gray-400 mr-2">Last published</h3>
        <p className="text-black mr-2">
          {publishedAt
            ? new Date(publishedAt).toLocaleString()
            : $t({ id: "update_and_publication_info.not_published_yet" })}
        </p>
      </div>

      <div className="flex items-center mb-2">
        <h3 className="text-gray-400 mr-2">by</h3>
        <p className="text-black" data-testid="last-publication-author">
          {publishedBy?.name ?? $t({ id: "update_and_publication_info.not_yet_specified" })}
        </p>
      </div>
    </div>
  )
}
