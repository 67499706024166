import { LPSReviewStatus } from "@/types/SSOTDocument"
import { FetchMethod, doFetch } from "./doFetch"

type Done = (data: Record<string, string>) => void

interface PublishParams {
  id: number
  done?: Done
  fail?: (e: Error) => void
}

interface UpdateParams extends PublishParams {
  content?: string
  lpsReviewStatus?: LPSReviewStatus
}

export const useSSOTDocumentAPI = () => {
  const updateSSOTDocument = ({ id, lpsReviewStatus, done, fail }: UpdateParams) =>
    doFetch({
      endpoint: `ssot_documents/${id}`,
      method: FetchMethod.PATCH,
      body: {
        ssotDocument: {
          lpsReviewStatus,
        },
      },
      errorMessage: "Failed to update document!",
      done,
      fail,
    })

  return {
    updateSSOTDocument,
  }
}
