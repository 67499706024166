import { type MRT_Row } from "material-react-table"
import { DecisionRule } from "@/types/DecisionTable"

export const sortOwners = (
  rowA: MRT_Row<DecisionRule>,
  rowB: MRT_Row<DecisionRule>,
  columnId: string,
) => {
  const nameA = rowA._valuesCache[columnId]?.name
  const nameB = rowB._valuesCache[columnId]?.name

  return nameA?.localeCompare(nameB)
}
