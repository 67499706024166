import React from "react"
import { Alert as MuiAlert, type AlertProps as MuiAlertProps } from "@mui/material"
import { type AlertSeverity } from "./types"
import classNames from "classnames"

interface AlertProps extends MuiAlertProps {
  positionClassNames: string
}

export const Alert = ({ positionClassNames, ...props }: AlertProps) => {
  const stylesForStandardVariant: Record<AlertSeverity, { background: string; color: string }> = {
    error: { background: "#fde1e1", color: "#6e031e" },
    success: { background: "#d6f3e9", color: "#003f1b" },
    info: { background: "#d9ecf7", color: "#023e64" },
    warning: { background: "#fcf0db", color: "#814004" },
  }

  return (
    <div className={classNames("z-10", positionClassNames)}>
      <div className="flex justify-center align-center">
        <MuiAlert
          {...props}
          style={{
            backgroundColor:
              props.variant === "standard" || !props.variant
                ? stylesForStandardVariant[props.severity].background
                : undefined,
            color:
              props.variant === "standard" || !props.variant
                ? stylesForStandardVariant[props.severity].color
                : undefined,
          }}
          className="min-w-40"
        />
      </div>
    </div>
  )
}
