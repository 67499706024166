import React from "react"
import { FloatingPortal } from "@floating-ui/react"
import { ToastState } from "./types"

export const ToastList = ({ toasts }: { toasts: ToastState }) => {
  const toastEntries = Object.entries(toasts)
  if (toastEntries.length === 0) {
    return null
  }
  return (
    <FloatingPortal>
      <ul className="fixed left-0 right-0 top-4 mx-auto w-[min(calc(100%_-_32px),_420px)] space-y-3 md:left-auto md:right-8 md:top-8">
        {toastEntries.map(([toastId, toastElement]) => (
          <li key={toastId}>{toastElement}</li>
        ))}
      </ul>
    </FloatingPortal>
  )
}
