import React, { useState } from "react"
import { FetchMethod, doFetch } from "@/api/doFetch"
import { DecisionTable } from "@/types/DecisionTable"
import { ErrorContent } from "./ErrorContent"
import { Modal, InfoBanner, Button } from "@/components"
import { amplitudeLogEvent } from "@/utils/amplitudeHelpers"

interface ConfirmationModalProps {
  modalOpen: boolean
  setModalOpen: (modalOpen: boolean) => void
  table: DecisionTable
  handlePublication: (updatedDecisionTable: DecisionTable) => void
}

export const ConfirmationModal = ({
  modalOpen,
  setModalOpen,
  table,
  handlePublication,
}: ConfirmationModalProps) => {
  const [publishing, setPublishing] = useState(false)
  const [error, setError] = useState(false)

  const handlePublish = async () => {
    setPublishing(true)
    amplitudeLogEvent("[SE]CA-click_confirm_publish_decision_table")
    setError(false)
    await doFetch({
      endpoint: table.urls.api.publish,
      method: FetchMethod.POST,
      errorMessage: "Failed to publish decision table",
      done: (res: Partial<DecisionTable>) => {
        handlePublication({ ...table, ...res })
        setModalOpen(false)
      },
      fail: () => {
        setError(true)
      },
    })
    setPublishing(false)
  }

  return (
    <Modal title={!error && "Confirm changes"} isOpen={modalOpen} setIsOpen={setModalOpen}>
      {error ? (
        <ErrorContent />
      ) : (
        <div className="max-w-lg">
          <p className="mb-5">
            Are you sure you want to publish these rules? Any changes will be published across all
            Oyster platform processes and immediately visible to the public.
          </p>
          <InfoBanner>
            <p>
              Remember to review and publish the specific SSOT documents to ensure all modifications
              are reflected on Zendesk.
            </p>
          </InfoBanner>

          <div className="flex justify-end mt-8">
            <div>
              <Button variant="secondary" onClick={() => setModalOpen(false)}>
                Cancel
              </Button>
            </div>
            <div className="ml-4">
              <Button variant="primary" onClick={handlePublish} loading={publishing}>
                Publish
              </Button>
            </div>
          </div>
        </div>
      )}
    </Modal>
  )
}
