import React, { useState } from "react"
import { FormLabel, Select, useDecisionTableContext } from "@/components"
import { type TCountryCode, getEmojiFlag } from "countries-list"
import { FieldProps } from "../types"
import { DecisionColumnEnumValue } from "@/types/DecisionTable"
import { checkIsNewRuleById } from "../../../utils"

interface CountryFieldProps extends FieldProps {
  options: DecisionColumnEnumValue[]
}

export const CountryField = ({
  row,
  cell,
  column,
  accessorKey,
  label,
  options,
}: CountryFieldProps) => {
  const {
    state: { validationErrors },
    dispatch,
  } = useDecisionTableContext()
  const [value, setValue] = useState(row._valuesCache[column.id])
  const [filter, setFilter] = useState("")

  const orderedCountries = options.sort((a, b) =>
    a.title > b.title ? 1 : b.title > a.title ? -1 : 0,
  )
  const filteredCountries =
    filter === ""
      ? orderedCountries
      : orderedCountries.filter(({ title, value }) => {
          return (
            title.toLowerCase().includes(filter.toLowerCase()) ||
            value.toLowerCase().includes(filter.toLowerCase())
          )
        })

  return (
    <>
      <FormLabel htmlFor={cell.id} className="mb-2" id={`label-${cell.id}`} label={label} />
      <Select
        name={cell.id}
        onChange={(countryCode: string) => {
          row._valuesCache[column.id] = countryCode
          setValue(countryCode)
        }}
        value={value}
        error={validationErrors[accessorKey]}
        onFocus={() => {
          setFilter("")
          dispatch({
            validationErrors: {
              ...validationErrors,
              [accessorKey]: undefined,
            },
          })
        }}
        disabled={!checkIsNewRuleById(cell.id)}
      >
        <Select.Input
          placeholder="Search by country name or country code"
          onChange={e => {
            setFilter(e.target.value)
          }}
          value={filter}
        />
        {filteredCountries.map(({ value, title }) => {
          return (
            <Select.Option key={value} value={value}>
              {getEmojiFlag(value as TCountryCode)} {title}
            </Select.Option>
          )
        })}
      </Select>
    </>
  )
}
