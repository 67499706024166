import { useReducer } from "react"

export function useStateReducer<T>(initialState?: T): {
  state: T
  dispatch: (update: Partial<T>) => void
} {
  const stateReducer = (prevState: T, update: Partial<T>) => ({
    ...prevState,
    ...update,
  })
  const [state, dispatch] = useReducer(stateReducer, initialState ?? ({} as T))

  return { state, dispatch }
}
