import React, { PropsWithChildren } from "react"

export function SelectEmptyState({ children }: PropsWithChildren<unknown>) {
  return (
    <div className="px-4 py-4">
      <span className="block text-sm text-gray-400">
        {children ? children : "No options found"}
      </span>
    </div>
  )
}
