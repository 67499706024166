import React from "react"
import { Editor } from "@tinymce/tinymce-react"

import TestEditor from "./TestEditor"
import { API_KEY } from "./tinymce"
import { useRailsContext } from "@/components"

interface PreviewEditorProps {
  content: string
}

const PreviewEditor: React.FC<PreviewEditorProps> = ({ content }) => {
  const { railsEnv } = useRailsContext()

  if (railsEnv == null || railsEnv === "test") {
    return <TestEditor initialValue={content} />
  }

  return (
    <Editor
      apiKey={API_KEY}
      cloudChannel="7.3.0"
      initialValue={content}
      disabled={true}
      init={{
        menubar: false,
        toolbar: false,
        statusbar: false,
        plugins: ["autoresize"],
        skin: "borderless",
        content_css: "document",
        autoresize_overflow_padding: 100,
      }}
    />
  )
}

export default PreviewEditor
