import React from "react"
import { Editor } from "@tinymce/tinymce-react"
import { Editor as EditorType } from "tinymce"

export interface TestEditorProps {
  id?: string
  textareaName?: string
  initialValue?: string
  onEditorChange?: (value: string, editor: EditorType) => void
}

const TestEditor: React.FC<TestEditorProps> = ({
  id,
  textareaName,
  initialValue,
  onEditorChange,
}) => {
  return (
    // don't pass the API key!
    <Editor
      id={id}
      textareaName={textareaName}
      initialValue={initialValue}
      onEditorChange={onEditorChange}
      init={{ theme: false }}
    />
  )
}

export default TestEditor
