import React from "react"

const alertIcon = require("./assets/alert.png")

export const ErrorContent = () => (
  <div className="flex flex-col items-center max-w-lg">
    <img src={alertIcon} alt="Error" className="w-16" />
    <h3 className="font-semibold text-black text-xl my-4">Unable to publish</h3>
    <p className="text-center my-4">
      Unfortunately, SAGE can&apos;t seem to publish your recent changes. Our engineers will attend
      to the problem shortly.
    </p>
  </div>
)
