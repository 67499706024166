import { type MRT_TableInstance } from "material-react-table"
import React, { useEffect } from "react"
import { Button, useDecisionTableContext } from "@/components"
import { DecisionRule } from "@/types/DecisionTable"
import { useIntl } from "react-intl"
const rocket = require("./assets/rocket.svg")

interface EmptyDecisionTableViewProps {
  table: MRT_TableInstance<DecisionRule>
}

export const EmptyDecisionTableView = ({ table }: EmptyDecisionTableViewProps) => {
  const { state } = useDecisionTableContext()
  const { $t } = useIntl()

  useEffect(() => {
    document
      .querySelectorAll("th")
      [state.insideSSOT ? 0 : 1].scrollIntoView?.({ behavior: "smooth" })
  }, [state.insideSSOT])

  return state.decisionRows.length === 0 ? (
    <span
      className="flex flex-col justify-center items-center py-10"
      style={{ maxWidth: "calc(100vw - 390px)" }}
    >
      <span className="flex flex-col justify-center items-center max-w-md text-center">
        <img src={rocket} alt="Rocket illustration" className="w-36" />
        <span className="block my-4">
          Set rules to establish compliance of all employment processes across the entire Oyster
          platform
        </span>
        {(!state.insideSSOT || state.insideSSOT.editMode) && (
          <Button
            onClick={() => table.setCreatingRow(true)}
            variant="primary"
            className="max-w-40 !justify-center"
          >
            Add new rule
          </Button>
        )}
      </span>
    </span>
  ) : (
    <span className="block text-lg my-4" style={{ marginLeft: "30vw" }}>
      {$t({ id: "decision_table.empty_table.no_results" })}
    </span>
  )
}
