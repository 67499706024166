import React, { KeyboardEventHandler, useEffect } from "react"
import { useSelectContext } from "./SelectContext"
import type { SelectInputProps } from "./types"
import { LoadingSpinner } from "../LoadingSpinner"
import { TextInput } from "../TextInput"
const magnifyingGlass = require("./assets/magnifying-glass.svg")

export const SelectInput = ({ label, isLoading, ...rest }: SelectInputProps) => {
  const { setTypeaheadEnabled, open, onSelectValue } = useSelectContext()

  // Disable typeahead when the list is open and there is a search input present
  // This is so that the events don't block the typing into the search input
  useEffect(() => {
    setTypeaheadEnabled?.(!open)
  }, [open, setTypeaheadEnabled])

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = evt => {
    if (evt.key === "Enter") {
      evt.preventDefault()
      onSelectValue?.()
    }
  }

  return (
    <div className="sticky top-0 z-10 bg-white p-4">
      <TextInput
        endAdornment={
          <div className="flex gap-2.5">
            {isLoading && <LoadingSpinner size="sm" />}
            <img alt="search" src={magnifyingGlass} />
          </div>
        }
        label={<label className="sr-only">{label}</label>}
        onKeyDown={handleKeyDown}
        size="sm"
        {...rest}
      />
    </div>
  )
}

SelectInput.displayName = "Select.Input"
