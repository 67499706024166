import React, { ReactNode, useCallback, useState } from "react"
import { omit } from "lodash-es"
import { CloseToastContext, TriggerToastContext, ToastContext } from "./ToastContexts"
import { ToastState } from "./types"
import { ToastList } from "./ToastList"
import { devWarning } from "@/components/utils/devWarning"

export const ToastProvider = ({ children }: { children: ReactNode }) => {
  const [toasts, setToasts] = useState<ToastState>({})

  const closeToast = useCallback((id: string | undefined) => {
    devWarning(
      !id,
      "A Toast was rendered without an ID and will not behave as expected. To fix this, pass the Toast component directly to the useToast's render function like `toast({render: <Toast><Toast.Content>Hello</Toast.Content></Toast>})`. Avoid `toast({render: <MyCustomToast/>})`.",
    )
    setToasts(prevToasts => omit(prevToasts, id as string))
  }, [])

  const triggerToast = useCallback((toast: ReactNode, id: string) => {
    setToasts(prev => ({ ...prev, [id]: toast }))
  }, [])

  return (
    <ToastContext.Provider value={{ toasts, setToasts }}>
      <TriggerToastContext.Provider value={triggerToast}>
        <CloseToastContext.Provider value={closeToast}>
          {children}
          <ToastList toasts={toasts} />
        </CloseToastContext.Provider>
      </TriggerToastContext.Provider>
    </ToastContext.Provider>
  )
}
