import React, { ReactNode, createContext, useContext, useEffect } from "react"
import { type DecisionTable } from "@/types/DecisionTable"
import { ValidationErrors } from "./types"
import { AdministrateActions } from "@/types/commons"
import { SSOTSection } from "@/types/SSOTDocument"
import { UpdateDecisionTableParams } from "@administrate/views/hooks/useSections"
import { useStateReducer } from "@/hooks/useStateReducer"

export interface InsideSSOT {
  countryCode: string
  subdivisionCode?: string
  editMode: boolean
  activeSection: SSOTSection
  updateDecisionTable: (params: UpdateDecisionTableParams) => void
}

export type DecisionTableState = DecisionTable & {
  insideSSOT?: InsideSSOT
  loading: boolean
  showColumnFilters: boolean
  showDeletionModal?: string
  validationErrors: ValidationErrors
  pinnedColumns: {
    left: string[]
    right: string[]
  }
  actions: AdministrateActions
}

interface DecisionTableContextType {
  state: DecisionTableState
  dispatch: (update: Partial<DecisionTableState>) => void
}

const DecisionTableContext = createContext<DecisionTableContextType | null>(null)

export const useDecisionTableContext = () => {
  const context = useContext(DecisionTableContext)

  if (!context) throw new Error("DecisionTableContext must be called within DecisionTableProvider")

  return context
}

export const DecisionTableProvider = ({
  children,
  initialState,
  decisionTable,
}: {
  children: ReactNode
  initialState?: DecisionTableState
  decisionTable: DecisionTable
}) => {
  const { state, dispatch } = useStateReducer<DecisionTableState>(initialState)

  useEffect(() => {
    dispatch({
      ...decisionTable,
      // We have to take this properties from the state because when the table
      // is published they don't come in the response. If we don't take them from
      // the state, the changes occurred in the table since the last time the page
      // was loaded would be lost until the page is loaded again.
      schema: state.schema,
      jsonSchema: state.jsonSchema,
      decisionRows: state.decisionRows.map(decisionRow => ({
        ...decisionRow,
        // Only in the case of the owner property we need to check if there has been an
        // update coming from the outside because it's updated in EditRuleOwnershipModal.
        // If we don't do this, the update won't be reflected in the owner field of the
        // column until the page is updated. This can't generate conflicts or bugs
        // because updating the owner from inside the table isn't possible.
        owner:
          decisionTable.decisionRows?.find(({ id }) => id === decisionRow.id)?.owner ||
          decisionRow.owner,
      })),
    })
    // Adding the state dependencies causes an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decisionTable, dispatch])

  useEffect(() => dispatch({ insideSSOT: initialState?.insideSSOT }), [initialState, dispatch])

  return (
    <DecisionTableContext.Provider value={{ state, dispatch }}>
      {children}
    </DecisionTableContext.Provider>
  )
}
