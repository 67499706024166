import * as amplitude from "@amplitude/analytics-browser"

export const amplitudeLogEvent = (
  eventName: string | amplitude.Types.BaseEvent,
  eventProperties?: Record<string, any>,
) => {
  if (window.gon?.amplitudeAPIKey === undefined || window.gon?.amplitudeAPIKey === null) {
    return
  }

  try {
    amplitude.logEvent(eventName, {
      eventSource: "client_side",
      ...eventProperties,
    })
  } catch (err) {
    console.warn("Amplitude error", err)
  }
}
