import React from "react"
import { LegalAndPeopleServicesReviewStatus } from "@/types/SSOTDocument"
import { useDocumentStatuses } from "@/hooks/useDocumentStatuses"
import { statusToBadgeVariant } from "@administrate/utils/badge/statusToBadgeVariant"
import { Badge, LoadingSpinner, Select } from "@/components"

interface StatusProps {
  onChange?: (status: LegalAndPeopleServicesReviewStatus) => void
  value: LegalAndPeopleServicesReviewStatus
  editable: boolean
  id: string
  loading?: boolean
}

export const Status = ({ onChange, value, editable, id, loading }: StatusProps) => {
  const { lpsReviewStatuses } = useDocumentStatuses()

  return editable ? (
    <div className="w-fit min-w-52">
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Select name={id} onChange={onChange} value={value} size="sm">
          {lpsReviewStatuses.map(status => (
            <Select.Option key={status.value} value={status.value}>
              <Badge className="w-fit" variant={statusToBadgeVariant(status.value)}>
                {status.text}
              </Badge>
            </Select.Option>
          ))}
        </Select>
      )}
    </div>
  ) : (
    <Badge className="w-fit" variant={statusToBadgeVariant(value)}>
      {lpsReviewStatuses.find(({ value: v }) => value === v).text}
    </Badge>
  )
}
