import React from "react"
import { Button, Modal } from "@/components"
import {
  ConfirmUpdateOnThreadCreationModalOpen,
  ConfirmUpdateOnThreadCreationModalType,
} from "./types"

interface ConfirmUpdateOnThreadCreationModalProps {
  modalOpen?: ConfirmUpdateOnThreadCreationModalOpen
  setModalOpen: (modalOpen: ConfirmUpdateOnThreadCreationModalOpen | undefined) => void
  setShouldUpdateSectionOnChange: (shouldUpdateSectionOnChange: boolean) => void
}

export const ConfirmUpdateOnThreadCreationModal: React.FC<
  ConfirmUpdateOnThreadCreationModalProps
> = ({ modalOpen, setModalOpen, setShouldUpdateSectionOnChange }) => {
  const handleCloseWithoutUpdate = () => {
    modalOpen?.cancelAction("Canceled")
    setModalOpen(undefined)
    setShouldUpdateSectionOnChange(false)

    // Removes the error alert that TinyMCE shows when the fail event happens.
    // We trigger the fail event with modalOpen.cancelAction, as it's the only
    // way to remove the loading state from the Editor's sidebar.
    setTimeout(() => {
      document.querySelector(".tox-tinymce-aux")?.remove()
    })
  }

  return (
    <Modal isOpen={!!modalOpen} setIsOpen={handleCloseWithoutUpdate} title="Save document changes?">
      <p className="mb-6">
        To{" "}
        {modalOpen?.type === ConfirmUpdateOnThreadCreationModalType.Create
          ? "save this new conversation"
          : "delete this conversation"}{" "}
        we need to update the document. Your current changes will be saved and the previous version
        will be lost.
      </p>
      <div className="flex justify-end">
        <div className="w-fit mr-3">
          <Button variant="secondary" onClick={handleCloseWithoutUpdate}>
            Cancel
          </Button>
        </div>
        <div className="w-fit">
          <Button
            variant="primary"
            onClick={async () => {
              await modalOpen?.updateAction()
              setModalOpen(undefined)
            }}
          >
            Update document
          </Button>
        </div>
      </div>
    </Modal>
  )
}
