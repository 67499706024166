import React, { ReactNode, createContext, useContext } from "react"
import { SSOTSection } from "@/types/SSOTDocument"
import useSections, {
  UpdateDecisionRuleOwnerParams,
  UpdateDecisionTableParams,
  UpdateSectionContentParams,
  UpdateSectionContentOwnershipParams,
} from "../../hooks/useSections"

interface SSOTSectionsContextType {
  sections: SSOTSection[]
  activeSection: SSOTSection
  updateDecisionRuleOwner: (params: UpdateDecisionRuleOwnerParams) => void
  updateSectionContent: (params: UpdateSectionContentParams) => void
  setActiveSection: (section: SSOTSection) => void
  updateDecisionTable: (params: UpdateDecisionTableParams) => void
  updateSSOTSectionContentOwnership: (params: UpdateSectionContentOwnershipParams) => void
}

const SSOTSectionsContext = createContext<SSOTSectionsContextType | null>(null)

export const useSSOTSectionsContext = () => {
  const context = useContext(SSOTSectionsContext)

  if (!context) throw new Error("SSOTSectionsContext must be called within SSOTSectionsProvider")

  return context
}

export const SSOTSectionsProvider = ({
  children,
  rootSectionChildren,
}: {
  children: ReactNode
  rootSectionChildren: SSOTSection[]
}) => {
  const {
    sections,
    activeSection,
    updateDecisionRuleOwner,
    updateSectionContent,
    setActiveSection,
    updateDecisionTable,
    updateSSOTSectionContentOwnership,
  } = useSections({
    children: rootSectionChildren,
  })

  return (
    <SSOTSectionsContext.Provider
      value={{
        sections,
        activeSection,
        updateDecisionRuleOwner,
        updateSectionContent,
        setActiveSection,
        updateDecisionTable,
        updateSSOTSectionContentOwnership,
      }}
    >
      {children}
    </SSOTSectionsContext.Provider>
  )
}
