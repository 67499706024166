import React from "react"

export const CheckCircle = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 0.25C8.07164 0.25 6.18657 0.821828 4.58319 1.89317C2.97982 2.96451 1.73013 4.48726 0.992179 6.26884C0.254225 8.05042 0.061142 10.0108 0.437348 11.9021C0.813554 13.7934 1.74215 15.5307 3.10571 16.8943C4.46928 18.2579 6.20656 19.1865 8.09787 19.5627C9.98919 19.9389 11.9496 19.7458 13.7312 19.0078C15.5127 18.2699 17.0355 17.0202 18.1068 15.4168C19.1782 13.8134 19.75 11.9284 19.75 10C19.745 7.41566 18.7162 4.93859 16.8888 3.11118C15.0614 1.28378 12.5843 0.254956 10 0.25ZM14.6406 8.29375L9.14688 13.5438C9.00485 13.6774 8.81687 13.7512 8.62188 13.75C8.52657 13.7514 8.43194 13.7338 8.34344 13.6984C8.25494 13.663 8.17433 13.6105 8.10625 13.5438L5.35938 10.9188C5.28319 10.8523 5.22123 10.7711 5.17722 10.6801C5.13321 10.589 5.10806 10.49 5.10328 10.389C5.0985 10.2881 5.11419 10.1871 5.14941 10.0924C5.18463 9.99758 5.23865 9.9109 5.30822 9.83754C5.3778 9.76417 5.46149 9.70563 5.55426 9.66543C5.64703 9.62523 5.74698 9.6042 5.84809 9.60362C5.94919 9.60303 6.04938 9.62289 6.14261 9.66201C6.23585 9.70113 6.32021 9.7587 6.39063 9.83125L8.62188 11.9594L13.6094 7.20625C13.7552 7.07902 13.9446 7.01309 14.1379 7.02223C14.3312 7.03138 14.5135 7.1149 14.6467 7.25533C14.7798 7.39576 14.8536 7.58222 14.8524 7.77575C14.8513 7.96928 14.7754 8.15488 14.6406 8.29375Z"
      fill="#007E35"
    />
  </svg>
)
