import React, {
  Children,
  MutableRefObject,
  isValidElement,
  useLayoutEffect,
  useMemo,
  useState,
} from "react"
import { FloatingList } from "@floating-ui/react"
import { useSelectContext } from "./SelectContext"
import { SelectListProps } from "./types"
import { validateDisplayNameChild } from "../utils/validateDisplayNameChild"
import { FocusManager } from "../FocusManager"

export const SelectList = ({ children, emptyState }: SelectListProps) => {
  /**
   * When pointer is true, it avoids the current active list item to scroll into view when hovering over it.
   * Check useLayoutEffect below for more details.
   */
  const [pointer, setPointer] = useState(false)
  const {
    id,
    open,
    activeIndex,
    listNodeRef,
    listStringRef,
    setOpen,
    interactions: { getFloatingProps } = { getFloatingProps: undefined },
    floating: { y, x, strategy, refs } = {
      floating: undefined,
      context: undefined,
      y: undefined,
      x: undefined,
      strategy: undefined,
    },
  } = useSelectContext()

  const isFilled = useMemo(() => {
    return Children.toArray(children).some(child => {
      return (
        isValidElement(child) &&
        (validateDisplayNameChild(child, "Select.Group") ||
          validateDisplayNameChild(child, "Select.Option"))
      )
    })
  }, [children])

  const hasSelectGroups = useMemo(() => {
    return Children.toArray(children).some(child => {
      return isValidElement(child) && validateDisplayNameChild(child, "Select.Group")
    })
  }, [children])

  const ContainerElement = hasSelectGroups ? "div" : "ul"

  useLayoutEffect(() => {
    if (open && activeIndex != null && !pointer) {
      requestAnimationFrame(() => {
        listNodeRef?.current[activeIndex]?.scrollIntoView?.({
          block: "nearest",
        })
      })
    }
  }, [open, activeIndex, listNodeRef, pointer])

  if (!open && pointer) {
    setPointer(false)
  }

  const getContent = () => (
    <ContainerElement
      ref={refs?.setFloating}
      aria-labelledby={`${`label-${id}`}`}
      className="z-10 w-full rounded-lg border border-gray-200 bg-white shadow-md focus:outline-none"
      {...getFloatingProps?.({
        id: `select-list-${id}`,
        style: {
          position: strategy,
          top: 0,
          left: 0,
          overflow: "auto",
          transform: `translate3d(${Math.round(Number(x))}px, ${Math.round(Number(y))}px, 0)`,
        },
        onPointerMove() {
          setPointer(true)
        },
        onKeyDown() {
          setPointer(false)
        },
      })}
    >
      <FloatingList
        elementsRef={listNodeRef as MutableRefObject<HTMLElement[]>}
        labelsRef={listStringRef}
      >
        {children}
      </FloatingList>
      {!isFilled && emptyState}
    </ContainerElement>
  )

  if (open) {
    return (
      <FocusManager
        isFocusTrapped={false}
        returnFocusRef={refs?.reference}
        render={({ getRenderProps }) => <div {...getRenderProps()}>{getContent()}</div>}
        isOpen={open}
        onRequestOpenChange={setOpen}
      />
    )
  }

  return (
    <div aria-hidden hidden>
      {getContent()}
    </div>
  )
}

SelectList.displayName = "Select.List"
