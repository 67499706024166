declare const tinymce: any

export const pickFile = (callback: (value: string, meta?: Record<string, any>) => void) => {
  const input = document.createElement("input")
  input.setAttribute("type", "file")
  input.setAttribute("accept", "image/*")

  input.addEventListener("change", e => {
    const file = (e.target as HTMLInputElement).files[0]

    const reader = new FileReader()
    reader.addEventListener("load", () => {
      /*
        Note: Now we need to register the blob in TinyMCEs image blob
        registry. In the next release this part hopefully won't be
        necessary, as we are looking to handle it internally.
      */
      const id = "blobid" + new Date().getTime()
      // eslint-disable-next-line no-undef
      const blobCache = tinymce.activeEditor.editorUpload.blobCache
      const base64 = (reader.result as string).split(",")[1]
      const blobInfo = blobCache.create(id, file, base64)
      blobCache.add(blobInfo)

      /* call the callback and populate the Title field with the file name */
      callback(blobInfo.blobUri(), { title: file.name })
    })
    reader.readAsDataURL(file)
  })

  input.click()
}
