import React, { useState } from "react"
import { FormLabel, Select, useDecisionTableContext } from "@/components"
import { FieldProps } from "../types"
import { DecisionColumnEnumValue } from "@/types/DecisionTable"
import { checkIsNewRuleById } from "../../../utils"

interface SubdivisionFieldProps extends FieldProps {
  countryCode: string
  options: DecisionColumnEnumValue[]
}

export const SubdivisionField = ({
  row,
  cell,
  column,
  accessorKey,
  countryCode,
  label,
  options,
}: SubdivisionFieldProps) => {
  const {
    state: { validationErrors },
    dispatch,
  } = useDecisionTableContext()
  const [value, setValue] = useState(row._valuesCache[column.id])
  const [filter, setFilter] = useState("")

  const countryProvinces = options.filter(({ value }) => value.split("-")[0] === countryCode)

  if (!countryProvinces || countryProvinces.length === 0) {
    return null
  }

  const filteredProvinces =
    filter === ""
      ? countryProvinces
      : countryProvinces.filter(({ title, value }) => {
          return (
            title.toLowerCase().includes(filter.toLowerCase()) ||
            value.toLowerCase().includes(filter.toLowerCase())
          )
        })

  return (
    <>
      <FormLabel htmlFor={cell.id} id={`label-${cell.id}`} className="mb-2" label={label} />
      <Select
        name={cell.id}
        onChange={(provinceCode: string) => {
          row._valuesCache[column.id] = provinceCode
          setValue(provinceCode)
        }}
        value={value}
        error={validationErrors[accessorKey]}
        onFocus={() => {
          setFilter("")
          dispatch({
            validationErrors: {
              ...validationErrors,
              [accessorKey]: undefined,
            },
          })
        }}
        disabled={!checkIsNewRuleById(cell.id)}
      >
        <Select.Input
          placeholder="Search by region name or region code"
          onChange={e => {
            setFilter(e.target.value)
          }}
        />
        {filteredProvinces.map(({ title, value }) => {
          return (
            <Select.Option key={value} value={value}>
              {title}
            </Select.Option>
          )
        })}
      </Select>
    </>
  )
}
